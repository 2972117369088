import { IS_LOGGING_ENABLED } from 'src/config';

type LogFunction = (...args: any[]) => void;
class Logger {
  private static instance: Logger | null = null;

  private readonly enabled: boolean = IS_LOGGING_ENABLED;

  private constructor() {
    if (!Logger.instance) {
      Logger.instance = this;
    }

    return Logger.instance;
  }

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  private logWithPrefix(prefix: string, fn: LogFunction, ...args: any[]) {
    if (this.enabled) {
      fn(`[${prefix}] at function ${this.getCallerName()}\n`, ...args);
    }
  }

  log(...args) {
    this.logWithPrefix('INFO', console.log, ...args);
  }

  warn(...args) {
    this.logWithPrefix('WARN', console.warn, ...args);
  }

  error(...args) {
    this.logWithPrefix('ERROR', console.error, ...args);
  }

  private getCallerName() {
    try {
      throw new Error();
    } catch (e) {
      try {
        return e?.stack?.split('at ')?.[4]?.split(' ')?.[0] || 'Unknown';
      } catch (e) {
        return 'Unknown';
      }
    }
  }
}

const logger = Logger.getInstance();
export default logger;

import { createSlice, current } from '@reduxjs/toolkit';
import { PdfDocument } from 'src/types/document';
import { DOCUMENT_ANALYSIS_STATUS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { v4 as uuidV4 } from 'uuid';

interface AnalysisHistoryContext {
  openedDocuments: PdfDocument[];
  isDocumentViewerOpen: boolean;
  selectedDocumentIndex: number;
  isAnalysisStreaming?: boolean;
}

type InitiateAnalysis = {
  dialogTitle?: string;
  isDialogOpen?: boolean;
  showDataSelectionHeader?: boolean;
  showSearchHeader?: boolean;
  showBackButton?: boolean;
  isSelectingDataset?: boolean;
  isSelectingQuestionnaire?: boolean;
  title?: string;
  selectedDataset?: { name: string; id: number; isDatasetDeleted?: boolean };
  selectedQuestionnaire?: {
    name: string;
    id: number;
    isEditable?: boolean;
    isQuestionnaireAssociated?: boolean;
  };
  selectedModel?: any;
  isAnalysisView?: boolean;
  selectedAnalysis?: {
    status: string;
    id: number;
    isQuestionEdited?: boolean;
    isQuestionnaireAssociated?: boolean;
  };
  searchQuery?: string;
  isUpdateQuestionnaireDialogOpen?: boolean;
};

type DocAnalystType = {
  initiateAnalysis: InitiateAnalysis;
  initiateAnalysisHistory?: InitiateAnalysis[];
  analysisHistoryContexts: {
    [id: number]: AnalysisHistoryContext;
  };
  currentlyDownloadingDocuments: number[];
  availableModels: any[];
  currentlyRunningAnalysis: number;
};
const initialState: DocAnalystType = {
  initiateAnalysis: {
    isDialogOpen: false,
    showDataSelectionHeader: true,
    dialogTitle: T.initiateAnalysis,
    title: '',
    searchQuery: '',
    selectedQuestionnaire: { name: '', id: 0, isEditable: true },
    isUpdateQuestionnaireDialogOpen: false
  },
  initiateAnalysisHistory: [
    {
      isDialogOpen: true,
      showDataSelectionHeader: true,
      dialogTitle: T.initiateAnalysis,
      title: '',
      searchQuery: ''
    }
  ],
  analysisHistoryContexts: {},
  currentlyDownloadingDocuments: [],
  availableModels: [],
  currentlyRunningAnalysis: 0
};

var defaultAnalysisContextState: AnalysisHistoryContext = {
  openedDocuments: [],
  isDocumentViewerOpen: false,
  selectedDocumentIndex: 0
};

const docAnalystSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setInitiateAnalysisOpen: (state, action) => {
      state.initiateAnalysis.isDialogOpen = action.payload || false;
    },
    updateInitiateAnalysisState: (
      state,
      action: { payload: InitiateAnalysis }
    ) => {
      const { payload } = action;
      Object.assign(state.initiateAnalysis, payload);
      state.initiateAnalysisHistory.push(state.initiateAnalysis);
    },
    handleInitiateAnalysisBackState: (state, action) => {
      if (
        state.initiateAnalysisHistory &&
        state.initiateAnalysisHistory.length > 0
      ) {
        state.initiateAnalysisHistory.pop();
        const lastState = current(
          state.initiateAnalysisHistory[
            state.initiateAnalysisHistory.length - 1
          ]
        );

        if (lastState) {
          state.initiateAnalysis = lastState;
        } else {
          state.initiateAnalysis = initialState.initiateAnalysis;
        }
      }
    },
    selectDataset: (state, action) => {
      state.initiateAnalysis.selectedDataset = action.payload;
    },
    selectQuestionnaire: (state, action) => {
      state.initiateAnalysis.selectedQuestionnaire = action.payload;
    },
    selectModel: (state, action) => {
      state.initiateAnalysis.selectedModel = action.payload;
    },
    setAvailableModels: (state, action) => {
      state.availableModels = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.initiateAnalysis.searchQuery = action.payload;
    },
    setTitle: (state, action) => {
      state.initiateAnalysis.title = action.payload;
    },
    closeSelection: (state) => {
      state.initiateAnalysis.isSelectingDataset = false;
      state.initiateAnalysis.isSelectingQuestionnaire = false;
      state.initiateAnalysis.showBackButton = false;
      state.initiateAnalysis.dialogTitle = T.initiateAnalysis;
      state.initiateAnalysis.showDataSelectionHeader = true;
      state.initiateAnalysis.showSearchHeader = false;
      state.initiateAnalysis.searchQuery = '';
    },
    closeDialog: (state) => {
      state.initiateAnalysis.isAnalysisView = false;
      state.initiateAnalysis.selectedAnalysis = null;
      state.initiateAnalysis.selectedDataset = null;
      state.initiateAnalysis.selectedQuestionnaire = null;
      state.initiateAnalysis.selectedModel = '';
      state.initiateAnalysis.title = '';
    },
    openAnalysisDetails: (state, action) => {
      const {
        selectedAnalysis,
        selectedDataset,
        selectedQuestionnaire,
        modelId,
        title
      } = action.payload;
      state.initiateAnalysis.isSelectingDataset = false;
      state.initiateAnalysis.isSelectingQuestionnaire = false;
      state.initiateAnalysis.isDialogOpen = true;
      state.initiateAnalysis.isAnalysisView = true;
      state.initiateAnalysis.selectedAnalysis = selectedAnalysis;
      state.initiateAnalysis.selectedDataset = selectedDataset;
      state.initiateAnalysis.selectedQuestionnaire = selectedQuestionnaire;
      state.initiateAnalysis.title = title;
      state.initiateAnalysis.selectedModel = state.availableModels.find(
        (model) => model.id === modelId
      );
    },
    updateOpenedDocument(
      state,
      {
        payload
      }: {
        payload: {
          analysisId: number;
          document: {
            pageNumber: number;
            name: string;
            id: number;
            index: number;
          };
        };
      }
    ) {
      if (
        state.analysisHistoryContexts[payload.analysisId].openedDocuments[
          payload.document.index
        ].pageNumber !== payload.document.pageNumber
      ) {
        state.analysisHistoryContexts[payload.analysisId].openedDocuments[
          payload.document.index
        ] = {
          ...state.analysisHistoryContexts[payload.analysisId].openedDocuments[
            payload.document.index
          ],
          ...payload.document
        };
      }
      state.analysisHistoryContexts[payload.analysisId].isDocumentViewerOpen =
        true;
      if (
        state.analysisHistoryContexts[payload.analysisId]
          .selectedDocumentIndex !== payload.document.index
      ) {
        state.analysisHistoryContexts[
          payload.analysisId
        ].selectedDocumentIndex = payload.document.index;
      }
    },
    addOpenedDocument(
      state,
      {
        payload
      }: {
        payload: {
          analysisId: number;
          document: PdfDocument;
        };
      }
    ) {
      let uuid = uuidV4();
      if (!state.analysisHistoryContexts[payload.analysisId])
        state.analysisHistoryContexts[payload.analysisId] =
          defaultAnalysisContextState;
      state.analysisHistoryContexts[payload.analysisId].openedDocuments.unshift(
        {
          ...payload.document,
          uuid: uuid
        }
      );
      state.analysisHistoryContexts[payload.analysisId].isDocumentViewerOpen =
        true;
    },

    removeOpenedDocument(
      state,
      {
        payload
      }: {
        payload: { analysisId: number; documentIndex: number };
      }
    ) {
      state.analysisHistoryContexts[payload.analysisId].openedDocuments.splice(
        payload.documentIndex,
        1
      );
      if (
        !state.analysisHistoryContexts[payload.analysisId].openedDocuments
          .length
      ) {
        state.analysisHistoryContexts[payload.analysisId].isDocumentViewerOpen =
          false;
      }
    },
    openDocumentViewer(state, { payload }: { payload: number }) {
      state.analysisHistoryContexts[payload].isDocumentViewerOpen = true;
    },
    closeDocumentViewer(state, { payload }: { payload: number }) {
      if (payload in state.analysisHistoryContexts) {
        state.analysisHistoryContexts[payload].isDocumentViewerOpen = false;
        state.analysisHistoryContexts[payload].openedDocuments = [];
      }
    },
    addCurrentlyDownloadingDocuments(state, { payload }: { payload: number }) {
      state.currentlyDownloadingDocuments.push(payload);
    },
    removeCurrentlyDownloadingDocuments(
      state,
      { payload }: { payload: number }
    ) {
      state.currentlyDownloadingDocuments =
        state.currentlyDownloadingDocuments.filter((item) => item !== payload);
    },
    updateQuestion(
      state,
      { payload }: { payload: { questionEdited: boolean } }
    ) {
      state.initiateAnalysis.selectedAnalysis.isQuestionEdited =
        payload.questionEdited;
      if (payload.questionEdited)
        state.initiateAnalysis.selectedAnalysis.status =
          DOCUMENT_ANALYSIS_STATUS.REGENERATING;
    },
    updateQuestionnaireAssociation(
      state,
      { payload }: { payload: { isQuestionnaireAssociated: boolean } }
    ) {
      state.initiateAnalysis.selectedAnalysis.isQuestionnaireAssociated =
        payload.isQuestionnaireAssociated;
    },
    addUpdateAnalysisHistoryContext(
      state,
      {
        payload
      }: { payload: { analysisId: number; isAnalysisStreaming: boolean } }
    ) {
      if (!state.analysisHistoryContexts[payload.analysisId]) {
        state.analysisHistoryContexts[payload.analysisId] = {
          isAnalysisStreaming: false,
          ...defaultAnalysisContextState
        };
      }
      const analysisContext = state.analysisHistoryContexts[payload.analysisId];
      const wasStreaming = analysisContext.isAnalysisStreaming;
      analysisContext.isAnalysisStreaming = payload.isAnalysisStreaming;
      if (payload.isAnalysisStreaming && !wasStreaming) {
        state.currentlyRunningAnalysis = Math.min(
          state.currentlyRunningAnalysis + 1
        );
      } else if (!payload.isAnalysisStreaming && wasStreaming) {
        state.currentlyRunningAnalysis = Math.max(
          state.currentlyRunningAnalysis - 1,
          0
        );
      }
    },
    updateAnalysisQuestionnaireStatus: (state, action) => {
      state.initiateAnalysis.selectedAnalysis.isQuestionEdited = action.payload;
    }
  }
});

export const {
  setInitiateAnalysisOpen,
  updateInitiateAnalysisState,
  handleInitiateAnalysisBackState,
  selectDataset,
  selectQuestionnaire,
  closeSelection,
  setTitle,
  closeDialog,
  openAnalysisDetails,
  addCurrentlyDownloadingDocuments,
  addOpenedDocument,
  updateOpenedDocument,
  removeCurrentlyDownloadingDocuments,
  removeOpenedDocument,
  closeDocumentViewer,
  setSearchQuery,
  updateQuestion,
  updateQuestionnaireAssociation,
  selectModel,
  setAvailableModels,
  addUpdateAnalysisHistoryContext,
  updateAnalysisQuestionnaireStatus
} = docAnalystSlice.actions;

export const reducer = docAnalystSlice.reducer;

import {
  FILE_EXTENSIONS,
  RESOURCE_TYPE,
  SUPPORTED_UPLOAD_FILES_FORMAT
} from 'src/types/enum';

export const TWENTY_FOUR_HOUR_FORMAT = 'dd MMM yyyy HH:mm';
export const DATE_FORMAT = 'MMMM dd yyyy';
export const DATE_FILTER_FORMAT = 'yyyy-MM-dd';
export const TIME_FORMAT = 'hh:mm';
export const DOCUMENT_FORMAT = 'EEEE dd/MM/yyyy hh:mm a';
export const EMAIL_REG_EXP = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
export const FILE_FORMAT = {
  [SUPPORTED_UPLOAD_FILES_FORMAT.PPT]: ['.'.concat(FILE_EXTENSIONS.PPT)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.PPTX]: ['.'.concat(FILE_EXTENSIONS.PPTX)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.PDF]: ['.'.concat(FILE_EXTENSIONS.PDF)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.DOC]: ['.'.concat(FILE_EXTENSIONS.DOC)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.DOCX]: ['.'.concat(FILE_EXTENSIONS.DOCX)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.XLS]: ['.'.concat(FILE_EXTENSIONS.XLS)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.XLSX]: ['.'.concat(FILE_EXTENSIONS.XLSX)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.ODS]: ['.'.concat(FILE_EXTENSIONS.ODS)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.ODP]: ['.'.concat(FILE_EXTENSIONS.ODP)],
  [SUPPORTED_UPLOAD_FILES_FORMAT.ODT]: ['.'.concat(FILE_EXTENSIONS.ODT)]
};

export const ROUTES = {
  NAMESPACE_ROUTE: 'workspaces',
  WORKSPACE_ROUTE: 'collections',
  RESOURCES_ROUTE: 'resources'
};

export const DATA_LIBRARY_ROUTES = {
  [RESOURCE_TYPE.NAMESPACE]: ROUTES.NAMESPACE_ROUTE,
  [RESOURCE_TYPE.WORKSPACE]: ROUTES.WORKSPACE_ROUTE,
  [RESOURCE_TYPE.PUBLIC_WORKSPACE]: ROUTES.WORKSPACE_ROUTE,
  [RESOURCE_TYPE.PRIVATE_WORKSPACE]: ROUTES.WORKSPACE_ROUTE,
  [RESOURCE_TYPE.FOUND_WORKSPACE]: ROUTES.WORKSPACE_ROUTE,
  [RESOURCE_TYPE.COLLECTION]: ROUTES.RESOURCES_ROUTE,
  [RESOURCE_TYPE.FOUND_COLLECTION]: ROUTES.RESOURCES_ROUTE,
  [RESOURCE_TYPE.FOLDER]: ROUTES.RESOURCES_ROUTE
};

export const PUBLIC_ROUTES = ['/signup', '/privacypolicy'];

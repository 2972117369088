import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ONBOARDING_STEPS_TARGET } from '../../constants';

export const handleWorldKnowledgeTabClick = () => {
  const worldKnowledge = document.getElementById(
    ONBOARDING_STEPS_TARGET.CHAT_WORLD_KNOWLEDGE_BUTTON
  ) as HTMLInputElement;
  if (worldKnowledge) {
    worldKnowledge.click();
  }
};

export const handleChatQueryInput = () => {
  const inputElement = document.getElementById(
    ONBOARDING_STEPS_TARGET.CHAT_INPUT_FIELD
  ) as HTMLInputElement;
  if (inputElement) {
    window.dispatchEvent(
      new CustomEvent('handle-message-input', {
        detail: T.chatQuery
      })
    );
  }
};

export const handleSendMessageButton = () => {
  const chatButton = document.getElementById(
    ONBOARDING_STEPS_TARGET.CHAT_SEND_MSG_BUTTON
  ) as HTMLButtonElement;
  if (chatButton) {
    chatButton.click();
  }
};

export const updateSpotLightPosition = () => {
  const chatMessages = document.getElementsByClassName(
    ONBOARDING_STEPS_TARGET.CHAT_MESSAGES
  ) as HTMLCollectionOf<HTMLDivElement>;
  if (chatMessages.length) {
    chatMessages[0].style.overflowY = 'scroll';
  }
  const spotLights = document.getElementsByClassName(
    ONBOARDING_STEPS_TARGET.REACT_JOYRIDE_SPOTLIGHT
  ) as HTMLCollectionOf<HTMLDivElement>;
  if (spotLights.length) {
    spotLights[0].style.top = '-10px';
  }
};

export const updateReferenceDocumentPosition = () => {
  const refrenceDocument = document.getElementsByClassName(
    ONBOARDING_STEPS_TARGET.CHAT_REFERENCE_DOCUMENT
  ) as HTMLCollectionOf<HTMLDivElement>;
  if (refrenceDocument.length) {
    refrenceDocument[0].scrollIntoView({ block: 'end' });
  }
};

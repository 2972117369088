import { absoluteUrl } from './utils/url';

export const BASE_SERVER_URL = absoluteUrl(
  process.env.REACT_APP_BASE_SERVER_URL
);

export const KEYCLOAK_SERVER_URL = absoluteUrl(
  process.env.REACT_APP_KEYCLOAK_SERVER_URL
);

export const KEYCLOAK_REALM_NAME = process.env.REACT_APP_KEYCLOAK_REALM_NAME;
export const APP_VERSION = process.env.REACT_APP_VERSION;

export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

export const IS_ENCRYPTION_ENABLED =
  process.env.REACT_APP_IS_ENCRYPTION_ENABLED?.toLowerCase() === 'true';
export const IS_NON_AD_ENABLED =
  process.env.REACT_APP_IS_NON_AD_ENABLED?.toLowerCase() === 'true';

// TODO: Check this key for encryption
export const KEY_B64 =
  'dm5hJ2VRZmdNVFlSbVJIKjRIY3FSfm5dQFJdTGooeEtaJkF3TVRwbjMuLWpmIy9iUzpKPS8pcXB7UzU7a3dRKQ==';

export const MICROSOFT_GRAPH_API_URL =
  process.env.REACT_APP_MICROSOFT_GRAPH_API_URL || '';

export const IS_PROVIDERS_DELETION_ALLOWED =
  process.env.REACT_APP_IS_PROVIDERS_DELETION_ALLOWED?.toLowerCase() === 'true';

export const TENANT_NAME = process.env.REACT_APP_TENANT_NAME || '';

export const IS_LOGGING_ENABLED =
  process.env.REACT_APP_IS_LOGGING_ENABLED?.toLowerCase() === 'true';

export const IS_MINI_EXPERTS_DISABLED =
  process.env.REACT_APP_IS_MINI_EXPERTS_DISABLED?.toLowerCase() === 'true';

export const IS_WEB_SEARCH_ENABLED =
  process.env.REACT_APP_IS_WEB_SEARCH_ENABLED?.toLowerCase() === 'true';

export const IS_SIGNUP_ENABLED =
  process.env.REACT_APP_IS_SIGNUP_ENABLED?.toLowerCase() === 'true';

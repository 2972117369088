import {
  GridColumnExtension,
  Sorting,
  SortingState
} from '@devexpress/dx-react-grid';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { getEncryptParams } from 'src/keycloak';
import { DataApiInputParams } from 'src/types/api';
import { SelectItem, StringKeys } from 'src/types/base';
import { FolderStructure, IFile } from 'src/types/document';
import { getParam, getPayload } from 'src/utils/encryption';
import {
  DOCUMENT_STATUS,
  RESOURCE_TYPE,
  UPLOAD_TYPE,
  USER_APP_PERMISSIONS,
  USER_RESOURCE_PERMISSION,
  DEFAULT_CONTENT_ROLES
} from 'src/types/enum';
import {
  getEncryptedFile,
  readFileAsBuffer
} from 'src/utils/files/file_encryption';
import { v4 as uuidV4 } from 'uuid';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { t } from 'i18next';
import { initialColorList } from 'src/theme/customColors';
import { BrowserHistory } from 'history';
import { Location } from 'history';
import { isMobile } from 'react-device-detect';

export const defaultFilters = {
  tag_id: []
};
export enum EXEMPTED_WORKSPACES_COLLECTIONS {
  FOUND = RESOURCE_TYPE.FOUND_WORKSPACE,
  PUBLIC = RESOURCE_TYPE.PUBLIC_WORKSPACE,
  PRIVATE = RESOURCE_TYPE.PRIVATE_WORKSPACE,
  FOUND_COLLECTION = RESOURCE_TYPE.FOUND_COLLECTION
}

export const UPLOAD_CONSTRAINT_RESOURCES = [
  RESOURCE_TYPE.WORKSPACE,
  RESOURCE_TYPE.PUBLIC_WORKSPACE,
  RESOURCE_TYPE.PRIVATE_WORKSPACE,
  RESOURCE_TYPE.FOUND_WORKSPACE
];

export const MOVEABLE_RESOURCES = [RESOURCE_TYPE.FOLDER, RESOURCE_TYPE.FILE];
export const PAGE_SIZES = [25, 50, 100];
export const ROOT_WORKSPACE_PAGE_SIZE = 100000;
export const EDIT_COLUMN_WIDTH = 150;

export const initialNewRow = {
  id: 0,
  name: ''
};
export const ORGANIZATION_ADMINISTRATOR = 'Organization Administrators';

export const ADVANCED_SHARING_OPTIONS = [
  {
    name: T.keepManuallyAssignedRolesOnSubFoldersFiles,
    value: 'default'
  },
  {
    name: T.assignRoleToAllSubFoldersFiles,
    value: 'force'
  },
  { name: T.assignRoleOnFilesOnly, value: 'files-only' }
];

export const no_data_message = (
  resourceType,
  resourcePermissions,
  appPermissions,
  filtersApplied = false
) => {
  if (filtersApplied) return t(T.noData);
  switch (resourceType) {
    case RESOURCE_TYPE.NAMESPACE:
      return appPermissions?.[USER_APP_PERMISSIONS.CREATE_ROOT_FOLDER_CONTENT]
        ?.value
        ? t(T.noDataNameSpaceMessage)
        : t(T.noDataNoPermissionMessage);
    case RESOURCE_TYPE.WORKSPACE:
      return resourcePermissions?.[USER_RESOURCE_PERMISSION.CREATE_FOLDERS]
        ?.value ||
        resourcePermissions?.[USER_RESOURCE_PERMISSION.UPLOAD_FILES_FOLDERS]
          ?.value
        ? t(T.noDataWorkspaceMessage)
        : t(T.noDataNoPermissionMessage);
    case RESOURCE_TYPE.COLLECTION:
    case RESOURCE_TYPE.FOLDER:
      return resourcePermissions?.[USER_RESOURCE_PERMISSION.CREATE_FOLDERS]
        ?.value ||
        resourcePermissions?.[USER_RESOURCE_PERMISSION.UPLOAD_FILES_FOLDERS]
          ?.value
        ? t(T.noDataCollectionMessage)
        : t(T.noDataNoPermissionMessage);
    default:
      return t(T.noData);
  }
};

export const COLUMNS = {
  FILENAME: 'name',
  STATUS: 'status',
  MODIFIED_BY: 'modified_by',
  MODIFIED_ON: 'modified_on',
  SYNCED_BY: 'synced_by',
  SYNCED_ON: 'synced_on'
};

export const COLUMN_TITLES = {
  [COLUMNS.FILENAME]: T.name,
  [COLUMNS.STATUS]: T.status,
  [COLUMNS.MODIFIED_ON]: T.modifiedOn,
  [COLUMNS.MODIFIED_BY]: T.modifiedBy,
  [COLUMNS.SYNCED_BY]: T.syncedBy,
  [COLUMNS.SYNCED_ON]: T.syncedOn
};

export const ORDER_LIST = [
  { id: 1, name: t(T.name) },
  { id: 2, name: t(T.lastUpdated) }
];

export const documentsColumns = (isExternalResource: boolean) => [
  {
    name: COLUMNS.FILENAME,
    title: COLUMN_TITLES[COLUMNS.FILENAME],
    getCellValue: (row) => ({ name: row?.name, typeCode: row?.type_code })
  },
  {
    name: COLUMNS.STATUS,
    title: COLUMN_TITLES[COLUMNS.STATUS],
    getCellValue: (row) => {
      return {
        status: row?.status,
        statusCode: row?.status_code,
        typeCode: row?.type_code
      };
    }
  },
  {
    name: isExternalResource ? COLUMNS.SYNCED_BY : COLUMNS.MODIFIED_BY,
    title: isExternalResource
      ? COLUMN_TITLES[COLUMNS.SYNCED_BY]
      : `${COLUMN_TITLES[COLUMNS.MODIFIED_BY]} / ${
          COLUMN_TITLES[COLUMNS.SYNCED_BY]
        }`,
    getCellValue: (row) =>
      row?.provider_id ? row?.synchronized_by : row?.modified_by || ''
  },
  {
    name: !!isExternalResource ? COLUMNS.SYNCED_ON : COLUMNS.MODIFIED_ON,
    title: !!isExternalResource
      ? COLUMN_TITLES[COLUMNS.SYNCED_ON]
      : `${COLUMN_TITLES[COLUMNS.MODIFIED_ON]} / ${
          COLUMN_TITLES[COLUMNS.SYNCED_ON]
        }`,
    getCellValue: (row) =>
      row?.provider_id ? row?.synchronized_on : row?.modified_on || ''
  }
];

export const editingColumnExtensions = [
  {
    columnName: COLUMNS.FILENAME,
    createRowChange: (row, value) => ({
      ...row,
      name: value
    })
  }
];

export const documentsColumnsExtensions = (isExternalResource: boolean) => [
  {
    columnName: COLUMNS.FILENAME,
    width: isMobile ? '25%' : '35%'
  },
  {
    columnName: COLUMNS.STATUS,
    width: '16%'
  },
  {
    columnName: !!isExternalResource ? COLUMNS.MODIFIED_BY : COLUMNS.SYNCED_BY,
    width: '16%'
  },
  {
    columnName: !!isExternalResource ? COLUMNS.MODIFIED_ON : COLUMNS.SYNCED_ON,
    width: '15%'
  }
];

export const getColumnExtensionsWidth = (
  columnExtensions: GridColumnExtension[],
  totalWidth?: number,
  modalOpen?: boolean
) => {
  if (totalWidth > 0) {
    const originalWidth = totalWidth > 768 ? totalWidth : 768;
    return columnExtensions.map((column) => {
      const width =
        (parseInt(column?.width?.toString().split('%')[0] || '') / 100) *
        (originalWidth - (modalOpen ? 0 : EDIT_COLUMN_WIDTH));

      return {
        ...column,
        width: width
      };
    });
  }
  return [];
};

export type TagType = (SelectItem | string)[];
export type GroupedFilesType = { [z: string]: any }[][];
export type FilesUploadStatus = {
  failed: number[];
  success: number[];
  details?: any;
};
export type PlaceholderTextType = {
  createText: string;
  uploadText: string;
  description: string;
};

export const getTagApiParams = (
  data: (string | number)[],
  tagsList: TagType
): DataApiInputParams => {
  let tags = tagsList.map((item) =>
    typeof item === 'string' ? item : item?.name
  );

  return {
    params: {
      file_id: data.toString(),
      tag: tags.toString()
    }
  };
};

export const getDocumentDeleteApiParams = (
  documentId: (number | string) | (number | string)[]
) => {
  return {
    params: {
      id: Array.isArray(documentId) ? documentId.join() : documentId
    }
  };
};

export const getReprocessDocuments = (
  selectedDocs: (string | number)[],
  rows: any[]
) => {
  const allowedFiles = rows.filter(
    (item) =>
      selectedDocs.includes(item.id) &&
      item.status_code === DOCUMENT_STATUS.UNSUPPORTED
  );
  return {
    documentCount: allowedFiles.length,
    documentIds: allowedFiles.map((item) => item.id).join()
  };
};

export const getFormData = async (
  filesList,
  tagsList,
  transactionId: string,
  uploadType?: UPLOAD_TYPE,
  parentId?: string | number
) => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    if (parentId) {
      formData.append('parent_id', getParam(parentId));
    }
    if (filesList.some((file) => file.path.split('/').length > 1)) {
      formData.append('upload_type', getParam(UPLOAD_TYPE.FOLDER));
    } else {
      formData.append('upload_type', getParam(UPLOAD_TYPE.FILE));
    }

    if (transactionId)
      formData.append('transaction_id', getParam(transactionId));
    if (tagsList.length) {
      let tags = tagsList.map((item) =>
        typeof item === 'string' ? item : item?.name
      );
      formData.append('tag', getParam(tags.toString()));
    }

    if (IS_ENCRYPTION_ENABLED) {
      formData.append('payload', getPayload('', getEncryptParams()));
    }
    for (const file of filesList) {
      const uuid = `${uuidV4()}.pdf`;
      const uuidName = `${uuid}_name`;
      const uuidPath = `${uuid}_path`;

      let filePath = file.path;
      if (filePath[0] === '/') filePath = filePath.replace('/', '');

      filePath = filePath.split('/').slice(0, -1).join('/');

      const uuidFile = new File([file], `${uuid}`, {
        type: file.type,
        lastModified: file.lastModified
      });
      formData.append(uuidName, getParam(file.name));
      formData.append(uuidPath, getParam(filePath));
      if (IS_ENCRYPTION_ENABLED) {
        const arrayBufferData: any = await readFileAsBuffer(uuidFile);
        let encryptedFile = await getEncryptedFile(
          arrayBufferData,
          uuidFile.name,
          uuidFile.type
        );
        formData.append('file', encryptedFile);
      } else {
        formData.append('file', uuidFile);
      }
    }
    resolve(formData);
  });
};

const flattenHierarchy = (folderStructure: FolderStructure, result = []) => {
  Object.entries(folderStructure)?.map(([name, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      result.push({
        name,

        type: UPLOAD_TYPE.FOLDER
      });
      flattenHierarchy(value, result);
    } else {
      result.push({ file: value[0], type: UPLOAD_TYPE.FILE });
    }
  });
  return result;
};

export const buildFlatHierarchy = (files: IFile[]) => {
  const hierarchy: Record<string, any> = {};
  files.forEach((file) => {
    const path = file?.path[0] === '/' ? file?.path.slice(1) : file?.path;
    const parts = path.split('/');
    let currentLevel = hierarchy;
    const lastIndex = parts.length - 1;
    parts.forEach((part, index) => {
      if (!currentLevel[part]) {
        currentLevel[part] = index === lastIndex ? [file] : {};
      } else if (index === lastIndex) {
        currentLevel[part].push(file);
      }
      currentLevel = currentLevel[part];
    });
  });
  return flattenHierarchy(hierarchy);
};

const validationRules = {
  name: {
    isValid: (value) => {
      return !!value.length && value.length > 200 === false;
    },
    errorText: t(T.nameCharacterLengthError)
  }
};

export const validate = (changed) => ({
  ...Object.keys(changed)
    .filter((key) => key === 'name')
    .reduce((acc, field) => {
      const isValid = validationRules[field].isValid(changed[field]);
      return {
        ...acc,
        [field]: {
          isValid,
          error: !isValid && validationRules[field].errorText
        }
      };
    }, {})
});

export const isRowValid = (changedObject) => {
  return Object.keys(initialNewRow).every((key) => {
    if (key === 'name') {
      return (
        changedObject?.hasOwnProperty(key) &&
        validationRules?.[key]?.isValid(changedObject[key] || '')
      );
    }
    return true;
  });
};

export const getFilteredList = (filtered: StringKeys) => {
  let filterObj = {};
  Object.keys(filtered).forEach((key) => {
    if (key === 's') {
      filterObj[key] = filtered[key];
    } else if (key === 'uploaded_by') {
      filterObj[key] = filtered[key]?.username;
    } else if (key === 'status_id' && filtered[key].value !== 'all') {
      filterObj[key] = filtered[key].id;
    } else if (key === 'tag_id') {
      if (filtered[key].length) {
        filterObj[key] = filtered[key].map((item) => item.id).toString();
      } else {
        delete filterObj[key];
      }
    }
  });
  return filterObj;
};

export const defaultSorting: Sorting[] = [
  {
    columnName: COLUMNS.FILENAME,
    direction: 'asc'
  }
];

export const sortingColumnExtensions: SortingState.ColumnExtension[] = [
  { columnName: COLUMNS.FILENAME, sortingEnabled: true },
  { columnName: COLUMNS.STATUS, sortingEnabled: false },
  { columnName: COLUMNS.MODIFIED_BY, sortingEnabled: true },
  { columnName: COLUMNS.MODIFIED_ON, sortingEnabled: true }
];

export const sharingRoleDescriptions = {
  [DEFAULT_CONTENT_ROLES.VIEWER]: T.assignViewerSubtitle,
  [DEFAULT_CONTENT_ROLES.CONTROLLER]: T.assignControllerSubtitle,
  [DEFAULT_CONTENT_ROLES.CONTRIBUTOR]: T.assignContributorSubtitle
};

export const colorList: SelectItem[] = initialColorList.map((color, index) => ({
  id: index + 1,
  name: color
}));

export const checkPageReload = (
  history: BrowserHistory,
  location: Location
) => {
  const navigationEntries = performance.getEntriesByType('navigation');
  if (navigationEntries.length > 0) {
    const navigationEntry = navigationEntries[0];
    if ((navigationEntry as any).type === 'reload') {
      history.push(location.pathname, {
        state: null
      });
    }
  }
};

export const getFavouredData = (rows, favourites, type) => {
  const favouriteArray = favourites?.filter((fav) => fav?.item_type === type);
  return rows?.map((row) => ({
    ...row,
    isFavourite: favouriteArray[0]?.items?.some(
      (fav: StringKeys) => fav?.item_id === row.id
    )
  }));
};

export const isLessThanOneMinuteOld = (createdOn: Date): boolean => {
  const currentTime = new Date();
  let createdOnDate = new Date(createdOn);
  const timeDifference = currentTime.getTime() - createdOnDate.getTime();

  return timeDifference < 240000;
};

export const publicOrPrivateWorkspaceCheck = (workSpaceCode: string) =>
  workSpaceCode === RESOURCE_TYPE.PUBLIC_WORKSPACE ||
  workSpaceCode === RESOURCE_TYPE.PRIVATE_WORKSPACE;

import React from 'react';
import { ChatData } from 'src/types/chat';
import ChatList from '../TopSection/Chat/ListItem';
import { GroupTitle } from './styles';
import { Box } from '@mui/material';

interface GroupChatProps {
  groupTitle: string;
  chatGroup: ChatData[];
  onChatDeleted: (chatId: number) => void;
  onTitleEdited: (chatId: number, updatedTitle: string) => void;
}

const GroupChat: React.FC<GroupChatProps> = ({
  groupTitle,
  chatGroup,
  onChatDeleted,
  onTitleEdited
}) => {
  if (chatGroup.length === 0) return null;

  return (
    <Box marginBottom={'16px'}>
      <GroupTitle>{groupTitle}</GroupTitle>
      {chatGroup.map((item) => (
        <ChatList
          data={item}
          key={item.id}
          onChatDeleted={onChatDeleted}
          onTitleEdited={onTitleEdited}
        />
      ))}
    </Box>
  );
};

export default GroupChat;

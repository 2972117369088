import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import storage from 'redux-persist/lib/storage';
import kc, {
  isValidToken,
  setKccToken,
  getKcToken,
  getUserLogInStatus,
  setEncryptParams,
  getEncryptParams,
  setNewUser
} from '../../keycloak';
import jwtDecode from 'jwt-decode';
import { ILoggedInUser } from '../../types/user';
import { BASE_SERVER_URL } from '../../config';
import {
  getChallenge,
  getChallengeHeader,
  getEncryptedToken,
  getPayload,
  KDF,
  KEY_DB64
} from '../../utils/encryption';
import axios from '../../utils/axios';
import { setNamespaceId, setTenantInfo } from './library';
import { setApplicationResources } from './data';
import logger from 'src/utils/logger';
import { ACCOUNT_TYPE } from 'src/types/enum';

interface UserSettingsType {
  username: string;
  timezone: string;
  session_idle_time: string;
  ps_scheme?: number;
}

interface EncryptionParams {
  k: any;
  u: string;
  ka: any;
  mt: any;
  c: string;
}

interface AzureWebPubSubInfo {
  baseUrl: string;
  token: string;
  url: string;
}

interface AuthType {
  user?: ILoggedInUser | undefined;
  isAuthenticated: boolean;
  userDetails?: UserSettingsType;
  encryptionParams?: EncryptionParams;
  isAuthorized: boolean;
  azureWebPubSubInfo?: AzureWebPubSubInfo;
  licenseInfo?: LicenseInfo;
  maxAnalysisLimit?: number;
}

interface LicenseInfo {
  creditLimit: string;
  wordLimit: string;
}

const initialState: AuthType = {
  isAuthenticated: false,
  isAuthorized: false
};

const isNonTrialUser = (accountType: string) =>
  accountType !== ACCOUNT_TYPE.TRIAL_ADMINISTRATOR &&
  accountType !== ACCOUNT_TYPE.TRIAL_USER;

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSignIn(
      state: AuthType,
      action: PayloadAction<{
        user: ILoggedInUser;
        params: EncryptionParams;
        wsInfo: AzureWebPubSubInfo;
        licenseInfo: LicenseInfo;
        maxAnalysisLimit: number;
      }>
    ) {
      const { user, params, wsInfo, licenseInfo, maxAnalysisLimit } =
        action.payload;
      state.user = user;
      state.isAuthenticated = true;
      state.encryptionParams = params;
      state.azureWebPubSubInfo = wsInfo;
      state.licenseInfo = licenseInfo;
      state.maxAnalysisLimit = maxAnalysisLimit;
    },
    authSignOut(state: AuthType) {
      state.user = undefined;
      state.isAuthenticated = false;
      state.encryptionParams = undefined;
      state.azureWebPubSubInfo = undefined;
      // this applies to all keys defined in persistConfig(s)
      storage.removeItem('persist:root');
      kc.logout({ redirectUri: '/' }).catch((err: any) => {
        alert('Error Logging Out!');
      });
    },
    userSettings(
      state: AuthType,
      action: PayloadAction<{ user: UserSettingsType }>
    ) {
      const { user } = action.payload;
      state.userDetails = user;
    },
    AuthorizedUser(
      state: AuthType,
      action: PayloadAction<{ isAuthorized: boolean }>
    ) {
      const { isAuthorized } = action.payload;
      state.isAuthorized = isAuthorized;
    }
  }
});

const endPoint: any = {
  register: 'update',
  unregister: 'unregister'
};
const serviceType = {
  typeRegister: 1,
  typeUnregister: 2
};

let tokenRegisterRetries = 0; // retry counter for update token

export const reducer = slice.reducer;

// Register User
export const authSignIn =
  (): AppThunk => async (dispatch: any, getState: any, token: any) => {
    tokenRegisterRetries = 0;
    sessionRequest(
      endPoint.register,
      serviceType.typeRegister,
      dispatch,
      getState,
      token
    );
  };

// Unregister User
export const authSignOut =
  (): AppThunk => async (dispatch: any, getState: any) => {
    sessionRequest(
      endPoint.unregister,
      serviceType.typeUnregister,
      dispatch,
      getState
    );
  };

function sessionRequest(
  endpoint: string,
  type: number,
  dispatch: any,
  getState: any,
  token?: any
) {
  try {
    const accessToken = getKcToken();

    if (accessToken && isValidToken(accessToken)) {
      switch (type) {
        case serviceType.typeRegister:
          const response: any = jwtDecode(accessToken);
          let formDataUpdate = new FormData();
          let params = KDF(response, accessToken, KEY_DB64);
          let encrypted_token = getEncryptedToken(params, accessToken);
          let challenge = getChallenge(params, null);
          let isLogin = getUserLogInStatus() === 'true' ? 'false' : 'true';
          formDataUpdate.append('payload', challenge);
          formDataUpdate.append('isLogin', isLogin);
          axios
            .post(
              `${BASE_SERVER_URL}/api/v1/session/${endpoint}`,
              formDataUpdate,
              {
                headers: {
                  Authorization: `${encrypted_token}`,
                  withCredentials: 'true'
                }
              }
            )
            .then((sessionRes: any) => {
              if (sessionRes.status === 401) {
                dispatch(
                  slice.actions.AuthorizedUser({
                    isAuthorized: false
                  })
                );
              } else {
                dispatch(
                  slice.actions.AuthorizedUser({
                    isAuthorized: true
                  })
                );
              }
              let user = {
                id: response.sub || '',
                name: response.name || response.preferred_username || '',
                email: response.email || '',
                username: response.preferred_username || '',
                roles: sessionRes?.data?.ret_val?.user_roles || [],
                cost_centre: response.cost_centre || '',
                title: response.title || '',
                department: response.department || '',
                manager: response.manager || '',
                area: response.area || '',
                accountType:
                  response.account_type ||
                  sessionRes?.data?.ret_val?.account_type ||
                  '',
                userType: response.user_type || '',
                isNonTrialUser: isNonTrialUser(
                  response.account_type ||
                    sessionRes?.data?.ret_val?.account_type ||
                    ''
                )
              };
              let applicationResources = {
                ...sessionRes?.data?.ret_val?.types
              };

              // setting new User check if user has no roles
              if (user.roles.length === 0) {
                setNewUser('true');
              } else {
                setNewUser('false');
              }
              // setting encryption params in localStorage
              let tempEncryptParams = {
                ...params,
                c: sessionRes?.data?.ret_val?.cors_uuid || null
              };
              setEncryptParams(tempEncryptParams);

              dispatch(
                slice.actions.authSignIn({
                  user: user,
                  params: {
                    ...params,
                    c: sessionRes?.data?.ret_val?.cors_uuid || null
                  },
                  wsInfo: sessionRes?.data?.ret_val?.ws_info || null,
                  licenseInfo: {
                    creditLimit:
                      sessionRes?.data?.ret_val?.credit_limit_info
                        ?.consumed_license_info?.credit_limit?.code?.[1] || '',
                    wordLimit:
                      sessionRes?.data?.ret_val?.credit_limit_info
                        ?.consumed_license_info?.word_limit?.code?.[1] || ''
                  },
                  maxAnalysisLimit:
                    sessionRes?.data?.ret_val?.app_configuration
                      ?.da_max_running_analysis || 1
                })
              );
              dispatch(
                setApplicationResources({
                  applicationResources: applicationResources
                })
              );
              dispatch(
                setNamespaceId(sessionRes?.data?.ret_val?.types?.Namespace)
              );
              dispatch(setTenantInfo(sessionRes?.data?.ret_val?.tenant_info));
              setKccToken('false');
            })
            .catch((err: any) => {
              // add 3 retries to register
              logger.log('Error on Update', err);
              tokenRegisterRetries++;
              if (tokenRegisterRetries < 3) {
                sessionRequest(
                  endPoint?.register,
                  type,
                  dispatch,
                  getState,
                  token
                );
                logger.log('retrying Count', tokenRegisterRetries);
              } else {
                alert('Error: Updating session, Please try again!');
                logoutSession(dispatch);
              }
            });
          break;

        default:
          const encryptionParams = getEncryptParams();
          const uuid = getPayload(encryptionParams?.u, encryptionParams);
          let formData = new FormData();
          formData.append('payload', JSON.stringify(uuid));
          axios
            .post(`${BASE_SERVER_URL}/api/v1/session/${endpoint}`, formData, {
              headers: {
                Channel: getChallengeHeader(
                  encryptionParams,
                  `session/${endpoint}`,
                  formData
                )
              }
            })
            .then(() => {
              logoutSession(dispatch);
            })
            .catch((err: any) => {
              alert(`Error logging out user! ${err}`);
            });
          break;
      }
    } else {
      logger.log('Token Invalid!');
      alert('Expired/Invalid Token!, please login again!');
      logoutSession(dispatch);
    }
  } catch (err) {
    logoutSession(dispatch);
  }
}

function logoutSession(dispatch: any) {
  localStorage.clear();
  dispatch(slice.actions.authSignOut());
}

export const userSettings =
  (userDetails: UserSettingsType): AppThunk =>
  async (dispatch: any) => {
    dispatch(
      slice.actions.userSettings({
        user: userDetails
      })
    );
  };

export const AuthorizedUser =
  (isAuthorized: boolean): AppThunk =>
  async (dispatch: any) => {
    dispatch(
      slice.actions.AuthorizedUser({
        isAuthorized: isAuthorized
      })
    );
  };
// axios.interceptors.request.use((request) => {
//   logger.log("Starting Request", JSON.stringify(request, null, 2));
//   return request;
// });

// axios.interceptors.response.use((response) => {
//   logger.log("Response:", JSON.stringify(response, null, 2));
//   return response;
// });

export default slice;

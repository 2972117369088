import { Box, List } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { startChat } from 'src/content/Documents/utils/gridUtils';
import DocumentsContext from 'src/contexts/FileManagerContext';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import { RootState, useSelector } from 'src/redux/store';
import { FAVOURITE_TYPE } from 'src/types/enum';
import Assistants from '../Assistants';
import History from '../History';
import SidebarMenu from '../Menu/index';
import { MenuWrapper, SubMenuWrapper } from '../styles';
import SidebarFavouriteItem from './item';
import getFavouriteItems, {
  calculateListHeight,
  FavouriteItem,
  FavouriteItems
} from './items';
import { isMobile } from 'react-device-detect';
import { SidebarContext } from 'src/contexts/SidebarContext';

export const renderSidebarFavouriteItems = ({
  item,
  path,
  handleStartChatClick,
  handleFavouriteClick,
  t,
  favouriteType,
  hasFilePermissions,
  handleFilePermissionModal
}: {
  item: FavouriteItem;
  path: string;
  handleStartChatClick: (
    id: number,
    name: string,
    workspaceName?: string
  ) => void;
  handleFavouriteClick: (id: number, isFavourite: boolean) => void;
  t: TFunction<'translation', undefined>;
  hasFilePermissions?: boolean;
  favouriteType: string;
  handleFilePermissionModal?: (id: number) => void;
}): JSX.Element => {
  const key = item.name;

  const exactMatch = item?.link
    ? !!matchPath(
        {
          path: item?.link,
          end: true
        },
        path
      )
    : false;

  return (
    <SubMenuWrapper>
      <List component="div">
        {[
          <SidebarFavouriteItem
            key={key}
            active={exactMatch}
            name={item.name}
            workspaceName={item?.workspaceName}
            link={item?.link}
            icon={item?.icon}
            id={item.id}
            handleStartChatClick={handleStartChatClick}
            handleFavouriteClick={handleFavouriteClick}
            t={t}
            favouriteType={favouriteType}
            isLink={favouriteType !== FAVOURITE_TYPE.ASSISTANT}
            hasFilePermissions={hasFilePermissions}
            handleFilePermissionModal={handleFilePermissionModal}
          />
        ]}
      </List>
    </SubMenuWrapper>
  );
};

function SidebarMidSection() {
  const location = useLocation();
  const { toggleSidebar } = useContext(SidebarContext);
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const { userAppPermissions, userFavourites, isFavouritesFetching } =
    useSelector((state) => state.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const { isUpdatingFavourite, updateFavourite } = useContext(DocumentsContext);

  const menuItems = useMemo(
    () =>
      getFavouriteItems(
        userAppPermissions,
        userFavourites || [],
        FAVOURITE_TYPE.RESOURCE
      ),
    [userFavourites]
  );

  const listHeight = useMemo(() => calculateListHeight(menuItems), [menuItems]);

  const handleStartChatClick = async (
    id: number,
    name: string,
    workspaceName?: string
  ) => {
    const routeState = location?.state?.documentContext;
    await startChat({
      documentContext: {
        id,
        name,
        workspaceName
      },
      dispatch,
      navigate,
      selectedChat,
      routeState,
      resourceType: FAVOURITE_TYPE.RESOURCE
    });
  };

  const handleFavouriteClick = useCallback(
    (id: number, isFavourite: boolean) => {
      if (isUpdatingFavourite || isFavouritesFetching) return;
      dispatch(setIsFavouritesFetching(true));
      updateFavourite(id, !isFavourite);
    },
    [dispatch, isUpdatingFavourite, isFavouritesFetching]
  );

  return (
    <>
      <Assistants />
      <History />
      <SidebarMenu />
      {menuItems?.length ? (
        <Box height={listHeight}>
          <Box
            position={'absolute'}
            height={listHeight}
            width={'100%'}
            sx={{
              overflowY: 'auto',
              paddingLeft: '10px'
            }}
          >
            {menuItems.map((section: FavouriteItems, index: number) => (
              <MenuWrapper key={index}>
                <List
                  component="div"
                  onClick={isMobile ? toggleSidebar : undefined}
                >
                  {renderSidebarFavouriteItems({
                    item: section.item,
                    path: location.pathname,
                    handleStartChatClick: handleStartChatClick,
                    handleFavouriteClick: handleFavouriteClick,
                    t: t,
                    favouriteType: FAVOURITE_TYPE?.RESOURCE,
                    hasFilePermissions: section?.item?.hasFilePermissions
                  })}
                </List>
              </MenuWrapper>
            ))}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default SidebarMidSection;

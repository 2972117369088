import Joyride, { Step } from 'react-joyride';
import CustomArrow from '../../CustomArrow';
import { Typography } from '@mui/material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { ONBOARDING_STEPS_TARGET } from '../../constants';
const UsersSteps = (): Step[] => {
  const { t } = useTranslation();
  return [
    {
      target: `#${ONBOARDING_STEPS_TARGET.SIDEBAR_MORE_ACTION_ICON}`,
      placement: 'top-end',
      floaterProps: { hideArrow: true },
      offset: 20,
      content: (
        <>
          <CustomArrow
            position={{ bottom: '-40px', left: '200px' }}
            orientation="bottom"
          />
          <Typography width={570} mb={1}>
            {t(T.inviteUserStep1Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.inviteUserStep1Title)
    },
    {
      target: `#${ONBOARDING_STEPS_TARGET.USERS_NAVIGATION_LINK}`,
      placement: 'top',
      offset: 20,
      floaterProps: {
        hideArrow: true,
        styles: {
          floaterOpening: {
            left: '40px'
          }
        }
      },
      content: (
        <>
          <CustomArrow
            position={{ bottom: '-40px', left: '150px' }}
            orientation="bottom"
          />
          <Typography width={570} mb={1}>
            {t(T.inviteUserStep2Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.inviteUserStep2Title),
      spotlightPadding: 0
    },
    {
      target: `#${ONBOARDING_STEPS_TARGET.USERS_INVITE_BUTTON}`,
      placement: 'left',
      floaterProps: { hideArrow: true },
      offset: 20,
      content: (
        <>
          <CustomArrow
            position={{ top: '30px', right: '-40px' }}
            orientation="left"
          />
          <Typography width={570} mb={1}>
            {t(T.inviteUserStep3Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.inviteUserStep3Title)
    }
  ];
};

export default UsersSteps;

import { getEncryptParams } from 'src/keycloak';
import {
  Builder,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';

type InternalAnalytics = {
  count: number;
  name: string;
};

export default (builder: Builder) => {
  return {
    getProcessedDocuments: builder.query<
      { total: number; consumed: number; unit: string },
      void
    >({
      async queryFn(arg: void, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `analytics/processed-documents?${getQueryString({})}`;
        let result: any = await fetchWithBQ({
          url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response?.ret_val?.data
          ? { data: response?.ret_val?.data }
          : serializeErrorResponse(response);
      }
    }),
    getUsage: builder.query<
      {
        unit: string;
        total: number;
        monthly_usage: number;
        daily_usage: { date: string | Date; consumed: number }[];
      },
      { start_date: Date | string; end_date: Date | string }
    >({
      async queryFn(arg, api, extraOptions, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        const queryString = getQueryString(arg);
        let url = `analytics/all-usage?${queryString}`;
        let result: any = await fetchWithBQ({
          url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response?.ret_val?.data
          ? { data: response?.ret_val?.data }
          : serializeErrorResponse(response);
      }
    }),
    getDataLibraryAnalytics: builder.query<InternalAnalytics[], void>({
      async queryFn(arg, api, extraOptions, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        const queryString = getQueryString(arg);
        let url = `analytics/data-library?${queryString}`;
        let result: any = await fetchWithBQ({
          url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response?.ret_val?.data
          ? { data: response?.ret_val?.data }
          : serializeErrorResponse(response);
      }
    }),
    getChatAnalytics: builder.query<InternalAnalytics[], void>({
      async queryFn(arg, api, extraOptions, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        const queryString = getQueryString(arg);
        let url = `analytics/chats?${queryString}`;
        let result: any = await fetchWithBQ({
          url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response?.ret_val?.data
          ? { data: response?.ret_val?.data }
          : serializeErrorResponse(response);
      }
    }),
    getUserAnalytics: builder.query<InternalAnalytics[], void>({
      async queryFn(arg, api, extraOptions, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        const queryString = getQueryString(arg);
        let url = `analytics/users?${queryString}`;
        let result: any = await fetchWithBQ({
          url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response?.ret_val?.data
          ? { data: response?.ret_val?.data }
          : serializeErrorResponse(response);
      }
    })
  };
};

import { Box, styled } from '@mui/material';

export const TooltipBox = styled(Box)({
  padding: '30px',
  borderRadius: '20px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
});

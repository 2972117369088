import Joyride, { Step } from 'react-joyride';
import CustomArrow from '../../CustomArrow';
import { Typography } from '@mui/material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { ONBOARDING_STEPS_TARGET } from '../../constants';
const DocumentSteps = (): Step[] => {
  const { t } = useTranslation();
  return [
    {
      target: `#${ONBOARDING_STEPS_TARGET.DOCUMENT_DATA_LIBRARY_LINK}`,
      placement: 'right',
      floaterProps: { hideArrow: true },
      offset: 20,

      content: (
        <>
          <CustomArrow
            position={{ top: '95px', left: '-44px' }}
            orientation="right"
          />
          <Typography width={570} mb={1}>
            {t(T.documentStep1Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.documentStep1Title),
      spotlightPadding: 0
    },
    {
      target: `#${ONBOARDING_STEPS_TARGET.DOCUMENT_PRIVATE_WORKSPACE}`,
      placement: 'bottom',
      offset: 20,
      floaterProps: {
        hideArrow: true,
        styles: {
          floaterOpening: {
            left: '-50px'
          }
        }
      },
      content: (
        <>
          <CustomArrow
            position={{ top: '-40px', left: '150px' }}
            orientation="top"
          />
          <Typography width={570} mb={1}>
            {t(T.documentStep2Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.documentStep2Title)
    },
    {
      target: `#${ONBOARDING_STEPS_TARGET.DOCUMENT_ADD_MENU_POPOVER}`,
      placement: 'left',
      floaterProps: { hideArrow: true },
      offset: 20,
      spotlightPadding: 0,
      content: (
        <>
          <CustomArrow
            position={{ top: '50px', right: '-40px' }}
            orientation="left"
          />
          <Typography width={570} mb={1}>
            {t(T.documentStep3Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.documentStep3Title)
    },
    {
      target: `#${ONBOARDING_STEPS_TARGET.DOCUMENT_NEW_COLLECTION_BUTTON}`,
      placement: 'bottom',
      floaterProps: {
        hideArrow: true,
        styles: {
          floater: {
            display: 'none'
          }
        }
      },
      offset: 20,
      spotlightClicks: true,
      content: '',
      disableBeacon: true
    },
    {
      target: `#${ONBOARDING_STEPS_TARGET.DOCUMENT_UPLOAD_LINK}`,
      placement: 'left',
      floaterProps: { hideArrow: true },
      offset: 20,
      content: (
        <>
          <CustomArrow
            position={{ top: '110px', right: '-40px' }}
            orientation="left"
          />
          <Typography width={570} mb={1}>
            {t(T.documentStep4Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.documentStep4Title)
    },
    {
      target: `.${ONBOARDING_STEPS_TARGET.DOCUMENT_STATUS_TAG}`,
      placement: 'bottom',
      floaterProps: {
        hideArrow: true
      },
      offset: 20,

      content: (
        <>
          <CustomArrow
            position={{ top: '-40px', left: '300px' }}
            orientation="top"
          />
          <Typography width={570} mb={1}>
            {t(T.documentStep5Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.documentStep5Title)
    }
  ];
};

export default DocumentSteps;

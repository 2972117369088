export const initialColorList = [
  'rgb(0, 12, 87)',
  'rgb(251, 39, 93)',
  'rgb(0, 202, 114)',
  'rgb(163, 88, 208)',
  'rgb(89, 90, 212)',
  'rgb(28, 31, 59)',
  'rgb(102, 204, 255)',
  'rgb(253, 171, 61)',
  'rgb(255, 203, 0)',
  'rgb(0, 154, 255)',
  'rgb(246, 95, 124)',
  'rgb(255, 21, 138)',
  'rgb(226, 68, 92)',
  'rgb(128, 128, 128)',
  'rgb(87, 155, 252)',
  'rgb(187, 51, 84)',
  'rgb(3, 127, 76)',
  'rgb(255, 90, 196)',
  'rgb(31, 118, 194)',
  'rgb(255, 100, 46)',
  'rgb(156, 211, 38)',
  'rgb(34, 80, 145)'
];

import { Box, CardMedia, Divider, Link, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import KCThemeImg from 'src/assets/background.svg';
import { ReactComponent as Logo } from 'src/assets/empowergpt-logo.svg';
import LinkedInLogo from 'src/assets/LinkedInLogo.png';
import { ReactComponent as WorldIcon } from 'src/assets/world.svg';

const KCThemeTemplate: React.FC<
  PropsWithChildren<{ header: React.ReactNode }>
> = ({ header, children }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        display={'flex'}
        sx={{
          width: '100vw',
          backgroundColor: theme.colors.alpha.white[100],
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center'
          }
        }}
        height={'100vh'}
        position={'relative'}
      >
        <Box
          width="170px"
          height="40px"
          position="fixed"
          top={50}
          left={60}
          sx={{
            [theme.breakpoints.down('sm')]: {
              position: 'initial',
              padding: '0 0 48px 24px',
              marginTop: '-88px',
              boxSizing: 'content-box'
            }
          }}
        >
          <Logo />
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={{ md: '50%', xs: '100%' }}
          paddingX={{
            xs: '24px',
            sm: '11%'
          }}
        >
          <Box width="100%">
            <Box textAlign="left"> {header}</Box>
            {children}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            bottom: {
              xs: '24px',
              sm: '48px'
            },
            left: {
              xs: '50%',
              sm: '30%'
            },
            transform: 'translate(-50%, 0)'
          }}
        >
          <Link
            href="https://www.linkedin.com/company/empowergpt/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              width: 'auto',
              height: {
                xs: '32px',
                sm: '24px'
              }
            }}
          >
            <img
              src={LinkedInLogo}
              style={{
                width: 'inherit',
                height: 'inherit'
              }}
            />
          </Link>

          <Divider orientation="vertical" sx={{ marginX: '16px' }} />

          <Link
            href="https://empowergpt.ai/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              width: {
                xs: '32px',
                sm: '24px'
              },
              height: {
                xs: '32px',
                sm: '24px'
              }
            }}
          >
            <WorldIcon width="100%" height="100%" />
          </Link>
        </Box>

        <Box
          display={{ md: 'block', xs: 'none' }}
          width={{ md: '50%', xs: '0' }}
        >
          <CardMedia
            component={'img'}
            src={KCThemeImg}
            height="100%"
            sx={{ objectFit: 'contain' }}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default KCThemeTemplate;

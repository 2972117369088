import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const PrivacyPolicyMarkdown = `
# Privacy Policy for EmpowerGPT

Effective Date: Jan 2023

Welcome to EmpowerGPT ("Application"). The Application is provided and managed by INTECH Automation & Intelligence GmbH ("Company", "we", "us", or "our"). We are committed to protecting the privacy and security of our users ("user", "you", or "your"). This Privacy Policy outlines our practices and your rights in regard to any information that we collect and process through the Application.
## 1. Information We Collect
### Personal Information
Upon registration or provided by you directly in various ways, we may collect personal details
such as:
- Your name
- Email address
- No other relevant personal details are required for account creation and user identification

### Document Information
We store the documents you upload in a secured and encrypted location. These documents are only used for queries made, requested insights and question asked within the application. These documents are only accessible to the application users you grant access to. We do not share these documents in any way with third parties.

### Derivative Data
Information our servers automatically collect when you access the Application, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Application.

### Usage Data
We collect data on how the Application and its features are accessed and used. This includes:
- Queries made
- Insights requested
- Questions asked within the Application

## 2. How We Use Your Information
Your information is used to:
- Create and manage your account
- Provide, operate, and maintain the Application
- Respond to your queries and provide customer service
- Generate insights from your uploaded documents
- Provide a platform for you to interact with the documents
- Ensure the security and integrity of our Application
- Comply with our legal obligations

## 3. Sharing of Your Information
Your privacy is paramount, and we do not share your personal data or documents with any third
parties, except in the following circumstances:
- To comply with applicable laws and regulations, to respond to a subpoena, search
warrant, or other lawful requests for information we receive, or to otherwise protect our
rights.

## 4. Data Hosting and Security
The data and documents you provide are hosted on a public cloud platform. We implement a variety of security measures designed to maintain the safety of your personal information when you enter, submit, or access your personal information, including:
- Secure socket layer (SSL) technology to encrypt the transmission of non-public, personally identifiable information
- Maintaining the confidentiality of your information consistent with industry standards and practices, and reviewing our security procedures carefully

However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal
information, we cannot guarantee its absolute security.

## 5. Your Rights
You have the right to:
- Access the information we hold about you
- Correct any information you believe is inaccurate or complete information you believe is incomplete
- Delete your personal information or uploaded documents
- Object to our processing of your information and data
- Request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions

## 6. Changes to This Privacy Policy
We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this Privacy Policy.

## 7. Consent
By using our Application, you consent to the terms outlined in this Privacy Policy.
`;

function PrivacyPolicy() {
  return (
    <Box
      sx={{
        maxWidth: '1200px',
        marginX: 'auto',
        padding: '16px',
        marginTop: '16px',
        marginBottom: '48px',
        '& h1': {
          fontSize: '24px',
          marginY: '28px'
        },
        '& h2': {
          fontSize: '20px',
          marginY: '12px'
        },
        '& h3': {
          fontSize: '18px',
          marginY: '8px'
        },
        '& p:first-of-type': {
          marginY: '28px'
        },
        '& p': {
          fontSize: '16px',
          marginY: '8px',
          textAlign: 'justify'
        },
        '& ul': {
          paddingInlineStart: '32px'
        },
        '& li': {
          fontSize: '16px'
        }
      }}
    >
      <ReactMarkdown>{PrivacyPolicyMarkdown}</ReactMarkdown>
    </Box>
  );
}

export default PrivacyPolicy;

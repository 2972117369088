import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { TooltipBox } from './styles';

const CustomPopup = ({
  index,
  step,
  primaryProps,
  tooltipProps,
  totalSteps,
  onClose,
  handleNext,
  isLastStep
}) => {
  const [showPopup, setShowPopup] = useState(true);
  const { t } = useTranslation();

  const handleClose = (e) => {
    setShowPopup(false);
    onClose();
  };

  return (
    <TooltipBox {...tooltipProps}>
      <Box>
        <Box>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 5,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </Box>
        <Box>
          {step.title && (
            <Typography fontWeight={500} fontSize={'17px'} padding={'8px 0'}>
              {step.title}
            </Typography>
          )}
          <Box>{step.content}</Box>
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography>{`${index + 1}/${totalSteps}`}</Typography>
        <Button
          {...primaryProps}
          variant={isLastStep ? 'contained' : 'outlined'}
          sx={{ padding: '10px', fontSize: '16px', fontWeight: 500 }}
          size="small"
          autoFocus
          color={isLastStep ? 'secondary' : 'primary'}
          onClick={(e) => {
            primaryProps.onClick(e);
            handleNext();
          }}
        >
          {isLastStep ? `${t(T.gotIt)}` : `${t(T.next)} >`}
        </Button>
      </Box>
    </TooltipBox>
  );
};

export default CustomPopup;

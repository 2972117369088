import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, Stack, TextField } from '@mui/material';
import { useRenameChatMutation } from 'src/services/api';
import { useDispatch } from 'react-redux';
import { RootState, useSelector } from 'src/redux/store';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { DataApiInputParams } from 'src/types/api';
import { setAlert } from 'src/redux/slices/snackbar';
import { ALERT } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import useMutation from 'src/hooks/useMutation';
import { ErrorContext } from 'src/utils/errorMappings';

type RenameChatProps = {
  toggleEditing: () => void;
  onTitleEdited: (id: number, title: string) => void;
  title: string;
};

const RenameChat = ({
  toggleEditing,
  title: subject,
  onTitleEdited
}: RenameChatProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const [title, setTitle] = useState<string>(subject);
  const inputRef = useRef<HTMLInputElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const [renameChat, { isLoading: isUpdating }] = useMutation({
    api: useRenameChatMutation,
    errorContext: ErrorContext.CHAT
  });

  const onClickClose = () => {
    toggleEditing();
  };

  const onCommit = useCallback(async () => {
    const title = inputRef.current.value;
    if (!title) {
      return;
    }
    const apiParams: DataApiInputParams = {
      q: 'chat',
      method: 'PATCH',
      params: {
        id: selectedChat,
        topic: title
      }
    };
    await renameChat({
      params: apiParams,
      fallbackMsg: T.chatRenameFailed,
      successMsg: T.chatRenameSuccess
    });
    onTitleEdited(selectedChat, title);
    onClickClose();
  }, [selectedChat]);

  const onChange = (event) => {
    setTitle(event.target.value);
  };

  useEffect(() => {
    inputRef?.current?.select();
  },[])

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (event && event?.target instanceof Node) {
        const target = event.target as Node;
        if (
          inputRef.current &&
          !inputRef.current.contains(target) &&
          closeButtonRef.current &&
          !closeButtonRef.current.contains(target)
        ) {
          onCommit();
        }
      }
    },
    [onCommit]
  );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onCommit();
    }
  }

  useEffect(() => {
    // using "mousedown" instead of "click" because click event from the edit button bubbles up
    // to the document handler, and immediately saves the title with the same initial text
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <TextField
        id="outlined-basic"
        variant="outlined"
        size="small"
        color="secondary"
        inputProps={{
          style: { color: 'white', borderRadius: 0 },
          className: 'chat-title-input'
        }}
        sx={{ mr: 1 }}
        value={title}
        onChange={onChange}
        inputRef={inputRef}
        onKeyDown={handleKeyDown}
        autoFocus
      />

      <Stack direction="row">
        <IconButton onClick={onCommit} size="small" disabled={isUpdating}>
          <DoneIcon color="secondary" fontSize="small" />
        </IconButton>
        <IconButton onClick={onClickClose} size="small" ref={closeButtonRef}>
          <CloseIcon color="secondary" fontSize="small" />
        </IconButton>
      </Stack>
    </>
  );
};

export default RenameChat;

import { IS_ENCRYPTION_ENABLED } from 'src/config';
import {
  Builder,
  appendFormData,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { getEncryptParams } from 'src/keycloak';
import {
  ICreateIdentityProviderParams,
  ICreateIdentityProviderResponse,
  ICreateResourceProviderParams,
  IGetIdentityProviderResponse,
  IGetResourceProvider,
  IUpdateIdentityProviderParams,
  IUpdateResourceProviderParams
} from 'src/types/settings';
import { getPayload } from 'src/utils/encryption';
import { blobToBuffer, decryptFileData } from 'src/utils/files/file_encryption';
import { DataApiInputParams } from 'src/types/api';

export default (builder: Builder) => ({
  getIdentityProvider: builder.query<IGetIdentityProviderResponse, void>({
    async queryFn(args, { getState }, extraOptions, fetchWithBQ) {
      let url = `providers/identity_provider`;
      let decryptKey = getEncryptParams()?.k;

      if (IS_ENCRYPTION_ENABLED) {
        url = `${url}?payload=${btoa(getPayload('', getEncryptParams()))}`;
      }

      let result: any = await fetchWithBQ({
        url: url,
        ...getHeaders(url),
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });

      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    providesTags: ['IdentityProvider']
  }),
  createIdentityProvider: builder.mutation<
    { data: ICreateIdentityProviderResponse },
    ICreateIdentityProviderParams
  >({
    async queryFn(
      args: ICreateIdentityProviderParams,
      { getState },
      extraOptions,
      fetchWithBQ
    ) {
      let url = `providers/identity_provider`;
      const formData = appendFormData(args.params);
      let result: any = await fetchWithBQ({
        url: url,
        method: 'POST',
        ...getHeaders(url, formData),
        body: formData,
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });
      let decryptKey = getEncryptParams()?.k;
      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    invalidatesTags: ['IdentityProvider']
  }),
  updateIdentityProvider: builder.mutation<
    IGetIdentityProviderResponse,
    IUpdateIdentityProviderParams
  >({
    async queryFn(args, { getState }, extraOptions, fetchWithBQ) {
      let url = `providers/identity_provider`;
      let formData = appendFormData(args.params);

      let result: any = await fetchWithBQ({
        url: url,
        method: 'PATCH',
        body: formData,
        ...getHeaders(url, formData),
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });
      let decryptKey = getEncryptParams()?.k;
      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    invalidatesTags: ['IdentityProvider']
  }),
  deleteIdentityProvider: builder.mutation({
    async queryFn(
      args: { params: { id: number } },
      { getState },
      extraOptions,
      fetchWithBQ
    ) {
      let url = `providers/identity_provider`;
      const formData = appendFormData(args.params);
      let result: any = await fetchWithBQ({
        url: url,
        method: 'Delete',
        ...getHeaders(url, formData),
        body: formData,
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });
      let decryptKey = getEncryptParams()?.k;
      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    invalidatesTags: ['IdentityProvider']
  }),

  getResourceProvider: builder.query<
    unknown,
    { params: IGetResourceProvider } | void
  >({
    async queryFn(args, { getState }, extraOptions, fetchWithBQ) {
      const queryString = getQueryString(args && args.params);
      let url = `providers/resource_provider${
        queryString.length ? '?' + queryString : ''
      }`;

      let decryptKey = getEncryptParams()?.k;

      let result: any = await fetchWithBQ({
        url: url,
        ...getHeaders(url),
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });

      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    providesTags: ['ResourceProvider']
  }),

  createResourceProvider: builder.mutation<
    unknown,
    ICreateResourceProviderParams
  >({
    async queryFn(args, _, __, fetchWithBQ) {
      let url = `providers/resource_provider`;
      let formData = appendFormData(args.params);

      let result: any = await fetchWithBQ({
        url: url,
        method: 'POST',
        body: formData,
        ...getHeaders(url, formData),
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });
      let decryptKey = getEncryptParams()?.k;
      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    invalidatesTags: ['ResourceProvider']
  }),
  updateResourceProvider: builder.mutation<
    unknown,
    IUpdateResourceProviderParams
  >({
    async queryFn(args, _, __, fetchWithBQ) {
      let url = `providers/resource_provider`;
      let formData = appendFormData(args.params);

      let result: any = await fetchWithBQ({
        url: url,
        method: 'PATCH',
        body: formData,
        ...getHeaders(url, formData),
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });
      let decryptKey = getEncryptParams()?.k;
      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    invalidatesTags: ['ResourceProvider']
  }),
  deleteResourceProvider: builder.mutation<unknown, { params: { id: number } }>(
    {
      async queryFn(args, _, __, fetchWithBQ) {
        let url = `providers/resource_provider`;
        let formData = appendFormData(args.params);

        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['ResourceProvider']
    }
  ),
  downloadCertificate: builder.query<any, void>({
    async queryFn(args, { getState }, extraOptions, fetchWithBQ) {
      let url = `app/certificates`;

      if (IS_ENCRYPTION_ENABLED) {
        url = `${url}?payload=${btoa(getPayload('', getEncryptParams()))}`;
      }
      let result: any = await fetchWithBQ({
        url: url,
        ...getHeaders(url),
        responseHandler: async (response) => ({
          ret_val: await response.blob()
        })
      });
      const bufferData = (await blobToBuffer(
        result.data.ret_val as Blob
      )) as Buffer;
      return { data: bufferData };
    }
  }),
  getAppSettings: builder.query({
    async queryFn(
      arg: DataApiInputParams,
      { getState },
      extraOptions,
      fetchWithBQ
    ) {
      let decryptKey = getEncryptParams()?.k;
      let url = `fw/resource-settings?${getQueryString(arg.params)}`;
      let result: any = await fetchWithBQ({
        url: url,
        ...getHeaders(url),
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });
      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    providesTags: ['ResourceSettings']
  }),
  updateAppSettings: builder.mutation({
    async queryFn(
      arg: DataApiInputParams,
      { getState },
      extraOptions,
      fetchWithBQ
    ) {
      let decryptKey = getEncryptParams()?.k;
      let formData = appendFormData(arg.params);
      let url = `fw/resource-settings`;
      let result: any = await fetchWithBQ({
        url: url,
        method: 'POST',
        body: formData,
        ...getHeaders(url, formData),
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });
      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    },
    invalidatesTags: ['ResourceSettings']
  }),
  getModels: builder.query({
    async queryFn(
      arg: DataApiInputParams,
      { getState },
      extraOptions,
      fetchWithBQ
    ) {
      let decryptKey = getEncryptParams()?.k;
      let url = `expert-advisor/ai-models?${getQueryString(arg.params)}`;
      let result: any = await fetchWithBQ({
        url: url,
        method: 'GET',
        ...getHeaders(url),
        responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
      });

      const response = decryptResponse(result, decryptKey);
      return response.code > 0
        ? { data: response.ret_val }
        : serializeErrorResponse(response);
    }
  })
});

import { KEY_B64, IS_ENCRYPTION_ENABLED } from '../config';
import { v4 } from 'uuid';
import { AES, enc, lib, SHA256, SHA512, pad, mode } from 'crypto-js';
import {
  IDLE_SESSION_TIME,
  KCC_PARAMS,
  KCC_TOKEN,
  KC_ID_TOKEN,
  KC_REFRESH_TOKEN,
  KC_TOKEN,
  USER_LOGGED_IN,
  getEncryptParams,
  setEncryptParams,
  setIdleSessionTime,
  setKcIdToken,
  setKcToken,
  setKccToken,
  setRefreshToken,
  setUserLogInStatus
} from '../keycloak';
import { API_BASE_URL } from 'src/services/api';

export const KEY_DB64 = enc.Utf8.stringify(enc.Base64.parse(KEY_B64));
const KEY_BDB64 = enc.Utf8.parse(KEY_DB64.slice(32, 64));

const key_local_b64 = 'Jk9DNyY0YnRGZEJUdCRVZHdoJVR5N14jSUhSWio3Y0E=';
const key_local_db64 = enc.Utf8.stringify(enc.Base64.parse(key_local_b64));

export const getParam = (param: any, uriEncode?: boolean) => {
  if (!IS_ENCRYPTION_ENABLED) {
    return param;
  }
  let encryptedParam = encrypt2(getEncryptParams()?.k, param?.toString());
  return uriEncode ? encodeURIComponent(encryptedParam) : encryptedParam;
};

export function encrypt2(key: any, data: any) {
  // k = enc.Utf8.parse(enc.Base64.stringify(key))
  let iv = lib.WordArray.random(16); // Generate a random 16 bytes IV

  let encrypted = AES.encrypt(data, key, {
    iv: iv,
    padding: pad.Pkcs7,
    mode: mode.CBC
  }); // Use CBC-mode and PKCS7-padding

  let joinedData = iv.clone().concat(encrypted.ciphertext); // Concat IV and Cipher Text

  let joinedDataB64 = enc.Base64.stringify(joinedData);
  return joinedDataB64;
}

function XOR_hex(args: any) {
  let res = '',
    i = args[0].length;
  while (i-- > 0) {
    let j = 0;
    let temp = parseInt(args[0].charAt(i), 16);
    while (++j < args.length) {
      temp ^= parseInt(args[j].charAt(i), 16);
    }
    res = temp.toString(16) + res;
  }
  return res;
}

export function KDF(dec_token: any, token: any, key_db64: any) {
  let mac = SHA512(token).toString();
  let d = new Date();
  let t = Math.round(d.getTime() / 1000).toString();
  let u = v4().toString();
  let s1 = XOR_hex([
    u,
    dec_token.nonce.toString(),
    dec_token.sid.toString(),
    dec_token.session_state.toString(),
    dec_token.sub.toString(),
    dec_token.jti.toString()
  ]);
  let s2 = XOR_hex([
    t,
    dec_token.iat.toString(),
    dec_token.exp.toString(),
    dec_token.auth_time.toString()
  ]);
  let m1 = SHA256(s1).toString();
  let m2 = SHA256(s2).toString();
  let m3 = SHA256(key_db64).toString();
  let temp = XOR_hex([m1, m2, m3]);
  let k = enc.Utf8.parse(temp.slice(0, 32));
  let ka = enc.Utf8.parse(temp.slice(32, 64));
  let out = { k: k, s1: s1, s2: s2, u: u, ka: ka, mt: mac };
  return out;
}

export function getChallenge(p: any, prev_p: any) {
  let d = new Date();
  let t = Math.round(d.getTime() / 1000) - Math.floor(Math.random() * 1000);
  let u = v4().toString();
  let u_challenge =
    u +
    ':::' +
    t +
    ':::' +
    p.u +
    ':::' +
    p.mt +
    ':::' +
    (prev_p == null ? '-' : prev_p.u);
  let params = p.s1 + ':::' + u + ':::' + t + ':::' + p.s2;
  let encrypted_params = encrypt2(KEY_BDB64, params);
  let challenge = encrypt2(p.ka, u_challenge);
  let ret = challenge + ':::' + encrypted_params;
  delete p.s1;
  delete p.s2;
  return ret;
}

function getPayloadChallenge(m: any, p: any) {
  let d = new Date();
  let t = Math.round(d.getTime() / 1000) - Math.floor(Math.random() * 1000);
  let u = v4().toString();
  let u_challenge = u + ':::' + t + ':::' + m + ':::' + p.c;
  let params = p.mt + ':::' + u + ':::' + t;
  let challenge = encrypt2(p.ka, u_challenge);
  let encrypted_params = encrypt2(KEY_BDB64, params);
  let ret = challenge + ':::' + encrypted_params;
  return ret;
}

export function getPayload(payload: any, p: any, b64?: any) {
  if (!IS_ENCRYPTION_ENABLED) {
    return payload;
  }
  let mac = SHA512(payload);
  let encrypted = encrypt2(p.k, payload);
  let ch = getPayloadChallenge(mac, p);
  if (b64) {
    let y = ch + ':::' + encrypted;
    let wA = lib.WordArray.create(y);
    let x = enc.Base64.stringify(wA);
    return x;
  }
  return ch + ':::' + encrypted;
}

export function getEncryptedToken(params: any, tok: any) {
  if (!IS_ENCRYPTION_ENABLED) {
    return tok;
  }
  let token = tok + ':::' + params.mt + ':::' + params.u;
  return encrypt2(params.k, token);
}

export function getChallengeHeader(params: any, url: any, formData: any) {
  if (!IS_ENCRYPTION_ENABLED) {
    return params.c;
  }
  let object: any = {};
  if (formData) {
    formData.forEach(function (value: any, key: any) {
      if (key !== 'file') {
        object[key] = value;
      }
    });
  }
  let data =
    API_BASE_URL +
    url +
    (formData == null ? '' : ':::' + JSON.stringify(object));
  let mac = SHA512(data);
  return getPayloadChallenge(mac, params);
}

export function encrypt_local(data: any) {
  if (!IS_ENCRYPTION_ENABLED) {
    return data;
  }
  let word_key = enc.Base64.parse(key_local_db64);
  let k = enc.Utf8.parse(enc.Base64.stringify(word_key));
  let ciphertext_b64 = encrypt2(k, data);

  return ciphertext_b64;
}

export function decrypt_local(ciphertext_b64: any) {
  if (!IS_ENCRYPTION_ENABLED) {
    return ciphertext_b64;
  }
  let word_key = enc.Base64.parse(key_local_db64);
  let k = enc.Utf8.parse(enc.Base64.stringify(word_key));
  let data = decrypt3(k, ciphertext_b64);

  return data;
}

export function decrypt3(key: any, data: any, encUtf: boolean = true) {
  let decoded_wb64 = enc.Base64.parse(data);
  let iv1 = lib.WordArray.create(decoded_wb64.words.slice(0, 4));
  let ct = lib.WordArray.create(decoded_wb64.words.slice(4));
  let ct_text = enc.Base64.stringify(ct);
  let org = AES.decrypt(ct_text, key, { iv: iv1 });
  return encUtf ? org.toString(enc.Utf8) : org;
}

export function setAllLocalStorageKeys() {
  if (!IS_ENCRYPTION_ENABLED) {
    return;
  }
  setKcToken(window.localStorage.getItem(KC_TOKEN) || '');
  setKcIdToken(window.localStorage.getItem(KC_ID_TOKEN) || '');
  setUserLogInStatus(window.localStorage.getItem(USER_LOGGED_IN) || '');
  setRefreshToken(window.localStorage.getItem(KC_REFRESH_TOKEN) || '');
  setIdleSessionTime(window.localStorage.getItem(IDLE_SESSION_TIME) || '');
  setKccToken(window.localStorage.getItem(KCC_TOKEN) || '');
  setEncryptParams(window.localStorage.getItem(KCC_PARAMS) || '');
}

import { alpha, Box, Button, Link, Typography, useTheme } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import TextField from 'src/components/TextField';
import { FormFooterContainer, StyledButton } from './styles';
import { isEmailValid } from 'src/utils/utils';
import { API_BASE_URL } from 'src/services/api';
import axios from 'src/utils/axios';
import { BASE_SERVER_URL } from 'src/config';
import SignupHeader from './SignupHeader';
import SignupConfirmation from './SignupConfirmation';
import KCThemeTemplate from '../../components/KCThemeTemplate';
import { useDispatch } from 'src/redux/store';
import { setAlert } from 'src/redux/slices/snackbar';
import { ErrorContext, ErrorMessages } from 'src/utils/errorMappings';
import { ALERT } from 'src/types/enum';

interface IFormInput {
  email: string;
}
const Signup = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isInvitationSent, setIsInvitationSend] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    setIsLoading(true);
    const formdata = new FormData();
    formdata.append('email', data.email);
    try {
      const { data } = await axios.post(
        `${BASE_SERVER_URL}${API_BASE_URL}keycloak/Users/register`,
        formdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      setIsLoading(false);
      if (data?.code < 0) {
        dispatch(
          setAlert({
            msg:
              ErrorMessages[ErrorContext.USERS][data?.extended?.error_name] ||
              t(T.internalServerError),
            type: ALERT.ERROR
          })
        );
      } else setIsInvitationSend(true);
    } catch (error) {
      dispatch(
        setAlert({
          msg: t(T.internalServerError),
          type: ALERT.ERROR
        })
      );
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title={`${t(T.empowerGPT)} - ${t(T.signup)}`} />
      <KCThemeTemplate
        header={isInvitationSent ? <SignupConfirmation /> : <SignupHeader />}
      >
        {!isInvitationSent && (
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              width="100%"
              mt={{ xs: 3.5, sm: 2.5 }}
            >
              <Box>
                <Typography color="#000C57B2" fontFamily="Actor">
                  {t(T.workEmail)}
                </Typography>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t(T.emailRequired) as string,
                    validate: (value) =>
                      isEmailValid(value) || (t(T.emailNotValid) as string)
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      customLabel={true}
                      height={'42px'}
                      placeholder="JohnDoe@example.com"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email ? errors.email.message : ''}
                      autoFocus
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      ref={ref}
                    />
                  )}
                />
              </Box>
              <StyledButton
                loading={isLoading}
                disabled={isLoading}
                type="submit"
              >
                {t(T.getInvite)}
              </StyledButton>
              <FormFooterContainer>
                <span>{t(T.alreadyHaveAccount)}</span>
                <Link
                  underline="always"
                  color={'#000C57B2'}
                  marginLeft={1}
                  href="/chat"
                >
                  {t(T.login)}
                </Link>
              </FormFooterContainer>
            </Box>
          </form>
        )}
      </KCThemeTemplate>
    </React.Fragment>
  );
};

export default Signup;

import { getEncryptParams } from 'src/keycloak';
import {
  Builder,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { DataApiInputParams } from 'src/types/api';
import { groupBy } from 'lodash';

export default (builder: Builder) => {
  return {
    getLookups: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `fw/lookups?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        let grouped: any = {};
        if (response?.ret_val?.data) {
          grouped = groupBy(response.ret_val?.data, 'type_code');
        }
        return grouped ? { data: grouped } : serializeErrorResponse(response);
      },
      providesTags: ['Tags']
    }),
    getTags: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `fw/tags?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Tags']
    })
  };
};

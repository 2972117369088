import { LoadingButton } from '@mui/lab';
import { styled, Box, Button } from '@mui/material';

export const FormFooterContainer = styled(Box)(({ theme }) => ({
  marginTop: '-8px',
  fontSize: '14px',
  color: '#000C57B2',
  fontFamily: 'Actor',
}));

export const StyledButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: '8px',
  fontWeight: 400,
  border: '1px solid',
  width: '100%',
  fontFamily: 'Actor',
  fontSize: '16px'
}));

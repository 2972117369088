import { getEncryptParams } from 'src/keycloak';
import {
  Builder,
  appendFormData,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { DataApiInputParams } from 'src/types/api';
import { getPayload } from 'src/utils/encryption';

export default (builder: Builder) => {
  return {
    getRoles: builder.query({
      async queryFn(
        arg: DataApiInputParams | void,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const queryString = getQueryString(arg && arg.params);
        let url = `keycloak/ClientRole${
          queryString.length ? '?' + queryString : ''
        }`;
        let decryptKey = getEncryptParams()?.k;

        if (IS_ENCRYPTION_ENABLED) {
          url = `${url}${queryString.length ? '&' : '?'}payload=${btoa(
            getPayload('', getEncryptParams())
          )}`;
        }

        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Roles']
    }),
    createRole: builder.mutation({
      async queryFn(
        args: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let url = `keycloak/ClientRole`;
        let formData = appendFormData(args.params);

        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Roles']
    }),
    deleteRole: builder.mutation({
      async queryFn(
        args: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let url = `keycloak/ClientRole`;
        let formData = appendFormData(args.params);
        const result: any = await fetchWithBQ({
          url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Roles']
    }),
    updateRole: builder.mutation({
      async queryFn(args: DataApiInputParams, _, __, fetchWithBQ) {
        let url = `keycloak/ClientRole`;
        let formData = appendFormData(args.params);

        let result: any = await fetchWithBQ({
          url: url,
          method: 'PATCH',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Roles']
    })
  };
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { StyledBox } from './styles';
import { ReactComponent as Frame } from 'src/assets/userOnboard/frame-icon.svg';
import { ReactComponent as Share } from 'src/assets/userOnboard/share-icon.svg';
import { ReactComponent as Upload } from 'src/assets/userOnboard/upload-icon.svg';
import { ReactComponent as CloseIcon } from 'src/assets/close-icon.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  handleStartTour: () => void;
}

const WelcomePopup = ({ isOpen, onClose, handleStartTour }: IProps) => {
  const { t } = useTranslation();

  const handleClose = (e, reason = '') => {
    if (reason !== 'backdropClick') onClose();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: (theme) => ({
          borderRadius: theme.general.borderRadiusXl
        })
      }}
      disableEscapeKeyDown
      onClose={handleClose}
      keepMounted
    >
      <DialogTitle>
        <Typography variant={'h3'}>{t(T.welcomeText)} Empower GPT</Typography>
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid mb={2} container spacing={4}>
          <Grid item xs={4}>
            <StyledBox>
              <Typography sx={{ fontWeight: 500, fontSize: '17px' }}>
                {t(T.Chat)}
              </Typography>
              <Frame width={100} height={100} />
            </StyledBox>
          </Grid>
          <Grid item xs={4}>
            <StyledBox>
              <Typography sx={{ fontWeight: 500, fontSize: '17px' }}>
                {t(T.upload)}
              </Typography>
              <Upload width={100} height={100} />
            </StyledBox>
          </Grid>
          <Grid item xs={4}>
            <StyledBox>
              <Typography sx={{ fontWeight: 500, fontSize: '17px' }}>
                {t(T.share)}
              </Typography>
              <Share width={100} height={100} />
            </StyledBox>
          </Grid>
        </Grid>
        <Typography mb={'6px'}>{t(T.welcomeText1)}</Typography>
        <Typography>{t(T.welcomeText2)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleStartTour}
          variant="contained"
          size="small"
          autoFocus
          color="secondary"
        >
          {t(T.gotIt)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomePopup;

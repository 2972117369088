import React from 'react';
import Box from '@mui/material/Box';

const CustomArrow = ({ position, orientation }) => {
  let coloredSide: {};

  switch (orientation) {
    case 'top':
      coloredSide = { borderBottom: '24px solid white' };

      break;
    case 'bottom':
      coloredSide = { borderTop: '24px solid white' };

      break;
    case 'left':
      coloredSide = { borderLeft: '24px solid white' };

      break;
    case 'right':
      coloredSide = { borderRight: '24px solid white' };

      break;
    default:
      coloredSide = {};
  }

  return (
    <Box
      className="arrow"
      sx={{
        position: 'absolute',
        width: 0,
        height: 0,
        borderLeft: '24px solid transparent',
        borderRight: '24px solid transparent',
        borderTop: '24px solid transparent',
        borderBottom: '24px solid transparent',
        ...coloredSide,
        top: position.top,
        left: position.left,
        right: position.right,
        bottom: position.bottom
      }}
    />
  );
};

export default CustomArrow;

import { createSlice } from '@reduxjs/toolkit';
import { StringKeys } from 'src/types/base';

type LibraryReducer = {
  namespaceId: string;
  selectedFolderId?: string;
  tenant_info: StringKeys;
};

const initialState: LibraryReducer = {
  namespaceId: '',
  tenant_info: {},
  selectedFolderId: undefined
};

const LibrarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setTenantInfo(state, action) {
      state.tenant_info = action.payload;
    },
    setNamespaceId(state, action) {
      state.namespaceId = action.payload;
    },
    setSelectedFolderId(state, action) {
      state.selectedFolderId = action.payload;
    }
  }
});

export default LibrarySlice;
export const reducer = LibrarySlice.reducer;
export const { setNamespaceId, setSelectedFolderId, setTenantInfo } =
  LibrarySlice.actions;

import { Box, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { DocumentsContextProvider } from 'src/contexts/FileManagerContext';
import { toggleChatHistoryPan } from 'src/redux/slices/chat';
import { RootState } from 'src/redux/store';
import SidebarBottomSection from './BottomSection';
import SidebarMidSection from './Favourites';
import HistoryPanel from './History/HistoryPanel';
import { CustomDivider } from './styles';
import SidebarTopSection from './TopSection';

function Sidebar() {
  const theme = useTheme();
  const { showChatHistoryPanel } = useSelector((state: RootState) => state.chat);
  const { pathname } = useLocation();
  const params = useParams();
  const dipatch = useDispatch();

  useEffect(() => {
    const regex = /^\/chat\/\d+$/;
    const isChatPath = regex.test(pathname);
    if (isChatPath) dipatch(toggleChatHistoryPan());
  }, []);

  return (
    <Box
      maxHeight={'100vh'}
      height={'100vh'}
      sx={{
        backgroundColor: theme.sidebar.background
      }}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" flexDirection="column">
        <SidebarTopSection />
      </Box>
      {showChatHistoryPanel ? (
        <HistoryPanel />
      ) : (
        <>
          <DocumentsContextProvider>
            <SidebarMidSection />
          </DocumentsContextProvider>
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <CustomDivider />
            <SidebarBottomSection />
          </Box>
        </>
      )}
    </Box>
  );
}

export default Sidebar;

import { useContext, useMemo, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, useTheme } from '@mui/material';
import { ReactComponent as AssistantChatBubble } from 'src/assets/assistantIcons/assistant-bubble.svg';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';
import { RootState, useSelector } from 'src/redux/store';
import { ChatData } from 'src/types/chat';
import { CHAT_CLASS } from 'src/types/enum';
import { ChatListActionButtonsBox, ChatListBox } from '../../styles';
import DeleteChat from './DeleteChat';
import RenameChat from './RenameChat';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { isMobile } from 'react-device-detect';

type Props = {
  data: ChatData;
  onChatDeleted: (chatId: number) => void;
  onTitleEdited: (id: number, title: string) => void;
};

function ChatList({ data: item, onTitleEdited, onChatDeleted }: Props) {
  const { toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const buttonRef = useRef<HTMLAnchorElement | null>(null);
  const [isEditingEnabled, setEditingEnabled] = useState<boolean>(false);
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const navigate = useNavigate();

  const showMoreButton = useMemo(
    () => selectedChat === item.id,
    [selectedChat, item]
  );

  const toggleEditing = () => {
    setEditingEnabled((isEditing) => !isEditing);
  };

  const handleEditClick = () => {
    if (selectedChat !== item?.id) {
      navigate(`/chat/${item?.id}`);
    }
    toggleEditing();
  };

  return (
    <ListItem
      key={item.id}
      disablePadding
      onClick={isMobile ? toggleSidebar : undefined}
    >
      <ChatListBox component="div" title={item.subject}>
        <Button
          ref={buttonRef}
          disableRipple
          fullWidth
          className={`chat-button ${showMoreButton ? 'Mui-active' : ''}`}
          component={selectedChat !== item.id ? RouterLink : Button}
          startIcon={
            item?.chat_class === CHAT_CLASS.ASSISTANT ? (
              <AssistantChatBubble />
            ) : (
              <ChatBubbleOutlineIcon
                sx={{
                  color: 'white',
                  padding: theme.spacing(0.2)
                }}
              />
            )
          }
          to={`/chat/${item.id}`}
          sx={{
            height: '36px',
            borderRadius: theme.general.borderRadiusSm,
            flex: 1
          }}
        >
          {isEditingEnabled ? (
            <RenameChat
              toggleEditing={toggleEditing}
              title={item.subject}
              onTitleEdited={onTitleEdited}
            />
          ) : (
            <CustomListItemText primaryText={item.subject} />
          )}
        </Button>
        {!isEditingEnabled && (
          <ChatListActionButtonsBox className="chat-action-buttons">
            <DeleteChat onDelete={onChatDeleted} chat_id={item.id} />
            <IconButton onClick={handleEditClick} size="small">
              <EditOutlinedIcon htmlColor="white" fontSize="small" />
            </IconButton>
          </ChatListActionButtonsBox>
        )}
      </ChatListBox>
    </ListItem>
  );
}

export default ChatList;

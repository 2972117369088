import React from 'react';
import ReactDOM from 'react-dom';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';
import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import { store, persistor } from 'src/redux/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';

import { kcinit, getKcToken, isValidToken } from './keycloak';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-virtualized/styles.css';
import './global.css';
import logger from './utils/logger';
import { isPublicRoute } from './utils/utils';

function renderApp(divId: string) {
  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SidebarProvider>
              <BrowserRouter>
                <ScrollTop />
                <App />
              </BrowserRouter>
            </SidebarProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById(divId)
  );
}

function init() {
  const divId = 'root-component';

  let isFocusLost = false;
  document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
      //tab blurred
      isFocusLost = true;
    } else {
      //Page is in user view
      if (isFocusLost) {
        //if tab was changed, check kc_token
        const kcToken = getKcToken();
        if (!kcToken || !isValidToken(kcToken)) {
          window.location.reload();
        }
      }
    }
    window.onstorage = (e: any) => {
      if (e.key === 'prm') {
        logger.log(e.key, e.newValue);
      }
    };
  });

  window.onoffline = (e: any) => {
    isFocusLost = true;
  };

  window.ononline = (e: any) => {
    if (isFocusLost) window.location.reload();
  };

  try {
    if (isPublicRoute()) {
      renderApp(divId);
    } else {
      kcinit().then(() => {
        renderApp(divId);
      });
    }
  } catch (error: any) {
    logger.error(error.message || error);
  }
}

init();

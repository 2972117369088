import { CustomDivider } from '../styles';
import CreateChat from './Chat/CreateChat';

function SidebarTopSection() {
  return (
    <>
      <CreateChat />
      <CustomDivider />
    </>
  );
}

export default SidebarTopSection;

import { getEncryptParams } from 'src/keycloak';
import {
  Builder,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { getPayload } from 'src/utils/encryption';
import { PERMISSION_TYPE } from 'src/types/enum';

type IGetPermissionsParams = {
  params: {
    roles?: string;
    ptype?: PERMISSION_TYPE;
    rid?: string;
  };
};

export default (builder: Builder) => {
  return {
    getPermissions: builder.query({
      async queryFn(arg: IGetPermissionsParams, _, __, fetchWithBQ) {
        const queryString = getQueryString(arg.params);
        let url = `fw/permissions${
          queryString.length ? '?' + queryString : ''
        }`;
        let decryptKey = getEncryptParams()?.k;

        if (IS_ENCRYPTION_ENABLED) {
          url = `${url}${queryString.length ? '&' : '?'}payload=${btoa(
            getPayload('', getEncryptParams())
          )}`;
        }

        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val.data }
          : serializeErrorResponse(response);
      },
      providesTags: ['Permissions']
    })
  };
};

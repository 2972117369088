import { FC, useState, createContext } from 'react';
import { isMobile } from 'react-device-detect';

type SidebarContextValues = {
  isSidebarOpen: any;
  toggleSidebar: () => void;
};

export const SidebarContext = createContext<SidebarContextValues>(
  {} as SidebarContextValues
);

export const SidebarProvider: FC = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);

  const toggleSidebar = () => {
    setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
  };

  return (
    <SidebarContext.Provider
      value={{ isSidebarOpen, toggleSidebar }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

/**
 * Get absolute URL from provided url to ensure it has a valid protocol and hostname.
 * Fallback to window.location for hostname and protocol.
 * Will enforce secure protocol if window.location.protocol is https.
 * This function must be called in the browser context.
 *
 * DO NOT use this function with absolute URLs without protocol (e.g. www.google.com).
 * @param {string} url - URL to sanitize (e.g. /api/v1/data or http://localhost:5000/api/v1/data)
 * @param {object} options - Additional options
 * @param {string} options.protocol - Protocol to use (http or ws, defaults to http)
 * @param {boolean} options.forceSecure - Force secure protocol (defaults to false)
 * @param {boolean} options.trailingSlash - Ensure URL has trailing slash (defaults to false)
 * @param {boolean} options.required - Throw error if URL is not provided (defaults to true)
 * @returns {string} Sanitized URL
 */
export const absoluteUrl = (
  url: string = '',
  options?: {
    protocol?: 'http' | 'ws';
    forceSecure?: boolean;
    trailingSlash?: boolean;
    required?: boolean;
  }
): string => {
  const {
    protocol = 'http',
    forceSecure = false,
    trailingSlash = false,
    required = true
  } = options || {};

  if (!url && required) throw new Error('URL is required.');

  const { host, protocol: windowProtocol } = window?.top?.location || {};
  const proto =
    windowProtocol === 'https:'
      ? `${protocol}s:`
      : `${protocol}${forceSecure ? 's' : ''}:`;

  let parsedUrl: URL;
  try {
    parsedUrl = new URL(url);
    parsedUrl.protocol = proto;
  } catch {
    if (!host)
      throw new Error(
        `Called outside browser context, unable to process URL: ${url}`
      );
    parsedUrl = new URL(
      `${proto}//${host}/${url.startsWith('/') ? url.slice(1) : url}`
    );
  }
  const result = parsedUrl.toString();
  return trailingSlash
    ? result.replace(/\/?$/, '/')
    : result.replace(/\/$/, '');
};

import { getEncryptParams } from 'src/keycloak';
import { decrypt3, encrypt2 } from '../encryption';
import { lib } from 'crypto-js';
import logger from '../logger';

function getStringLengthInBytes(inputString) {
  // Convert the length of the string to its byte representation
  const stringLength = Buffer.alloc(4); // Allocate 4 bytes
  stringLength.writeUInt32BE(inputString?.length, 0);
  return stringLength;
}

function stringToBytes(inputString) {
  return Buffer.from(inputString);
}

function stringToBytesAndWrite(inputString: string) {
  const lengthInBytes = getStringLengthInBytes(inputString);

  // Combine the byte representations
  const dataToWrite = Buffer.concat([
    lengthInBytes,
    stringToBytes(inputString)
  ]);

  return dataToWrite;
}

export function getEncryptedFile(
  arrayBufferData,
  fileName: string,
  fileType: string
) {
  let key = getEncryptParams()?.k;
  let offset = 0;
  let chunks = [];
  const chunkSizeInBytes = 16 * 1024; //16kb chunk size
  while (offset < arrayBufferData.byteLength) {
    const chunk = arrayBufferData.slice(offset, offset + chunkSizeInBytes);
    var wordArray = lib.WordArray.create(chunk);
    let encrypted = encrypt2(key, wordArray);
    chunks.push(encrypted);
    offset += chunkSizeInBytes;
  }
  let arrayBuffer: Buffer[] = [];
  for (const chunk of chunks) {
    arrayBuffer.push(stringToBytesAndWrite(chunk));
  }
  let finalResult: Buffer = Buffer.concat(arrayBuffer);

  let encryptedBlob = new Blob([finalResult]);
  var encryptedFile = new File([encryptedBlob], fileName, {
    type: fileType
  });
  return encryptedFile;
}

export function readFileAsBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = (event) => {
      reject(event.target.error);
    };
    reader.readAsArrayBuffer(file);
  });
}

export async function blobToBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const buffer = Buffer.from(reader.result as ArrayBuffer);
      resolve(buffer);
    };
    reader.onerror = () => {
      reject(new Error('Failed to convert Blob to Buffer'));
    };
    reader.readAsArrayBuffer(blob);
  });
}

export function readChunksFromBuffer(bufferData) {
  const chunks = [];
  let currentIndex = 0;
  while (currentIndex < bufferData.length) {
    const chunkLength = bufferData.readInt32BE(currentIndex);
    currentIndex += 4;
    const chunk = bufferData.slice(currentIndex, currentIndex + chunkLength);
    chunks.push(chunk);
    currentIndex += chunkLength;
  }
  return chunks;
}

function convertWordArrayToUint8Array(wordArray) {
  var arrayOfWords = wordArray.hasOwnProperty('words') ? wordArray.words : [];
  var length = wordArray.hasOwnProperty('sigBytes')
    ? wordArray.sigBytes
    : arrayOfWords.length * 4;
  var uInt8Array = new Uint8Array(length),
    index = 0,
    word,
    i;
  for (i = 0; i < length; i++) {
    word = arrayOfWords[i];
    uInt8Array[index++] = word >> 24;
    uInt8Array[index++] = (word >> 16) & 0xff;
    uInt8Array[index++] = (word >> 8) & 0xff;
    uInt8Array[index++] = word & 0xff;
  }
  return uInt8Array;
}

export const downloadFileToSystem = (data, name) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const decryptFileData = async (bufferData) => {
  let decryptedData;

  try {
    const chunks = await readChunksFromBuffer(bufferData);
    let decryptedChunks = [];

    for (const chunk of chunks) {
      const decrypted = decrypt3(
        getEncryptParams()?.k,
        chunk.toString(),
        false
      );
      decryptedChunks.push(decrypted);
    }

    let combinedDecrypted = lib.WordArray.create();
    for (const x of decryptedChunks) {
      combinedDecrypted = combinedDecrypted.concat(x);
    }
    decryptedData = convertWordArrayToUint8Array(combinedDecrypted);
  } catch (err) {
    logger.error(err);
  }
  return decryptedData;
};

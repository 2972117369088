import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const itJson = {
  // General
  [T.empowerGPT]: 'EmpowerGPT',
  [T.knowledgeBaseChat]: 'Chat della base di conoscenza',
  [T.enterprise]: 'Aziendale',
  [T.worldKnowledge]: 'Conoscenza del mondo',
  [T.generalChat]: 'Chat generale',
  [T.languageSwitcher]: 'Seleziona lingua',
  [T.logout]: 'Disconnettersi',
  [T.profile]: 'Profilo',
  [T.uploadImport]: 'Carica / Importa',
  [T.documents]: 'Documenti',
  [T.conversations]: 'Conversazioni',
  [T.valuableInsights]: 'Estrai rapidamente informazioni preziose',
  [T.uploadYourDocuments]: 'Carica documenti',
  [T.clearFilters]: 'Reimposta',
  [T.resetPassword]: 'Cambia Password',
  [T.userGroups]: 'Gruppi Utente',
  [T.userGroupName]: 'Nome del Gruppo Utente',
  [T.accountType]: 'Tipo di Account',
  [T.email]: 'Email',
  [T.startChat]: 'Inizia Chat',
  [T.createdWithTime]: 'Creato {{time}} fa',
  [T.modifiedWithTime]: 'Modificato {{time}} fa',
  [T.syncedWithTime]: 'Sincronizzato {{time}} fa',
  [T.rolesAndPermissions]: 'Ruoli e Autorizzazioni',
  [T.selectItemsToDelete]: 'Seleziona gli elementi da eliminare',
  [T.selectItemsToTag]: 'Seleziona gli elementi da contrassegnare',
  [T.selectItemsToMove]: 'Seleziona gli elementi da spostare',
  [T.noPermissionToDelete]: 'Non hai il permesso di eliminare',
  [T.noPermissionToView]: 'Non hai il permesso di visualizzare',
  [T.noPermissionToMoveHere]: 'Non hai il permesso di spostare qui',
  [T.noPermissionToDownload]: 'Non hai il permesso di scaricare',
  [T.tagDisabledForWorkspaceChat]:
    'La funzionalità di tag non è disponibile per le chat dello spazio di lavoro',
  [T.typeToSearch]: 'Digita per cercare',
  [T.disclaimerEgpt]: `L'AI Model può commettere errori, si prega di verificare con le citazioni fornite.`,
  [T.usage]: 'Utilizzo',
  [T.reprocess]: 'Riprocessare',
  [T.selectItemsToReprocess]: 'Seleziona gli elementi da riprocessare',
  [T.chatGPT]: 'ChatGPT',
  [T.fileProcessing]: 'Elaborazione di File',
  [T.enterpriseKnowledgeChat]: 'Chat di conoscenza aziendale',
  [T.worldKnowledgeChat]: 'Chat di conoscenza del mondo',
  [T.ok]: 'OK',
  [T.goBack]: 'Indietro',
  [T.settings]: 'Impostazioni',
  [T.llmsSettingsHeading]:
    'Modelli di intelligenza artificiale disponibili in chat',
  [T.selectModels]: 'Seleziona modelli',
  [T.modelsWarning]: 'Si prega di selezionare almeno un modello.',
  [T.version]: 'Versione',
  [T.configure]: 'Configurare',
  [T.tenantKnowledgeBase]: 'Conoscenza {{tenant}}',
  [T.noInternet]:
    'Nessuna connessione a Internet. Controllare la rete e riprovare.',
  [T.loading]: 'Caricamento',

  //Filters
  [T.search]: 'Ricerca',
  [T.searching]: 'Ricerca...',
  [T.accountTypeSelect]: 'Seleziona il Tipo di Account',
  [T.selected]: `Selezionato`,
  [T.noOptions]: 'Nessuna opzione',
  [T.filters]: 'Filtri',
  [T.revokeAccess]: `Revoca dell'accesso`,

  // Documents
  [T.uploadDocs]: 'Carica Documenti',
  [T.tagDocuments]: 'Etichetta Documenti',
  [T.allDocuments]: 'Tutti i documenti',
  [T.docName]: 'Nome del Documento',
  [T.uploadedBy]: 'Caricato da',
  [T.uploadedOn]: 'Caricato il',
  [T.actions]: 'Azioni',
  [T.view]: 'Visualizza',
  [T.sync]: 'Sincronizza',
  [T.share]: 'Condividi',
  [T.sharedWith]: 'Condiviso con',
  [T.invite]: 'Invita',
  [T.details]: 'Dettagli popolati da AI',
  [T.documentType]: 'Tipo di Documento',
  [T.size]: 'Dimensione',
  [T.length]: 'Lunghezza',
  [T.description]: 'Descrizione',
  [T.rowsPerPage]: 'Righe per pagina',
  [T.uploadTimeAndDateWithUser]: 'Caricato il {{time}} da {{user}}',
  [T.download]: 'Scarica',
  [T.uploadProgress]: 'Caricare i progressi',
  [T.document]: 'documento',
  [T.docsDropMessage]: 'Rilascia i documenti per iniziare il caricamento.',
  [T.uploadDocFailed]: 'Impossibile caricare i documenti.',
  [T.uploadDocsFailed]: 'Impossibile caricare il documento.',
  [T.uploadMessage]: 'Hai caricato ',
  [T.dropDocsMessage1]: 'Rilascia i documenti per iniziare il caricamento.',
  [T.dropDocsMessage2]: 'Trascina e rilascia i documenti qui.',
  [T.dropDocsRestriction]: 'Non puoi caricare questi tipi di documenti.',
  [T.dropDocsPartialRestriction]:
    'Alcuni tipi di documenti non validi sono stati respinti.',
  [T.failedFetchingDocuments]: 'Recupero dei documenti non riuscito.',
  [T.uploadDocsSuccess]: '{{count}} documento(i) caricato(i) con successo.',
  [T.uploadStatusBoxMessage]: `{{count}} documento/i sono stati caricati con successo. Puoi chiudere la finestra.`,
  [T.uploadDocsWarningMessage]:
    '{{count}} documento(i) caricato(i) con successo e {{failed}} fallito(i). Riprovando...',
  [T.uploadDocSuccess]: '{{count}} documento caricato con successo!',
  [T.documentsUploadSuccess]: 'Documenti caricati con successo!',
  [T.searchDocuments]: 'Cerca Documenti',
  [T.enterTagName]: 'Inserisci il nome del tag',
  [T.folderCreateSuccess]: 'Cartella creata con successo!',
  [T.collectionCreateSuccess]: 'Raccolta creata con successo!',
  [T.workspaceCreateSuccess]: 'Spazio di lavoro creato con successo!',
  [T.folderCreateError]: 'Errore nella creazione della risorsa!',
  [T.folderCreateSuccessAccesError]:
    'Cartella creata con successo! Impossibile aggiornare i permessi di accesso.',
  [T.folderUpdateSuccessAccessError]:
    'Cartella aggiornata con successo! Impossibile aggiornare i permessi di accesso.',
  [T.selectTags]: 'Seleziona i Tag',
  [T.docTaggedSuccess]: 'Documenti etichettati con successo!',
  [T.docTaggedError]: "Errore nell'etichettatura dei documenti!",
  [T.docTagsDeleteSuccess]: 'Tag eliminati con successo!',
  [T.docTagsDeleteError]: 'Impossibile eliminare i tag!',
  [T.docTagsUpdatedSuccess]: 'Tag aggiornati con successo!',
  [T.docTagsUpdatedError]: 'Aggiornamento dei tag non riuscito!',
  [T.selectedTags]: 'Tag selezionati',
  [T.tags]: 'Tag',
  [T.selectUploadTags]: 'Seleziona i tag da applicare sui documenti caricati',
  [T.maxDocSize]:
    'La lunghezza del nome del documento "{{ name }}" supera il limite massimo di 200 caratteri.',
  [T.uploaded]: 'Caricato',
  [T.modifiedBy]: 'Modificato da',
  [T.syncedBy]: 'Sincronizzato da',
  [T.syncedOn]: 'Sincronizzato il',
  [T.syncing]: 'Sincronizzazione in corso...',
  [T.syncingInGridView]: `Attendere mentre la cartella viene sincronizzata...`,
  [T.syncingFailed]: 'Tentativo di sincronizzazione non riuscito',
  [T.modifiedOn]: 'Modificato il',
  [T.status]: 'Stato',
  [T.documentDeletionFailed]: 'Eliminazione documento non riuscita.',
  [T.documentNotEmptyError]:
    'Non è consentito eliminare una cartella non vuota.',
  [T.uploadFolder]: 'Carica Cartella',
  [T.documentDeleteSuccess]: 'Documento eliminato con successo.',
  [T.documentsDeleteSuccess]: 'Documenti eliminati con successo.',
  [T.documentDeleteConfirmation]: 'Sei sicuro di voler eliminare il documento?',
  [T.collectionDeleteSuccess]: 'Collezione eliminata con successo!',
  [T.collectionDeleteConfirmation]:
    'Sei sicuro di voler eliminare la collezione?',
  [T.collectionDeletionFailed]: 'Eliminazione della collezione fallita.',
  [T.workspaceDeletionFailed]: 'Eliminazione dello spazio di lavoro fallita.',
  [T.workspaceDeleteSuccess]: 'Spazio di lavoro eliminato con successo!',
  [T.workspaceDeleteConfirmation]:
    'Sei sicuro di voler eliminare lo spazio di lavoro?',
  [T.multipleDocumentDeleteConfirmation]:
    'Sei sicuro di voler eliminare i documenti selezionati?',
  [T.nestedFolderDelete]:
    'Questa azione eliminerà tutte le cartelle, sottocartelle e file selezionati. Vuoi continuare?',
  [T.multipleFilesReprocessConfirmation]:
    'Sei sicuro/a di voler riprocessare il documento selezionato?',
  [T.genericConfirmation]: 'Sei sicuro?',
  [T.retrialFailed]:
    'Impossibile caricare alcuni documenti, riprova più tardi.',
  [T.deleteDocuments]: 'Elimina Documenti',
  [T.cancelUploadConfirmation]:
    'Sei sicuro di voler annullare il caricamento dei file rimanenti?',
  [T.retryFailedDocuments]: 'Riprova Documenti Non Riusciti',
  [T.processingFailed]: 'Elaborazione Non Riuscita',
  [T.processLastTransaction]: 'Elabora Ultima Transazione',
  [T.processAllTransactions]: 'Elabora Tutte le Transazioni',
  [T.documentDownloadError]: 'Download del documento non riuscito.',
  [T.destinationFolder]: 'Seleziona Cartella di Destinazione',
  [T.move]: 'Sposta',
  [T.moveDuplicateError]: 'File/Cartella già esistente.',
  [T.moveSuccess]: 'File/Cartella spostato con successo.',
  [T.moveFailed]: 'Errore nello spostamento di File/Cartella.',
  [T.reprocessSuccess]:
    'File in coda per il riprocessamento completato con successo.',
  [T.reprocessFailed]: 'Errore durante il riprocessamento dei file.',
  [T.reprocessCountInfo]: 'Riprocessamento di {{count}} file.',
  [T.syncSuccess]: 'Risorsa in coda per la sincronizzazione con successo.',
  [T.syncFailed]: 'Errore durante la sincronizzazione della risorsa.',
  [T.nameCharacterLengthError]: 'Il nome deve contenere da 1 a 200 caratteri.',
  [T.name]: 'Nome',
  [T.resourceSharingSuccessMessage]: 'Risorsa condivisa con successo.',
  [T.resourceSharingErrorMessage]:
    'Errore durante la condivisione della risorsa.',
  [T.userOrUserGroup]: 'Utente o Gruppo Utenti',
  [T.searchForUserOrUserGroup]: 'Cerca Utente o Gruppo Utenti',
  [T.message]: 'Messaggio',
  [T.addMessage]: 'Aggiungi un messaggio',
  [T.sendEmailNotificationToRecipients]:
    'Invia notifica via email ai destinatari',
  [T.advanced]: 'Avanzate',
  [T.keepManuallyAssignedRolesOnSubFoldersFiles]:
    'Mantieni ruoli assegnati manualmente su sottocartelle/file',
  [T.assignRoleToAllSubFoldersFiles]:
    'Assegna ruolo a tutte le sottocartelle/file',
  [T.assignRoleOnFilesOnly]: 'Assegna ruolo solo ai file',
  [T.noUserOrUserGroupHasAccessToResource]:
    'Nessun utente o gruppo utenti ha accesso alla risorsa',
  [T.noMatchingUsersOrUserGroupsFound]:
    'Nessun utente o gruppo utenti corrispondente trovato',
  [T.controllerRoleAssignmentToRegularUserAtWorkspace]:
    'Il ruolo selezionato non può essere assegnato agli utenti regolari negli spazi di lavoro.',
  [T.regularUserAssignmentToWorkspace]:
    'Gli utenti normali non possono essere designati come controller dello spazio di lavoro. Promuovi il tipo di account dalla pagina degli utenti.',
  [T.contollerAssignmentError]:
    'Non autorizzato a invitare utenti come controller negli spazi di lavoro.',
  [T.authorizedToRemoveLowTierAccountTypesOnly]:
    'Autorizzato a rimuovere assegnazioni solo per account di livello inferiore.',
  [T.containsCollection]: 'Contiene: {{count}} collezioni',
  [T.containsItems]: 'Contiene: {{count}} elementi',
  [T.createPermissionDenied]: 'Permesso di creazione non trovato',
  [T.uploadPermissionDenied]: 'Permesso di caricamento non trovato',
  [T.createCollection]: 'Crea una raccolta',
  [T.collectionPlaceholder]:
    "Aggiungete i documenti all'interno di una raccolta per poter chattare con loro",
  [T.createFolder]: 'Crea una cartella',
  [T.folderPlaceholder]:
    'Aggiungi contenuti per costruire il tuo dominio di conoscenza',
  [T.assignViewerSubtitle]: 'Interroga e leggi il contenuto.',
  [T.assignControllerSubtitle]:
    "Interroga, modifica e gestisci l'accesso al contenuto.",
  [T.assignContributorSubtitle]: 'Interroga, leggi e aggiungi al contenuto.',
  [T.emptyWorkspace]: 'Nessuno Spazio di Lavoro disponibile',
  [T.emptyCollection]: 'Nessuna Collezione disponibile',
  [T.workspaces]: 'Spazi di lavoro',
  [T.collections]: 'Collezioni',
  [T.author]: 'Autore',
  [T.documentDetailsDisclaimer]: `I dettagli generati dall'IA potrebbero essere errati.`,
  // Chat
  [T.Chat]: 'Chat',
  [T.chat]: 'chat',
  [T.createNewChat]: 'Nuova Chat',
  [T.send]: 'Invia',
  [T.queryPlaceholder]: 'Chiedere',
  [T.queryPlaceholderWorldKnowledge]: `Chiedere. Premete '/' per accedere alle richieste utili.`,
  [T.chatGptSlogan]: `Per assistenza nella creazione di contenuti, nella codifica, nella ricerca e nell'accesso alla conoscenza del mondo.`,
  [T.empowerGptSlogan]:
    'Per risposte alla luce di una base di conoscenza accessibile.',
  [T.chatConfigurations]: 'Configurazione Chat',
  [T.parameterDialogTitle]: 'Parametri',
  [T.parameterDialogSubtitle]:
    'Aggiorna i parametri della chat per cercare i documenti in modo efficiente',
  [T.updateParameters]: 'Aggiorna Parametri',
  [T.cancel]: 'Annulla',
  [T.submit]: 'Invia',
  [T.save]: 'Salva',
  [T.confirm]: 'Conferma',
  [T.parametersUpdateSuccess]: 'Parametri della chat aggiornati con successo!',
  [T.parametersUpdateError]:
    "Errore nell'aggiornamento dei parametri della chat!",
  [T.temperature]: 'Temperatura',
  [T.tokens]: 'Lunghezza massima (token)',
  [T.sequences]: 'Sequenze di stop',
  [T.probabilities]: 'Probabilità migliori',
  [T.frequency]: 'Penalità frequenza',
  [T.presence]: 'Penalità presenza',
  [T.chatRenameSuccess]: 'Chat rinominata con successo!',
  [T.chatRenameFailed]: 'Errore nel rinominare la chat!',
  [T.chatDeleteSuccess]: 'Chat eliminata con successo!',
  [T.chatDeleteFailed]: "Errore nell'eliminare la chat!",
  [T.generatingResponse]: 'Generazione risposta',
  [T.errorGeneratingResponse]:
    'Impossibile generare una risposta! Riprova per favore.',
  [T.messagesLoadingFailed]:
    'Errore nel recupero dei messaggi. Per favore, riprova.',
  [T.responseFailedMessage]:
    'Si è verificato un errore nella generazione di una risposta.',
  [T.responseStreamingFailedMessage]:
    'Si è verificato un errore nella trasmissione della risposta. Per favore, aggiorna la pagina.',
  [T.regenerateResponse]: 'Rigenera Risposta',
  [T.errorAddingReaction]: "Errore nell'aggiungere una reazione.",
  [T.errorUpdatingChatContext]:
    "Errore nell'aggiornare il contesto della chat.",
  [T.chatDeleteConfirmation]: 'Sei sicuro di voler eliminare la chat?',
  [T.samplePrompts]: 'Esempi di Promemoria',
  [T.capabilities]: 'Capacità',
  [T.limitations]: 'Limitazioni',
  [T.samplePrompt1]:
    'Quali verifiche devo effettuare prima di installare la pompa Etaprime L?',
  [T.samplePrompt2]:
    "Qual è l'intervallo ammissibile di viscosità del petrolio greggio per la centrale elettrica L27/38S?",
  [T.samplePrompt4]:
    'Linee guida per la creazione di una tabella pivot in Microsoft Excel.',
  [T.samplePrompt5]:
    'Consigli per la collaborazione e la comunicazione del team remoto.',
  [T.samplePrompt7]:
    'Suggerire strategie di ottimizzazione della catena di approvvigionamento.',
  [T.samplePrompt8]:
    "Best practice per l'identificazione dei rischi aziendali.",
  [T.capabilities1]:
    'Ottieni risposte alle tue domande basate esclusivamente sui documenti caricati.',
  [T.capabilities2]:
    "Ricorda il contesto delle domande precedenti all'interno di una chat.",
  [T.limitations1]:
    'Potrebbe non riuscire a confrontare attributi distribuiti in tutto il database.',
  [T.limitations2]:
    'La conoscenza è limitata allo scopo dei documenti caricati.',
  [T.includeChatHistory]:
    "Clicca per ricollegarti al contesto dell'ultima domanda.",
  [T.excludeChatHisory]: 'Clicca per scollegarti dal contesto della chat',
  [T.stickToDocs]: 'Limita la ricerca ai documenti aperti nel visualizzatore.',
  [T.resourceUnavailable]: 'Risorsa non disponibile',
  [T.referenceDocumentDeleted]: 'Documento di riferimento eliminato',
  [T.disclaimerChatgpt]: `L'AI Model può commettere errori, si prega di verificare le informazioni importanti.`,
  [T.copy]: 'Copia',
  [T.copied]: 'Copiato',
  [T.errorCopyingResponse]: 'Errore durante la copia della risposta',
  [T.stickDocMessage]:
    'Limita la ricerca ai documenti aperti nel visualizzatore. (Nessun documento aperto)',
  [T.contentFilteringErrorMsg]: `La risposta è stata filtrata a causa del prompt che ha attivato il criterio di gestione dei contenuti di Azure. Modificare la richiesta e riprovare`,
  [T.generalErrorMsg]: `Il modello AI non è stato in grado di generare una risposta. Riprovare`,
  [T.shareChatTitle]: `Condividi questa chat`,
  [T.shareChatText]: `Utenti di EmpowerGPT con il link`,
  [T.shareChatDisclaimer]:
    'Tutti i messaggi aggiunti dopo la condivisione rimarranno privati',
  [T.copyLink]: 'Copiare il link',
  [T.errorCopyingLink]: 'Errore nella copia del link',
  [T.sharedBy]: `Condiviso da: `,
  [T.chatInitiationError]: `Impossibile avviare una richiesta. Si prega di verificare se la configurazione del modello è corretta.`,
  [T.unsupportedLanguage]: `La lingua di input rilevata come {{detectedLanguage}} non è attualmente supportata nella chat di Enterprise Knowledge. Si prega di passare a una lingua supportata, che attualmente include inglese, tedesco e italiano.`,
  [T.rateLimit]:
    'La vostra organizzazione ha raggiunto il limite di richieste al minuto. Riprovare più tardi.',
  [T.webSearchDescription]: 'Cerca sul web per rispondere',
  [T.stickToDoc]: 'Attenersi ai documenti',
  [T.webSearch]: 'Ricerca web',
  [T.noAuthorizedFilesForChat]:
    'Spiacente, non ci sono documenti contro la risorsa selezionata disponibili per la chat. Assicurati che non sia vuota e contenga documenti elaborati a cui hai accesso.',

  // Chat Steps
  [T.understandingInput]: `Comprendendo l'input`,
  [T.searchingContent]: `Cercando negli spazi di lavoro`,
  [T.searchingWeb]: `Cercando sul web`,
  [T.link]: `collegamento`,
  [T.generatingResponseUsing]: `Generando risposta utilizzando`,
  [T.excerptsFrom]: `estratti da`,
  [T.contentSearched]: 'Conoscenza aziendale ricercata',
  [T.webSearched]: 'Web ricercato',
  // Chat feedback
  [T.feedbackTitle]: 'Fornisci ulteriori feedback',
  [T.feedback1]: 'La risposta cita il documento sbagliato.',
  [T.feedback2]: 'La risposta esce dal campo dei documenti.',
  [T.feedback3]: 'La risposta è dannosa/insicura.',
  [T.feedback4]: 'La risposta non è utile.',
  [T.submitFeedback]: 'Invia feedback',
  [T.sources]: 'Sorgenti',
  [T.feedbackCommentPlaceholder]:
    'Cosa è andato storto con la risposta? Come può essere migliorata?',
  [T.feedbackValidationError]: 'Si prega di selezionare almeno 1 opzione.',

  // User/Roles/Permissions
  [T.rolesTitle]: 'Ruoli',
  [T.role]: 'Ruolo',
  [T.permissions]: 'Autorizzazioni',
  [T.users]: 'Utenti',
  [T.create]: 'Crea',
  [T.update]: 'Aggiorna',
  [T.add]: 'Aggiungi',
  [T.new]: 'Nuovo',
  [T.edit]: 'Modifica',
  [T.delete]: 'Elimina',
  [T.tag]: 'Tag',
  [T.upload]: 'Carica',
  [T.createRole]: 'Crea Ruolo',
  [T.updateRole]: 'Aggiorna Ruolo',
  [T.createUser]: 'Crea Utente',
  [T.updateUser]: 'Aggiorna Utente',
  [T.searchRole]: 'Cerca Ruolo Utente',
  [T.availableRole]: 'Ruolo Utente Disponibile',
  [T.assignedRole]: 'Ruolo Utente Assegnato',
  [T.confirmDeleteRole]: 'Sei sicuro di voler eliminare questo ruolo?',
  [T.confirmDeleteUser]: 'Sei sicuro di voler eliminare questo utente?',
  [T.confirmDeleteMultipleUsers]:
    'Sei sicuro di voler eliminare questi utenti?',
  [T.roleDeleteSuccess]: 'Ruolo eliminato con successo!',
  [T.deleteRow]: 'Elimina riga',
  [T.randomError]: 'Si è verificato un errore!',
  [T.errorDeletingRole]: "Errore durante l'eliminazione del ruolo.",
  [T.noRecordFound]: 'Nessun record trovato!',
  [T.roleUpdateSuccess]: 'Ruolo Aggiornato con Successo!',
  [T.roleUpdateError]: "Errore nell'aggiornamento del ruolo!",
  [T.roleCreateSuccess]: 'Ruolo Creato con Successo!',
  [T.roleCreateError]: 'Errore nella creazione del ruolo!',
  [T.userInviteSuccess]: 'Invito inviato via email.',
  [T.userInviteError]: "Errore durante l'invio dell'invito.",
  [T.userUpdateSuccess]: 'Utente aggiornato con successo!',
  [T.userUpdateFailed]: "Errore durante l'aggiornamento dell'utente.",
  [T.userDeleteSuccess]: 'Utente eliminato con successo!',
  [T.usersDeleteSuccess]: 'Utenti eliminati con successo!',
  [T.userDeleteError]: "Impossibile eliminare l'utente, per favore riprova!",
  [T.duplicateUserError]: "L'indirizzo email è già in uso",
  [T.duplicateFolderError]: 'File/Cartella già esistente.',
  [T.duplicateCollectionError]: 'La collezione esiste già.',
  [T.duplicateWorkspaceError]: 'Lo spazio di lavoro esiste già.',
  [T.renameSuccess]: 'Rinominato con successo!',
  [T.renameFailed]: 'Errore durante il rinominare!',
  [T.rename]: 'Rinomina',
  [T.enterRoleName]: 'Inserisci il Nome del Ruolo',
  [T.fullName]: 'Nome Completo',
  [T.enterFullName]: 'Inserisci il Nome Completo',
  [T.enterEmailAddress]: "Inserisci l'Indirizzo Email",
  [T.enterRoleDescription]: 'Inserisci la Descrizione del Ruolo',
  [T.roleName]: 'Nome del Ruolo',
  [T.invalidRoleName]: 'Nome del Ruolo non valido',
  [T.userName]: 'Nome Utente',
  [T.enterUserName]: 'Inserisci il Nome Utente',
  [T.moveSelectedRight]: 'Sposta selezionati a destra',
  [T.moveSelectedLeft]: 'Sposta selezionati a sinistra',
  [T.applyTags]: 'Applica Tag',
  [T.language]: 'Lingua',
  [T.dataLibrary]: 'Libreria Dati',
  [T.documentsStatus]: 'Stato Documenti Caricati',
  [T.statusFailedText]: 'Stato: Non riuscito',
  [T.statusUploadedText]: 'Stato: Caricato',
  [T.startDate]: 'Data di Inizio',
  [T.endDate]: 'Data di Fine',
  [T.selectStatus]: 'Seleziona Stato',
  [T.pages]: 'pagine',
  [T.addARole]: 'Aggiungi un Ruolo',
  [T.editRole]: 'Modifica Ruolo',
  [T.organizationAdminSubtitle]:
    'Può creare e assegnare spazi di lavoro, invitare amministratori/utenti, aggiungere ruoli personalizzati.',
  [T.workspaceAdminSubtitle]:
    'Può gestire interi spazi di lavoro, gruppi di utenti e aggiungere utenti regolari.',
  [T.regularUserSubtitle]:
    'Può chattare su documenti accessibili, caricare e condividere contenuti con altri utenti.',
  [T.licenseLimitReached]: 'Limite di licenza raggiunto',

  // workspace
  [T.workspaceName]: 'Nome Spazio di Lavoro',
  [T.collectionName]: 'Nome Raccolta',
  [T.workspaceColor]: 'Colore dello Spazio di Lavoro',
  [T.workspaceController]: 'Controller dello Spazio di Lavoro',
  [T.createWorkspace]: 'Crea Spazio di Lavoro',
  [T.updateWorkspace]: 'Aggiorna Spazio di Lavoro',
  [T.newWorkspace]: 'Nuovo spazio di lavoro',
  [T.newCollection]: 'Nuova collezione',
  [T.newFolder]: 'Nuova cartella',
  [T.documentUpdateConfirmation]:
    'Rinomina lo spazio di lavoro e aggiorna il colore di sfondo',
  [T.backgroundColor]: 'Colore di Sfondo',
  [T.updatesOn]: 'Aggiornamenti',
  [T.updatedBy]: 'Aggiornato da',
  [T.workspaceUpdateSuccess]: 'Spazio di Lavoro aggiornato con successo!',
  [T.workspaceUpdateFailed]:
    "Errore nell'aggiornamento dello Spazio di Lavoro!",
  [T.sortBy]: 'Ordina Per',
  [T.lastUpdated]: 'Ultimo Aggiornamento',
  [T.ago]: 'fa',
  [T.day]: 'giorno',
  [T.days]: 'giorni',
  [T.hour]: 'ora',
  [T.hours]: 'ore',
  [T.min]: 'minuto',
  [T.mins]: 'minuti',
  [T.sec]: 'secondo',
  [T.secs]: 'secondi',
  [T.workSpaceDocumentsDropError]:
    'Aggiungi una raccolta per caricare i documenti al suo interno.',
  [T.createAWorkspace]: 'Crea uno Spazio di Lavoro',
  [T.newWorkspaceDescription]:
    'Crea una base di conoscenza separata per ogni dipartimento',
  [T.createACollection]: 'Crea una Raccolta',
  [T.publicDisclaimer]:
    'I contenuti in Pubblico sono condivisi con tutti gli utenti.',
  [T.privateDisclaimer]: `Il contenuto dell'area di lavoro personale è accessibile solo all'utente.`,
  [T.moreActions]: 'Altre azioni',

  //no_data_messages
  [T.noDataNameSpaceMessage]:
    'Crea uno spazio di lavoro per iniziare a caricare dati al suo interno.',
  [T.noDataWorkspaceMessage]:
    'Carica una cartella o crea una raccolta per caricare dati al suo interno.',
  [T.noDataCollectionMessage]: 'Carica file/cartelle',
  [T.noDataNoPermissionMessage]:
    "Richiedi all'amministratore l'accesso ai documenti.",
  [T.noData]: 'Nessun Dato',
  [T.workSpaceInfoMessage]:
    "I nuovi spazi di lavoro possono essere creati solo dagli amministratori dell'organizzazione.",
  [T.moveDialogInfo]:
    'Il contenuto spostato manterrà le autorizzazioni originali della sorgente.',

  // File statuses
  [T.F_S_UP]: 'In corso',
  [T.F_S_RE]: 'Riprova',
  [T.F_S_PR]: 'Elaborato',
  [T.F_S_US]: 'Non supportato',
  [T.F_S_LLR]: 'Limite di licenza raggiunto',
  [T.F_S_WF]: 'In attesa del file',

  // User Groups
  [T.userGroupDeletedSuccessfully]: 'Gruppo Utenti Eliminato con Successo.',
  [T.userGroupsDeletedSuccessfully]: 'Gruppi Utenti Eliminati con Successo.',
  [T.userGroupCreatedSuccessfully]: 'Gruppo Utenti Creato con Successo.',
  [T.failedToCreateUserGroup]: 'Impossibile Creare il Gruppo Utenti.',
  [T.failedToDeleteUserGroup]: 'Impossibile Eliminare il Gruppo Utenti.',
  [T.failedToDeleteUserGroups]: 'Impossibile Eliminare i Gruppi Utenti.',
  [T.userGroupUpdatedSuccessfully]: 'Gruppo Utenti Aggiornato con Successo.',
  [T.failedToUpdateUserGroup]: 'Impossibile Aggiornare il Gruppo Utenti.',
  [T.addUserGroup]: 'Aggiungi un Gruppo Utenti',
  [T.editUserGroup]: 'Modifica Gruppo Utenti',
  [T.searchUsers]: 'Cerca Utenti',
  [T.createdBy]: 'Creato da',
  [T.userGroupDeleteConfirmationMsg]:
    'Sei sicuro di voler eliminare il/i Gruppo/i Utenti?',

  // Validations
  [T.maxLength]: '{{name}} non può superare {{length}} caratteri',
  [T.required]: '{{name}} è obbligatorio',
  [T.tagHelperText]:
    "Premi il tasto 'Invio' per creare un nuovo tag o selezionarne uno dalla lista.",

  // Usage
  [T.processedDocuments]: 'Documenti elaborati',
  [T.overallUsage]: 'Utilizzo complessivo',
  [T.aiUsage]: "Utilizzo dell'IA",
  [T.monthlyUsage]: 'Utilizzo mensile',
  [T.dailyUsage]: 'Utilizzo giornaliero',
  [T.processedDocumentsInfo]:
    'Numero stimato di parole rispetto ai documenti elaborati memorizzati nella spazi di lavoro.',
  [T.tokenUsageInfo]:
    '{{consumed}} {{unit}} di documenti elaborati memorizzati nella spazi di lavoro su un limite totale di {{total}} {{unit}}',
  [T.aiUsageInfo]: "Utilizzo dell'IA per chat e elaborazione documenti",
  [T.creditUsageInfo]:
    '{{consumed}} {{unit}} utilizzati su un limite totale di {{total}} {{unit}}',
  [T.credits]: 'Crediti',
  [T.consumed]: 'Consumato',
  [T.date]: 'data',
  [T.noCreditsUsedThisMonth]: 'Nessun credito utilizzato questo mese',

  // Error Messages
  [T.namespaceError]: 'Si è verificato un errore di spazio dei nomi.',
  [T.resourceNotFoundError]: 'Risorsa non trovata.',
  [T.noPermissionsAgainstResourceError]: 'Nessun permesso sulla risorsa.',
  [T.insufficientPrivileges]: "Permesso per eseguire l'azione non trovato.",
  [T.appRoleTemplateNotFoundError]: "Modello di ruolo dell'app non trovato.",
  [T.uploadTypeError]: 'Tipo di caricamento non valido fornito.',
  [T.transactionIdError]: 'Fornito un ID transazione non valido.',
  [T.uploadToRootError]: 'Errore durante il caricamento nella radice.',
  [T.uploadToFoundError]:
    "Non sei autorizzato a caricare file e cartelle nella cartella 'FOUND'.",
  [T.uploadToFileError]:
    'I file possono essere caricati solo in cartelle valide.',
  [T.fileUploadToWorkspaceError]:
    'I file non possono essere caricati a livello di spazio di lavoro.',
  [T.noFilesAttachedError]:
    'Seleziona file validi per iniziare il loro caricamento.',
  [T.fileStorageWriteError]: 'Errore di scrittura dello storage file.',
  [T.fileStorageReadError]: 'Errore di lettura dello storage file.',
  [T.requestedResourceNotFile]: 'La risorsa richiesta non è un file.',
  [T.fileStorageDeleteError]: 'Errore di eliminazione dello storage file.',
  [T.publicFolderDeleteError]: 'Impossibile eliminare la cartella pubblica.',
  [T.internalServerError]: 'Qualcosa è andato storto.',
  [T.invalidArgsError]: 'Argomenti non validi forniti.',
  [T.folderNotAllowedHere]: 'Non sei autorizzato a creare una cartella qui.',
  [T.filesPermission]: 'I file non sono consentiti qui.',
  [T.rolesInsufficientViewPrivileges]:
    'Non hai il diritto di visualizzare i ruoli.',
  [T.userGroupsInsufficientPrivileges]:
    'Non hai il diritto di visualizzare i gruppi utente.',
  [T.waCreateUserRestriction]:
    "L'amministratore dello spazio di lavoro può creare solo utenti regolari.",
  [T.readOnlyResourceUpdate]:
    'Non sei autorizzato a aggiornare una risorsa in sola lettura.',
  [T.readOnlyResourceDelete]:
    'Non sei autorizzato a eliminare una risorsa in sola lettura.',
  [T.rolesUniqueVoilation]: 'Ruolo con lo stesso nome già esistente.',
  [T.addPermissionsAgainstResourceError]:
    'Impossibile aggiungere permessi sulla risorsa.',
  [T.permissionsUpdatingErrorForRole]:
    'Impossibile aggiornare i permessi per la risorsa.',
  [T.insufficientCreatePrivileges]: 'Non hai i diritti per creare la risorsa.',
  [T.insufficientUpdatePrivileges]:
    'Non hai i diritti per aggiornare la risorsa.',
  [T.insufficientDeletePrivileges]:
    'Non hai i diritti per eliminare la risorsa.',
  [T.insufficientDeleteOneOrMorePrivileges]:
    'Non hai i diritti per eliminare una o più risorse.',
  [T.userGroupUniqueVoilation]:
    'Il gruppo utente con lo stesso nome già esiste.',
  [T.usersUniqueVoilation]: "L'utente esiste già.",
  [T.selfAssignmentOnResourceRestriction]:
    'Non puoi assegnarti a te stesso alla risorsa.',
  [T.ruAssignmentAsControllerRestriction]:
    'Gli utenti regolari non possono essere assegnati come controller.',
  [T.oaRemovalFromResourceDenied]:
    "All'amministratore dell'organizzazione non è consentito essere rimosso dalla risorsa.",
  [T.insufficientAssignmentPrivileges]:
    "Non hai abbastanza diritti per eseguire l'operazione.",
  [T.peerAccountAssignmentRestriction]:
    'Non ti è consentito rimuovere account dello stesso livello o di livello superiore.',
  [T.unauthorizedFilesError]: 'Non hai il permesso di caricare file/cartelle.',
  [T.messageOnEmptyFolderOrUnavailableFile]:
    'La chat non può essere avviata su risorse vuote o non disponibili.',
  [T.noSharedFilesExist]:
    "Si prega di caricare documenti nella spazi di lavoro o richiedere accesso all'amministratore per iniziare a utilizzare l'interrogazione.",
  [T.itemDetailsNotFoundError]: 'Dettagli per questa risorsa non trovati.',
  [T.operationNotPermitted]: "Permesso per eseguire l'azione non trovato.",
  [T.uniqueFolderNameError]: 'Cartella già esistente.',
  [T.noResourcesShared]:
    'Sono ammessi solo documenti autorizzati. Si prega di caricare prima un documento.',

  // over limit messages

  [T.chatCreditLimitExceededOA]: `Limite mensile per i crediti AI raggiunto. Per assistenza immediata, contatta l'assistenza clienti all'indirizzo "support@empowergpt.ai"`,
  [T.chatCreditLimitExceeded]: `Limite mensile per i crediti AI raggiunto. Contatta il tuo amministratore.`,
  [T.fileCreditLimitExceededOA]: `
  Elaborazione dei documenti interrotta, limite mensile per i crediti AI raggiunto. Per assistenza immediata, contatta l'assistenza clienti all'indirizzo "support@empowergpt.ai".`,
  [T.fileCreditLimitExceeded]: `
  Elaborazione dei documenti interrotta, limite mensile per i crediti AI raggiunto. Contatta il tuo amministratore.`,
  [T.fileWordLimitExceededOA]: `Elaborazione del documento interrotta, è stato raggiunto il limite di parole per i documenti elaborati archiviati. Elimina i documenti che non sono più necessari per liberare spazio. Per ulteriore assistenza, contattare l'assistenza clienti all'indirizzo "support@empowergpt.ai".`,
  [T.fileWordLimitExceeded]: `Elaborazione del documento interrotta, è stato raggiunto il limite di parole per i documenti elaborati archiviati. Elimina i documenti che non sono più necessari per liberare spazio.`,

  [T.creditLimitExceeded90Percent]:
    'Oltre il 90% dei crediti AI è stato consumato.',
  [T.wordLimitExceeded90Percent]:
    'È stato raggiunto oltre il 90% del limite di parole allocate rispetto ai documenti elaborati archiviati',
  [T.wordLimitReached100Percent]: `È stato raggiunto il limite di parole allocate per i documenti elaborati archiviati. Elimina i documenti che non sono più necessari per liberare spazio. Per ulteriore assistenza, contattare l'assistenza clienti all'indirizzo "support@empowergpt.ai"`,
  [T.fileUploadLimitError]:
    'Hai raggiunto il limite di 100 documenti del piano di prova.',
  // users invitations
  [T.inviteUsers]: 'Invita Utenti',
  [T.emailAddress]: 'Indirizzo Email',
  [T.userGroup]: 'Gruppo Utenti',
  [T.active]: 'Attivo',
  [T.invited]: 'Invitato',
  [T.invitationExpired]: 'Invito Scaduto',
  [T.inactive]: 'Inattivo',
  [T.resendInvite]: "Invia nuovamente l'invito",
  [T.deActivate]: 'Disattiva',
  [T.active]: 'Attiva',
  [T.inviteSentSuccessfully]: 'Invito inviato con successo!',
  [T.inviteSentFailed]: "Errore nell'invio dell'invito!",
  [T.userActivated]: 'Utente attivato con successo!',
  [T.userDeactivated]: 'Utente disattivato con successo!',
  [T.userActivationFailed]: "Errore nell'attivazione dell'utente!",
  [T.userDeactivationFailed]: "Errore nella disattivazione dell'utente!",
  [T.adUserNotFoundError]: 'Utente non trovato in AD.',
  [T.invalidEmail]: 'Inserisci un indirizzo email valido',

  // SharePoint
  [T.sharePointAuthError]:
    'Potresti non essere in grado di interagire con SharePoint a causa di un problema di connettività.',
  [T.sharepoint]: 'Importa da SharePoint',
  [T.sharepointModalTitle]: 'Seleziona una Cartella / Libreria',
  [T.sharepointModalSubtitle]:
    "Passare al sito desiderato e selezionare la cartella / libreria di destinazione per l'importazione",
  [T.availableSites]: 'Siti disponibili',
  [T.selectSiteMsg]:
    'Seleziona un sito per visualizzare i contenuti disponibili del sito',
  [T.siteContent]: 'Contenuto del sito',
  [T.showSiteContent]: 'Mostra contenuto del sito',
  [T.select]: 'Selezionare',

  [T.resourceImportedSuccessfully]: 'Risorsa importata con successo.',
  [T.rootSite]: 'Siti principali',
  [T.sharePointSuccessModalTitle]: 'Cartella / Libreria collegata con successo',
  [T.sharePointSuccessModalLine1]:
    'Tutto a posto! La cartella / libreria selezionata insieme ai suoi contenuti sarà accessibile come una Collezione una volta completata la sincronizzazione dei dati.',
  [T.sharePointSuccessModalLine2]:
    'La visibilità della Collezione dipende esclusivamente dalle sue autorizzazioni in SharePoint.',
  [T.noSubSiteAvailable]: 'Nessuna Sotto-sito disponibile.',
  [T.noFolderFileAvailable]: 'Nessuna cartella / file disponibile.',
  [T.openInSharePoint]: 'Apri in SharePoint',
  [T.Navigate]: 'Navigare',
  [T.collectionSharepointPlaceholder]: 'Importa da SharePoint',
  [T.collectionSharepointDescription]:
    'Importa contenuti da Sharepoint a EmpowerGPT',

  // One Drive
  [T.oneDriveMyFiles]: 'I miei file',
  [T.oneDriveShared]: 'Condiviso con voi',
  [T.oneDrive]: 'Importa da OneDrive',
  [T.oneDriveModalTitle]: 'Seleziona una cartella/libreria',
  [T.oneDriveModalSubtitle]:
    "Naviga all'interno di OneDrive e seleziona il contenuto di destinazione per l'importazione",
  [T.oneDriveContent]: 'Contenuto',
  [T.oneDriveSuccessModalTitle]: 'Cartella/Libreria collegata correttamente',
  [T.oneDriveSuccessModalLine1]:
    'Tutto stabilito! La cartella/libreria selezionata insieme ai suoi contenuti saranno accessibili come raccolta una volta completata la sincronizzazione dei dati.',
  [T.oneDriveSuccessModalLine2]:
    'La visibilità della Collezione si basa esclusivamente sulle sue autorizzazioni su One Drive.',
  [T.openInOneDrive]: 'Apri in OneDrive',
  [T.contentImportFromOneDrive]: 'Importazione di contenuti da OneDrive',
  [T.oneDriveResourceProviderWelcomeTitle]:
    "Passaggi per l'importazione dalla configurazione di OneDrive",
  [T.oneDriveConfigurationSuccessTitle]: 'Importazione da OneDrive Configurata',
  [T.oneDriveConfigurationSuccessDescription1]:
    'I contenuti ora possono essere importati da OneDrive in EmpowerGPT come raccolte.',
  [T.oneDriveConfigurationSuccessDescription2]:
    'La visibilità del contenuto importato sarà basata sulle relative autorizzazioni su OneDrive.',
  [T.collectionOneDrivePlaceholder]: 'Importa da OneDrive',
  [T.collectionOneDriveDescription]:
    'Importa contenuti da OneDrive a EmpowerGPT',

  // Common External Resource provider
  [T.collectionExternalResProvPlaceholder]: 'Importa da SharePoint/OneDrive',
  [T.collectionExternalResProvDescription]:
    'Importa contenuti da Sharepoint o OneDrive a EmpowerGPT',
  [T.externalResourceDisclaimer]:
    "La visibilità del contenuto si basa esclusivamente sulle autorizzazioni alla fonte, tranne che all'interno di 'Pubblico' o 'Area di lavoro personale'.",

  // SSO configuration
  [T.redirectUri]: 'URI di reindirizzamento',
  [T.confirmationStep1]:
    "I passaggi sopra descritti sono stati eseguiti sull'applicazione registrata in Microsoft Entra ID.",
  [T.signInWithMicrosoft]: 'Accedi con Microsoft (SSO)',
  [T.allowInvitingExternalUsers]: "Consenti l'invito a utenti esterni",
  [T.cloudDriveIntegration]: 'Integrazione del Cloud Drive',
  [T.syncFrequency]: 'Frequenza di sincronizzazione (minuti)',
  [T.contentImportFromSharepoint]: 'Importa contenuto da SharePoint',
  [T.letsStart]: 'Iniziamo',
  [T.next]: 'Il prossimo',
  [T.done]: 'Fatto',
  [T.welcomeScreenHeading1]: 'Passaggi per SSO',
  [T.welcomeScreenHeading2]: 'Configurazione',
  [T.registrationStep]: 'Registra "EmpowerGPT" in Microsoft Entra ID',
  [T.azureDetailsStep]:
    "Inserisci i dettagli dell'applicazione registrata su Microsoft Entra ID",
  [T.configurePermissions]:
    "Dai le autorizzazioni richieste contro gli APIs utilizzati all'applicazione registrata in Microsoft Entra ID",
  [T.registrationPoint1]: "In un'altra scheda, accedi a",
  [T.azurePortal]: 'Azure Portal',
  [T.roleAzurePortal]: 'come amministratore.',
  [T.registrationPoint2]:
    'Vai a "Microsoft Entra ID" (Azure Active Directory) > "App registrations" come amministratore.',
  [T.registrationPoint3]: 'Fai clic su "+ New registration".',
  [T.registrationPoint4]:
    'Inserisci "EmpowerGPT" come nome dell\'applicazione e seleziona i tipi di account supportati richiesti. (Consigliato: "Accounts in this organizational directory only").',
  [T.registrationPoint5]:
    'Nella sezione "Redirect URI" sul modulo di registrazione dell\'applicazione, seleziona "Web" come tipo e incolla il seguente URI di reindirizzamento nel campo corrispondente.',
  [T.confirmationStep1]:
    "I passaggi sopra descritti sono stati eseguiti sull'applicazione registrata in Microsoft Entra ID.",
  [T.redirectUriRequired]: 'URI di reindirizzamento è obbligatorio',
  [T.azureDetailsPoint1]:
    'Una volta registrata l\'applicazione, verrai reindirizzato alla pagina "Overview" dell\'applicazione registrata.',
  [T.azureDetailsPoint2]:
    'Copia l\'ID applicazione (client) e l\'ID directory (tenant) dalla pagina "Overview" e inseriscili in ciascuno dei campi sottostanti.',
  [T.azureDetailsPoint3]:
    'Vai a "Certificates and secrets" nella sezione "Manage" sull\'applicazione registrata in Microsoft Entra ID.',
  [T.azureDetailsPoint4]:
    'Seleziona la scheda "Client secrets" e fai clic sul pulsante "New client secret" per creare un nuovo segreto client. Inserisci "EmpowerGPT client secret" nella descrizione, imposta la scadenza a 24 mesi (consigliato) e fai clic su "add".',
  [T.azureDetailsPoint5]:
    'Copia il valore segreto e incollalo nel campo sottostante. I valori segreti del client non possono essere visualizzati. tranne subito dopo la creazione. Assicurati di salvare il valore sicuro quando viene creato prima di lasciare la pagina.',
  [T.clientIdRequired]: 'Client Id è obbligatorio',
  [T.labelClientId]: 'ID applicazione (client): ',
  [T.tenantIdRequired]: 'Tenant Id è obbligatorio',
  [T.labelTenantId]: 'Directory (tenant) Id:',
  [T.secretValueRequired]: 'Il valore segreto è obbligatorio',
  [T.labelSecret]: 'Valore segreto del client:',
  [T.enter]: 'Inserisci...',
  [T.permissionsStep1]:
    'Vai a "API permissions" nella sezione "Manage" sull\'applicazione registrata in Microsoft Entra ID.',
  [T.permissionsStep2]:
    'Fai clic sul pulsante "+ Add a permission" e seleziona "Microsoft Graph" in Microsoft APIs.',
  [T.permissionsStep3]:
    'Seleziona "Application permissions" per dare le seguenti autorizzazioni e conferma facendo clic sul pulsante "add permission" qui sotto.',
  [T.permissionsStep4]: 'User.Read.All in User.',
  [T.permissionsStep5]: 'Group.Read.All in Group.',
  [T.permissionsStep6]: 'GroupMember.Read.All in GroupMember.',
  [T.permissionsStep7]:
    'Ripeti il passaggio 2 e seleziona "Delegated permissions" per dare l\'autorizzazione "profile". Conferma facendo clic sul pulsante "add permission" qui sotto.',
  [T.permissionsStep8]:
    "I passaggi sopra descritti sono stati eseguiti sull'applicazione registrata in Microsoft Entra ID.",
  [T.successDialogTitle]: 'SSO configurato con successo',
  [T.successDialogDescription]:
    "SS0 è stato configurato con successo contro gli utenti all'interno della directory collegata.",
  [T.clickHere]: 'Clicca qui',
  [T.toLogout]: ' per disconnettersi e testare la configurazione.',
  // Resource Provider config
  [T.resourceProviderWelcomeTitle]:
    "Passaggi per la configurazione dell'importazione da SharePoint",
  [T.resourceProviderTitle1]:
    'Carica il certificato generato nell\'applicazione registrata "EmpowerGPT" su Microsoft Entra ID',
  [T.resourceProviderStep1Instruction1]:
    "Incolla l'URL di base per il sito SharePoint della tua organizzazione di seguito. (L'URL di base per SharePoint Online viene fornito in base all'immissione durante la configurazione dell'abbonamento a Microsoft 365. Ad esempio, se il nome della tua organizzazione è Contoso, il sito principale di SharePoint Online sarà https://contoso.sharepoint.com.).",
  [T.resourceProviderStep1Instruction2]:
    'Fai clic sul pulsante sottostante per scaricare il certificato richiesto.',
  [T.resourceProviderStep1Instruction3]:
    "In un'altra scheda, accedi al portale di Azure come amministratore.",
  [T.resourceProviderStep1Instruction4]:
    'Vai a "Microsoft Entra ID" (Azure Active Directory) > "App registrations" > "All applications" > "EmpowerGPT".',
  [T.resourceProviderStep1Instruction5]:
    'Seleziona l\'applicazione e vai a "Certificates and secrets" nella sezione "Manage".',
  [T.resourceProviderStep1Instruction6]:
    'Seleziona la scheda "Certificates" e fai clic su "Upload certificate". Carica il certificato generato sopra, scaricato sul tuo computer.',
  [T.resourceProviderStep1Instruction7]:
    'Inserisci una descrizione appropriata e clicca sul pulsante "Aggiungi" per aggiungere il certificato.',
  [T.resourceProviderTitle2]:
    "Concedi le autorizzazioni richieste per le API utilizzate all'applicazione registrata in Microsoft Entra ID",
  [T.resourceProviderStep0Instruction0Start]: `In un'altra scheda, accedere a `,
  [T.resourceProviderStep0Instruction0End]: ` come amministratore`,
  [T.resourceProviderStep2Instruction1]:
    'Accedere a “Microsoft Entra ID” (Azure Active Directory) > “Registrazioni applicazioni” > “Tutte le applicazioni” > “EmpowerGPT” > “Autorizzazioni API”.',
  [T.resourceProviderStep2Instruction2]:
    'Clicca su "+ Add a permission" e seleziona "Microsoft Graph" sotto le Microsoft APIs.',
  [T.resourceProviderStep2Instruction3]:
    'Seleziona "Application permissions" per concedere le seguenti autorizzazioni e conferma cliccando su "add permission" in basso.',
  [T.resourceProviderStep2Instruction5]: 'Sites.Read.All.',
  [T.resourceProviderStep2Instruction6]: 'Files.Read.All.',
  [T.resourceProviderStep2Instruction7]:
    'Ripeti il passaggio 2 e seleziona "Delegated permissions" per concedere le seguenti autorizzazioni. Conferma facendo clic sul pulsante "add permission" qui sotto.',
  [T.resourceProviderStep2Instruction8]:
    'Fai clic sul pulsante "+ Add a permission" e seleziona "SharePoint" sotto Microsoft APIs per dare le seguenti "Application permission". Fai clic su "add permission" per confermare.',
  [T.sharepointConfigurationSuccessTitle]:
    'Importazione da SharePoint configurata',
  [T.sharepointConfigurationSuccessDescription1]:
    'Ora è possibile importare contenuti dai siti SharePoint in EmpowerGPT come collezioni.',
  [T.sharepointConfigurationSuccessDescription2]:
    'La visibilità dei contenuti importati sarà basata sulle autorizzazioni in SharePoint.',
  [T.downloadCertificate]: 'Scarica il certificato',
  [T.invalidSharePointUrl]: 'URL di SharePoint non valido fornito.',
  [T.identityProviderEnabledSuccessfully]:
    'Microsoft (SSO) è stato abilitato correttamente.',
  [T.resourceProviderSharePointEnabledSuccessfully]:
    'SharePoint abilitato correttamente.',
  [T.resourceProviderOneDriveEnabledSuccessfully]:
    'OneDrive abilitato correttamente.',
  [T.identityProviderDisabledSuccessfully]:
    'Microsoft (SSO) è stato disabilitato correttamente.',
  [T.resourceProviderSharePointDisabledSuccessfully]:
    'SharePoint disabilitato correttamente.',
  [T.resourceProviderOneDriveDisabledSuccessfully]:
    'OneDrive disabilitato correttamente.',
  [T.identityProviderUpdateFailed]: 'Impossibile aggiornare Microsoft (SSO).',
  [T.resourceProviderUpdateFailed]: 'Impossibile aggiornare SharePoint.',
  [T.identityProviderCreationFailed]: "Errore nell'abilitare Microsoft (SSO).",
  [T.resourceProviderSharePointCreationFailed]:
    "Errore nell'abilitare SharePoint.",
  [T.resourceProviderOneDriveCreationFailed]: "Errore nell'abilitare OneDrive.",
  [T.disabledToolTipMicrosoftSSO]: 'Abilita Microsoft SSO per configurare.',
  [T.requiredCheckbox]: 'Questo campo è obbligatorio',
  [T.adminConsent]:
    'Assicurati che sia stata fornita l\'autorizzazione dell\'amministratore per le autorizzazioni che la richiedono, indicate da un "Yes" nella colonna "Admin consent required" della tabella "Configured permissions".',

  //Internal Analytics
  [T.analytics]: 'analitica',
  [T.questions]: 'Domande',
  [T.dislikes]: 'Dispiaceri',

  // User Favourites
  [T.favourites]: 'Preferiti',
  [T.markFavourite]: 'Segna come preferito',
  [T.unmarkFavourite]: 'Deseleziona come preferito',
  [T.markedFavourite]: 'Contrassegnato come preferito',
  [T.unmarkedFavourite]: 'Non contrassegnato come preferito',
  [T.markFavouriteError]: 'Errore nel contrassegnare come preferito',
  [T.unmarkFavouriteError]: 'Errore durante la demarcazione come preferita',
  [T.favouriteLimitReached]: "Limite dei preferiti dell'utente raggiunto",

  //User Onboarding
  [T.welcomeText]: `Benvenuti a`,
  [T.welcomeText1]: `EmpowerGPT è una piattaforma di intelligenza artificiale creata per le aziende. Chatta in modo sicuro con LLM all'avanguardia che incorporano le vostre conoscenze aziendali e mondiali.`,
  [T.welcomeText2]: `Ampliate la base di conoscenze della vostra azienda caricando o importando dati da applicazioni integrate.`,
  //Chat
  [T.gotIt]: `Capito`,
  [T.onNext]: 'Fare clic su Avanti per continuare.',
  [T.chatStep1Title]: 'Chat: Selezionare il dominio di conoscenza richiesto.',
  [T.chatStep1Description]: `Scegliete come volete che EmpowerGPT risponda. Attenetevi alla conoscenza aziendale per ottenere risposte alla luce di documenti aziendali accessibili. Oppure passate alla conoscenza del mondo per ottenere assistenza nella creazione di contenuti, nella codifica, nella ricerca e nell'accesso alla conoscenza del mondo.`,
  [T.chatStep2Title]: 'Chat: Digitare la domanda.',
  [T.chatStep2Description]: `Utilizzate il campo di input sottostante per inserire le vostre domande e premete il pulsante di invio sulla destra. Il confronto con le domande e le risposte precedenti è limitato all'interno di una chat.`,
  [T.chatStep3Title]: 'Chat: Rivedere i fatti.',
  [T.chatStep3Description]: `Le risposte alle chat nella conoscenza aziendale hanno riferimenti citati alla fine. Si raccomanda di convalidare l'autenticità della risposta consultando i riferimenti.`,
  [T.chatQuery]: `Potete scrivere un'e-mail per congratularvi con il team per il raggiungimento degli obiettivi trimestrali?`,
  [T.today]: 'Oggi',
  [T.thisWeek]: 'Questa settimana',
  [T.thisMonth]: 'Questo mese e i più vecchi',
  [T.chatHistory]: 'Storia della chat',

  //Data Library
  [T.documentStep1Title]: 'Caricare: Navigare nella Spazi di lavoro.',
  [T.documentStep1Description]: `Data Library contiene spazi di lavoro che sono stati condivisi con l'utente. Gli spazi di lavoro classificano i documenti all'interno di un'organizzazione.`,
  [T.documentStep2Title]: `Caricare: Selezionare l'area di lavoro richiesta.`,
  [T.documentStep2Description]: `Le azioni disponibili su un'area di lavoro si basano sul ruolo che vi è stato assegnato. "Il mio spazio di lavoro" è uno spazio di lavoro personale, con il quale è possibile aggiungere documenti e chattare.`,
  [T.documentStep3Title]: `Caricare: Creare una raccolta.`,
  [T.documentStep3Description]: `Le raccolte classificano ulteriormente i documenti all'interno di ogni area di lavoro. Create la vostra raccolta all'interno dell'area di lavoro My per caricare i documenti al suo interno.`,
  [T.documentStep4Title]: `Caricare: Selezionare i documenti da caricare`,
  [T.documentStep4Description]: `EmpowerGPT supporta un'ampia varietà di formati di documenti che gli utenti possono aggiungere alla base di conoscenza, tra cui PDF (anche documenti scansionati), documenti Word, diapositive Powerpoint e file Excel.`,
  [T.documentStep5Title]: 'Caricamento: Capire lo stato del documento',
  [T.documentStep5Description]: `Una volta caricati, i documenti vengono messi in coda per l'elaborazione da parte dell'AI. Una volta contrassegnati come “Eseguiti”, gli utenti che hanno accesso a questi documenti possono chattare con loro.`,

  // Invite User
  [T.inviteUserStep1Title]: 'Invitare: Aprire il menu',
  [T.inviteUserStep1Description]: `I pulsanti del menu contengono i collegamenti alle pagine necessarie per la configurazione, la gestione dell'applicazione e dell'account.`,
  [T.inviteUserStep2Title]: 'Invito: Andare alla pagina degli utenti',
  [T.inviteUserStep2Description]: `La pagina Utenti contiene l'elenco di tutte le persone attualmente presenti sulla piattaforma empowerGPT. Gli amministratori possono invitare gli utenti da questa pagina.`,
  [T.inviteUserStep3Title]: `Invito: Digitare l'e-mail dell'invitato`,
  [T.inviteUserStep3Description]: `Digitare i messaggi di posta elettronica degli invitati, selezionare il tipo di account e gli eventuali gruppi di utenti prima di inviare l'invito.`,

  // Chat models comments and descriptions
  [T.mistralLargeComment]: 'Alternanza',
  [T.mistralLargeDescription]: `Ospitato su Microsoft Azure nell'UE`,
  [T.llama370BInstructComment]: 'Open Source',
  [T.llama370BInstructDescription]: `Ospitato su Microsoft Azure nell'UE`,
  [T.llama38BInstructComment]: 'Open Source',
  [T.llama38BInstructDescription]: `Ospitato su Microsoft Azure nell'UE`,
  [T.gpt4TurboComment]: 'Preciso',
  [T.gpt4TurboDescription]: `Ospitato su Microsoft Azure nell'UE`,
  [T.gpt432kComment]: 'Il meglio',
  [T.gpt432kDescription]: `Ospitato su Microsoft Azure nell'UE`,
  [T.gpt4oComment]: 'Veloce e preciso',
  [T.gpt4oDescription]: `Ospitato su Microsoft Azure nell'UE`,
  [T.cohereComment]: 'Impresa ottimizzata',
  [T.cohereDescription]: `Ospitato su Microsoft Azure nell'UE`,
  [T.gpt35Turbo16kComment]: 'Veloce',
  [T.gpt35Turbo16kDescription]: `Ospitato su Microsoft Azure nell'UE`,

  // Prompts
  [T.promptTitle1]: 'Accorciare i tempi',
  [T.promptText1]:
    'Riducete il seguente testo a [inserire_parole_contenute] parole, assicurandovi di mantenere tutte le informazioni importanti: [inserire_testo_qui].',
  [T.promptTitle2]: 'Fare più a lungo',
  [T.promptText2]:
    'Espandete il seguente testo fino a [inserire_parole_contenute] parole, assicurandovi che non vengano aggiunti dettagli irrilevanti: [inserire_testo_qui].',
  [T.promptTitle3]: 'Cambiare tono',
  [T.promptText3]:
    'Riscrivete il seguente testo con un tono [specificato_tono], assicurando che il messaggio rimanga chiaro ed efficace: [inserire_testo_qui].',
  [T.promptTitle4]: `Correggere la grammatica e l'ortografia`,
  [T.promptText4]: `Correggete la grammatica e l'ortografia del testo seguente, evidenziando tutte le modifiche e rendendo le parole corrette in grassetto: [inserire_testo_qui].`,
  [T.promptTitle5]: 'Tradurre',
  [T.promptText5]:
    'Tradurre il seguente testo in [lingua_specificata], assicurandosi che tutte le entità nominate, le abbreviazioni e gli acronimi siano accuratamente conservati: [inserire_testo_qui].',
  [T.promptTitle6]: 'Riassumere',
  [T.promptText6]:
    'Riassumete il seguente testo in [numero_di_parole] parole, assicurandovi che tutte le informazioni importanti siano mantenute e che il messaggio complessivo rimanga invariato: [inserire_testo_qui].',
  [T.promptTitle7]: 'Dare un feedback',
  [T.promptText7]:
    'Fornite un feedback sul seguente testo, concentrandovi sulle aree specificate: [tecnica, tono, grammatica, formato]. Assicuratevi che il vostro feedback sia costruttivo e contribuisca a migliorare il testo: [inserire_testo_qui].',
  [T.promptTitle8]: 'Brainstorming di idee',
  [T.promptText8]:
    'Fate un brainstorming di [numero_di_idee] idee creative e pratiche per il seguente compito: [compito+esempi_opzionali].',
  [T.promptTitle9]: `Scrivere un'e-mail`,
  [T.promptText9]: `Scrivete un'e-mail basata sulla trasmissione del seguente messaggio: [messaggio]. Assicuratevi che il tono sia [tono_specificato] e che la lunghezza dell'e-mail sia [lunghezza_specificata].`,
  [T.promptTitle10]: 'Scrivere un post sui social media',
  [T.promptText10]:
    'Scrivete un post sui social media per [piattaforma_specificata] mantenendo un tono [tono_specificato], rivolto a [pubblico_destinatario]. Includere contenuti coinvolgenti e adatti alla piattaforma.',

  // Mini Experts - Doc Analyst
  [T.miniExperts]: 'Mini Esperti',
  [T.docAnalyst]: 'Analista Documenti',
  [T.docAnalystDescription]: `Doc Analyst è un Mini Esperto di Gen AI che può analizzare i dati aziendali su domande su misura. Le principali funzionalità di Doc Analyst sono elencate di seguito:`,
  [T.docClassifierDescription]:
    'Il classificatore di documenti è un Mini Esperto di Gen AI progettato per categorizzare e etichettare automaticamente i documenti in base al loro contenuto.',
  [T.docSummarizerDescription]:
    'Il Generatore di Sommari condensa automaticamente documenti lunghi in riassunti concisi, catturando i punti più critici e le informazioni essenziali.',
  [T.analysis]: 'Analisi',
  [T.documentClassification]: 'Classificazione dei documenti',
  [T.comingSoon]: 'Prossimamente',
  [T.summaryGenerator]: 'Generatore di riepilogo',
  [T.confirmationDialog]: 'Finestra di dialogo di conferma',
  [T.dataCollections]: 'Raccolte Dati',
  [T.initiate]: 'Inizia',
  [T.newAnalysis]: 'Nuova analisi',
  [T.initiateAnalysis]: 'Inizia Analisi',
  [T.dataset]: 'Set di Dati',
  [T.questionnaire]: 'Questionario',
  [T.runAnalysis]: 'Esegui Analisi',
  [T.runAnalysisPlaceholder]: `Per avviare l'analisi, inserire il nome dell'analisi.\nSelezionare il set di dati e il questionario, quindi cliccare su ”`,
  [T.importFromApp]: "Importa dall'App",
  [T.regenerateAnalysis]: 'Rigenera Analisi',
  [T.saveAnalysis]: 'Salva Analisi',
  [T.numberOfFiles]: 'Numero di file',
  [T.numberOfQuestions]: 'Nr. domande',
  [T.clone]: 'Clone',
  [T.filesImportSuccess]: 'File/Cartella importati con successo',
  [T.filesImportFailure]: 'Importazione di file/cartelle non riuscita',
  [T.import]: 'Importa',
  [T.analysisTitle]: "Titolo dell'Analisi",
  [T.datasetTitle]: 'Titolo del Dataset',
  [T.analysisDeleteConfirmation]: "Sei sicuro di voler eliminare l'analisi?",
  [T.analysisDeleteSuccess]: 'Analisi eliminata con successo!',
  [T.analysisDeleteFailed]:
    "L'eliminazione dell'analisi non è riuscita. Riprovare più tardi!",
  [T.datasetDeleteConfirmation]: 'Sei sicuro di voler cancellare il dataset?',
  [T.datasetDeleteSuccess]: 'Il dataset è stato cancellato con successo!',
  [T.datasetDeleteFailed]: `L'eliminazione del set di dati non è riuscita. Riprovare più tardi!`,
  [T.questionnaireDeleteConfirmation]:
    'Sei sicuro di voler cancellare il questionario?',
  [T.questionnaireDeleteSuccess]: 'Questionario cancellato con successo!',
  [T.questionnaireDeleteFailed]:
    'Cancellazione del questionario non riuscita. Riprovare più tardi!',
  [T.addQuestionnaire]: 'Aggiungi un nuovo questionario',
  [T.addQuestion]: 'Aggiungi domanda',
  [T.questionnaireTitlePlaceholder]: 'Inserire il titolo del questionario',
  [T.questionnaireQuestionPlaceholder]: 'Inserire la domanda',
  [T.questionnaireCreationSuccess]: 'Questionario creato con successo.',
  [T.questionnaireCreationFailure]:
    'La creazione del questionario non è riuscita.',
  [T.questionnaireUpdateFailure]:
    'Aggiornamento del questionario non riuscito.',
  [T.datasetCorrupted]: 'File sorgente cancellato',
  [T.selectItemsToImport]: 'Seleziona elementi da importare',
  [T.importFilesDisclaimer]: 'Puoi selezionare 10 elementi',
  [T.analysisCancelSuccess]: 'Analisi annullata con successo!',
  [T.questionUpdatedSuccess]: 'Questionario aggiornato con successo',
  [T.maximumDatasetSize]: 'Massimo 10 file consentiti in un set di dati',
  [T.minimumDatasetSize]:
    'Per creare un set di dati è necessario almeno 1 file.',
  [T.questionnaireBlocked]: `Il questionario non può essere modificato a causa dell'associazione.`,
  [T.analysisFailed]: 'Documento Analisi fallita',
  [T.analysisResourceFailure]:
    'Errore risorsa di analisi del documento non trovata',
  [T.analysisLimit]: `Limite delle analisi in corso raggiunto! Si prega di attendere poiché è in corso un'altra analisi.`,
  [T.analysisEditingBlocked]: `Modifica dell'analisi bloccata. Attendere il completamento dell'analisi in corso.`,
  [T.closeAnalysisTitle]:
    'Annullamento della risposta/ Esecuzione in background',
  [T.cancelAnalysis]: 'Annulla analisi',
  [T.runInBackground]: 'Esegui in background',
  [T.inProgressAnalysis]: 'Analisi in corso',
  [T.inProgressAnalysisDescription]: `Vuoi annullare l'analisi o eseguire in background?`,
  [T.regenerateResponse]: 'Risposta rigenerante',
  [T.updateQuestionnaire]: 'Questionario di aggiornamento',
  [T.updateAssociatedQuestionnaireDescription]:
    'Il questionario con associazione precedente non può essere aggiornato.',
  [T.newQuestionnaireDescription]: 'Salva una nuova copia del questionario.',
  [T.saveNew]: 'Salva nuovo',
  [T.analysisQuestionnaireUpdateLimit]:
    'Il questionario associato ad altre analisi non può essere aggiornato.',
  [T.newAnalysisTooltip]: `Analisi è già in corso`,
  [T.analysisDetail]: `Dettaglio dell'analisi`,
  [T.selectDataset]: 'Selezionare il set di dati',
  [T.selectQuestionnaire]: 'Selezionare il questionario',
  [T.docAnalystDuplicationError]: `Esiste già un articolo con lo stesso titolo.`,
  [T.edited]: 'Modificato',
  [T.generating]: 'Generazione',
  [T.updateQuestionnairePending]: 'Questionario di aggiornamento in attesa',
  [T.updateQuestionnairePendingDescription]:
    'Aggiornare il questionario prima di uscire.',
  [T.dateCreated]: 'Data di creazione',
  [T.clearAll]: 'Cancella tutto',
  [T.questionnaireAssociationMsg]: `Non puoi modificare il questionario a causa della sua associazione con l'analisi.`,
  [T.questionnaireName]: 'Nome del questionario',
  [T.enterQuestionnaireName]: 'Inserisci il nome del questionario',
  [T.updateQuestionnaireDescription]:
    'Il questionario è aggiornato. Fare clic su "Aggiorna" per salvare le modifiche.',
  [T.updateQuestionnaireDialogTitle]: 'Salva le modifiche nel questionario',
  [T.createdOn]: 'Creato il',
  [T.datasetDeletedTooltip]:
    'Il dataset originale è stato cancellato / modificato dal proprietario.',
  [T.mainFeatures]: 'Caratteristiche principali',
  [T.docAnalystFeatures]: `Estrarre risposte precise dai documenti aziendali$$Creare questionari specifici per lo scopo$$Collegare dataset di conoscenza senza sforzo$$Eseguire analisi approfondite$$Modificare le domande e rigenerare l'analisi al volo$$Collaborazione attiva con i membri del team`,
  [T.docClassifierFeatures]: `Analizza i dati testuali da un'ampia gamma di documenti aziendali per identificare temi chiave, argomenti o categorie$$Classifica e ordina i documenti in categorie definite dall'utente senza intervento manuale$$Gestisce efficacemente grandi volumi di documenti, garantendo una classificazione coerente`,
  [T.docSummaryGenerator]:
    'Genera riassunti concisi da documenti lunghi$$Identifica e evidenzia le informazioni più importanti$$Consente agli utenti di regolare la lunghezza del riassunto in base alle loro esigenze$$Riassume più documenti simultaneamente per confronto o panoramica$$Semplifica la revisione del contenuto e migliora la comprensione$$Supporta la sintesi in più lingue, rispondendo a documenti diversi',
  [T.model]: 'Modello',
  [T.fromDate]: 'Data di Inizio',
  [T.sendQuestionnaire]: 'Invia questionario',
  [T.sentTo]: 'Inviato a',
  [T.questionnaireSendSuccess]: 'Questionario inviato con successo',
  [T.receivedFrom]: 'Ricevuto da',
  [T.questionnaireNotSharedYet]: 'Non ancora condiviso con nessuno.',
  [T.sentHistory]: 'Storia inviata',
  [T.byMe]: 'By Me',
  [T.all]: 'Tutti',
  [T.filesDeletedInDatasetDisclaimer]: 'Alcuni file sono stati eliminati',
  [T.allFilesDeletedInDatasetDisclaimer]:
    'Tutti i file del dataset sono stati cancellati',
  [T.fileName]: 'Nome file',
  [T.received]: 'Ricevuto',

  // Document Analysis statuses
  [T.L_DA_CM]: 'Completato',
  [T.L_DA_IP]: 'Inprogresso',
  [T.L_DA_CN]: 'Annullato',
  [T.L_DA_FL]: 'Fallito',
  [T.L_DA_PC]: 'Parzialmente completato',
  [T.L_DA_RGR]: 'Rigenerante',

  // Assistants
  [T.assistants]: 'Assistenti',
  [T.allAssistant]: 'Tutti',
  [T.createdByMe]: 'Creato da me',
  [T.creator]: 'Creatore',
  [T.createAssistant]: 'Crea Assistente',
  [T.updateAssistant]: 'Aggiorna Assistente',
  [T.assistantName]: "Nome dell'Assistente",
  [T.assistantDescription]: "Descrizione dell'Assistente",
  [T.assistantInstructions]: "Istruzioni dell'Assistente",
  [T.assistantModal]: 'Modalità Assistente',
  [T.assistantCreatedSuccessfully]: 'Assistente creato con successo',
  [T.assistantUpdatedSuccessfully]: 'Assistente aggiornato con successo',
  [T.assistantDeletedSuccessfully]: 'Assistente eliminato con successo',
  [T.iconUploadedSuccessfully]: 'Icona caricata con successo',
  [T.namePlaceholder]: 'Dai un nome al tuo assistente',
  [T.descriptionPlaceholder]:
    'Aggiungi una breve descrizione di questo assistente.',
  [T.instructions]: 'Istruzioni',
  [T.instructionsPlaceholder]:
    'Cosa fa questo assistente? Come dovrebbe comportarsi? Cosa dovrebbe evitare?',
  [T.conversationStarters]: 'Iniziatori di conversazioni',
  [T.conversationStartersErrorMessage]:
    'Gli iniziatori di conversazione non dovrebbero essere vuoti',
  [T.conversationStartersPlaceholder]:
    'Esempi di prompt per iniziare la conversazione.',
  [T.aiModels]: 'Modelli AI',
  [T.aiModelsPlaceholder]: 'Seleziona qualsiasi modello AI',
  [T.knowledgeAddFiles]: 'Conoscenza aziendale (Aggiungi file)',
  [T.addFiles]: 'Aggiungi file',
  [T.knowledgeAddFilesAdded]: '{{totalFiles}}/50 file aggiunti',
  [T.enterpriseKnowledge]: 'Conoscenza aziendale',
  [T.worldKnowledgeWithWebSearch]: 'Conoscenza mondiale con ricerca sul web',
  [T.conversationStartersCount]: '{{totalCon}}/6 aggiunti',
  [T.assistantPreviewNoFiles]: `Aggiungi file al database di conoscenza aziendale dell'assistente per avviare una chat.`,
  [T.preview]: 'Anteprima',
  [T.chooseEmojis]: `Selezionare l'immagine`,
  [T.keepItPrivate]: 'Mantienilo privato',
  [T.emojiDisclaimer]: "Per caricare un'emoji deve essere inferiore a 500 Kb",
  [T.assistantFilesPermission]: 'Non hai accesso ai seguenti file',
  [T.assistantNoFiles]: `I file di conoscenza aziendale dell'assistente sono stati eliminati`,
  [T.noFilesDisclaimer]:
    "I file aggiunti alla conoscenza dell'Assistente sono stati eliminati dagli spazi di lavoro. I controllori dell'Assistente possono aggiungere nuovi file o modificare le capacità dell'Assistente per avviare una nuova chat con lui.",
  [T.addFileDisclaimer]:
    'Assicurati che i file aggiunti nella conoscenza siano accessibili agli utenti con cui sono condivisi',
  [T.errorConfirmationDialog]: 'Risolvi i seguenti errori prima di procedere',
  [T.assistantDeletedDisclaimer]:
    'Non è possibile continuare a chattare con un assistente eliminato.',
  [T.outdatedVersionText]: `Non è possibile continuare a chattare con una versione obsoleta di questo assistente. Avvio `,
  [T.revokedVersionText]: `Accesso ai file di conoscenza dell'assistente revocato. Non puoi continuare a chattare. Clicca `,
  [T.revokedAssistantPermission]: `Accesso all'assistente revocato. Non puoi continuare a chattare. `,
  [T.newChat]: 'nuova chat',
  [T.here]: 'qui',
  [T.revokedAssistant]: ' per visualizzare i file.',
  [T.updatedAssistant]: ` con l'assistente aggiornato.`,
  [T.assistantUniqueViolationError]: `L'assistente con lo stesso nome esiste già`,
  [T.noAiModelDisclaimer]: `Il modello AI dell'assistente è stato disattivato dall'amministratore. I controllori dell'assistente possono assegnargli un nuovo modello o richiedere l'accesso dell'amministratore al modello disabilitato per avviare una nuova chat.`,
  [T.noAiModelTitle]: 'Modello AI configurato disabilitato',
  [T.stayOnThisPage]: `Rimani su questa pagina`,
  [T.aiModelErrorMessage]: `Seleziona un modello AI`,
  [T.createUpdateConfirmationDialogTitle]: `Modifiche non salvate`,
  [T.leaveWithoutSaving]: `Esci senza salvare`,
  [T.createUpdateConfirmationDisclaimer]: `Stai per lasciare questa pagina con modifiche non salvate.`,
  [T.addImage]: `Aggiungi immagine`,
  [T.changeImage]: `Cambia immagine`,
  [T.close]: 'Chiudere',
  [T.assistantDeleted]: 'Assistente Soppresso',
  [T.conversationStarterErrorMessage]: `L'Assistant Starter non può essere più lungo di 300 caratteri`,
  [T.viewMore]: 'Vedi altro',
  [T.viewLess]: 'Vedi meno',
  [T.assistantSelfAssignmentError]: `L'autoassegnazione/disassegnazione di una risorsa non è consentita!`
};

export default itJson;

import { getEncryptParams } from 'src/keycloak';
import {
  Builder,
  appendFormData,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { DataApiInputParams } from 'src/types/api';
import { getPayload } from 'src/utils/encryption';

type IGetUserGroupsParams = {
  params: {
    show_details?: boolean;
    user_id?: string;
    group_id?: number | string;
    created_by?: string;
    s?: string;
  };
};

export default (builder: Builder) => {
  return {
    getUserGroups: builder.query({
      async queryFn(
        arg: IGetUserGroupsParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const queryString = getQueryString(arg.params);
        let url = `keycloak/Group?${queryString.length ? queryString : ''}`;
        let decryptKey = getEncryptParams()?.k;

        if (IS_ENCRYPTION_ENABLED) {
          url = `${url}?${queryString}${
            queryString.length ? '&' : '?'
          }payload=${btoa(getPayload('', getEncryptParams()))}`;
        }

        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Groups']
    }),
    createUserGroup: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `keycloak/Group`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Groups']
    }),
    deleteUserGroup: builder.mutation({
      async queryFn(
        args: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let url = `keycloak/Group`;
        let formData = appendFormData(args.params);
        const result: any = await fetchWithBQ({
          url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Groups']
    }),
    updateUserGroups: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        const formData = appendFormData(arg.params);
        let url = `keycloak/Group`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'PUT',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Groups']
    })
  };
};

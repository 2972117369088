import { TRANSLATION_CONSTANTS as T } from './translations';
export enum ErrorCodes {
  NAMESPACE_ERROR = 'NAMESPACE_ERROR',
  RESOURCE_NOT_FOUND_ERROR = 'RESOURCE_NOT_FOUND_ERROR',
  NO_PERMISSIONS_AGAINST_RESOURCE_ERROR = 'NO_PERMISSIONS_AGAINST_RESOURCE_ERROR',
  INSUFFICIENT_PRIVILEGES = 'INSUFFICIENT_PRIVILEGES',
  APP_ROLE_TEMPLATE_NOT_FOUND_ERROR = 'APP_ROLE_TEMPLATE_NOT_FOUND_ERROR',
  UPLOAD_TYPE_ERROR = 'UPLOAD_TYPE_ERROR',
  TRANSACTION_ID_ERROR = 'TRANSACTION_ID_ERROR',
  UPLOAD_TO_ROOT_ERROR = 'UPLOAD_TO_ROOT_ERROR',
  UPLOAD_TO_FOUND_ERROR = 'UPLOAD_TO_FOUND_ERROR',
  UPLOAD_TO_FILE_ERROR = 'UPLOAD_TO_FILE_ERROR',
  FILE_UPLOAD_TO_WORKSPACE_ERROR = 'FILE_UPLOAD_TO_WORKSPACE_ERROR',
  FILE_UPLOAD_LIMIT_ERROR = 'FILE_UPLOAD_LIMIT_EXCEED_ERROR',
  NO_FILES_ATTACHED_ERROR = 'NO_FILES_ATTACHED_ERROR',
  FILE_STORAGE_WRITE_ERROR = 'FILE_STORAGE_WRITE_ERROR',
  FILE_STORAGE_READ_ERROR = 'FILE_STORAGE_READ_ERROR',
  REQUESTED_RESOURCE_NOT_FILE = 'REQUESTED_RESOURCE_NOT_FILE',
  FILE_STORAGE_DELETE_ERROR = 'FILE_STORAGE_DELETE_ERROR',
  PUBLIC_FOLDER_DELETE_ERROR = 'PUBLIC_FOLDER_DELETE_ERROR',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INVALID_ARGS = 'INVALID_ARGS',
  FOLDER_NOT_ALLOWED_HERE = 'FOLDER_NOT_ALLOWED_HERE',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
  READ_ONLY_RESOURCE_UPDATE = 'READ_ONLY_RESOURCE_UPDATE',
  READ_ONLY_RESOURCE_DELETE = 'READ_ONLY_RESOURCE_DELETE',
  ADD_PERMISSIONS_AGAINST_RESOURCE_ERROR = 'ADD_PERMISSIONS_AGAINST_RESOURCE_ERROR',
  PERMISSIONS_UPDATING_ERROR_FOR_ROLE = 'PERMISSIONS_UPDATING_ERROR_FOR_ROLE',
  INSUFFICIENT_VIEW_PRIVILEGES = 'INSUFFICIENT_VIEW_PRIVILEGES',
  INSUFFICIENT_CREATE_PRIVILEGES = 'INSUFFICIENT_CREATE_PRIVILEGES',
  INSUFFICIENT_UPDATE_PRIVILEGES = 'INSUFFICIENT_UPDATE_PRIVILEGES',
  INSUFFICIENT_DELETE_PRIVILEGES = 'INSUFFICIENT_DELETE_PRIVILEGES',
  INSUFFICIENT_DELETE_ONE_OR_MORE_PRIVILEGES = 'INSUFFICIENT_DELETE_ONE_OR_MORE_PRIVILEGES',
  WA_CREATE_USER_RESTRICTION = 'WA_CREATE_USER_RESTRICTION',
  INSUFFICIENT_ASSIGNMENT_PRIVILEGES = 'INSUFFICIENT_ASSIGNMENT_PRIVILEGES',
  OA_REMOVAL_FROM_RESOURCE_DENIED = 'OA_REMOVAL_FROM_RESOURCE_DENIED',
  PEER_ACCOUNT_ASSIGNMENT_RESTRICTION = 'PEER_ACCOUNT_ASSIGNMENT_RESTRICTION',
  RU_ASSIGNMENT_AS_CONTROLLER_RESTRICTION = 'RU_ASSIGNMENT_AS_CONTROLLER_RESTRICTION',
  SELF_ASSIGNMENT_ON_RESOURCE_RESTRICTION = 'SELF_ASSIGNMENT_ON_RESOURCE_RESTRICTION',
  MESSAGE_ON_EMPTY_FOLDER_OR_UNAVAILABLE_FILE = 'MESSAGE_ON_EMPTY_FOLDER_OR_UNAVAILABLE_FILE',
  NO_SHARED_FILES_EXIST = 'NO_SHARED_FILES_EXIST',
  AD_USER_NOT_FOUND = 'AD_USER_NOT_FOUND',
  OPERATION_NOT_PERMITTED = 'OPERATION_NOT_PERMITTED',
  ITEM_DETAILS_NOT_FOUND = 'ITEM_DETAILS_NOT_FOUND',
  CHAT_CREDIT_LIMIT_EXCEEDED = 'CHAT_CREDIT_LIMIT_EXCEEDED',
  FILE_CREDIT_LIMIT_EXCEEDED = 'FILE_CREDIT_LIMIT_EXCEEDED',
  FILE_WORD_LIMIT_EXCEEDED = 'FILE_WORD_LIMIT_EXCEEDED',
  CREDIT_LIMIT_EXCEEDED_90_PERCENT = 'CREDIT_LIMIT_EXCEEDED_MORE_THEN_90',
  CREDIT_LIMIT_REACHED_100_PERCENT = 'CREDIT_LIMIT_EXCEEDED_MORE_THEN_100',
  WORD_LIMIT_EXCEEDED_90_PERCENT = 'WORD_LIMIT_EXCEEDED_MORE_THEN_90',
  WORD_LIMIT_REACHED_100_PERCENT = 'WORD_LIMIT_EXCEEDED_MORE_THEN_100',
  FAVOURITE_ADD_ERROR = 'FAVOURITE_ADD_ERROR',
  FAVOURITE_REMOVE_ERROR = 'FAVOURITE_REMOVE_ERROR',
  USER_FAVOURITE_LIMIT_REACHED = 'USER_FAVOURITE_LIMIT_REACHED',
  NO_RESOURCES_SHARED = 'NO_RESOURCES_SHARED',
  CONTENT_FILTER = 'CONTENT_FILTER',
  GENERAL_AI_ERROR = 'GENERAL_ERROR',
  COULD_NOT_INITIATE_REQUEST = 'CHAT_INITIATION_ERROR',
  UNSUPPORTED_LANGUAGE = 'UNSUPPORTED_LANGUAGE',
  NO_AUTHORISED_FILES = 'NO_AUTHORISED_FILES',
  REFERENCE_DOCUMENT_DELETED = 'REFERENCE_DOCUMENT_DELETED',
  MAXIMUM_DATASET_FILES_LIMIT = 'MAXIMUM_DATASET_FILES_LIMIT',
  QUESTIONNAIRE_BLOCKED_DUE_TO_ASSOCIATION = 'QUESTIONNAIRE_BLOCKED_DUE_TO_ASSOCIATION',
  DOCUMENT_ANALYSIS_JOB_FAILED = 'DOCUMENT_ANALYSIS_JOB_FAILED',
  DOCUMENT_ANALYSIS_RESOURCE_NOT_FOUND = 'DOCUMENT_ANALYSIS_RESOURCE_NOT_FOUND',
  MAX_RUNNING_ANALYSIS_LIMIT = 'MAX_RUNNING_ANALYSIS_LIMIT',
  ANALYSIS_EDITING_BLOCKED = 'ANALYSIS_EDITING_BLOCKED',
  MINIMUM_DATASET_FILES_LIMIT = 'MINIMUM_DATASET_FILES_LIMIT',
  ANALYSIS_TO_QUESTIONNAIRE_UPDATE_LIMIT = 'ANALYSIS_TO_QUESTIONNAIRE_UPDATE_LIMIT',
  RATE_LIMIT = '429'
}

export enum ErrorContext {
  FILES = 'files',
  USERS = 'users',
  CHAT = 'chat',
  ROLES = 'roles',
  USER_GROUPS = 'userGroups',
  GENERAL = 'general',
  RESOURCE_PROVIDER = 'resourceProvider',
  OA = 'OA',
  FAVOURITES = 'favourites',
  AI = 'ai',
  DOC_ANALYST = 'docAnalyst',
  ASSISTANT = 'Assistant',
  ICONS = 'Icons'
}

export const ErrorMessages = {
  [ErrorContext.FILES]: {
    [ErrorCodes.NAMESPACE_ERROR]: T.namespaceError,
    [ErrorCodes.NO_PERMISSIONS_AGAINST_RESOURCE_ERROR]:
      T.noPermissionsAgainstResourceError,
    [ErrorCodes.INSUFFICIENT_PRIVILEGES]: T.insufficientPrivileges,
    [ErrorCodes.APP_ROLE_TEMPLATE_NOT_FOUND_ERROR]:
      T.appRoleTemplateNotFoundError,
    [ErrorCodes.UPLOAD_TYPE_ERROR]: T.uploadTypeError,
    [ErrorCodes.TRANSACTION_ID_ERROR]: T.transactionIdError,
    [ErrorCodes.UPLOAD_TO_ROOT_ERROR]: T.uploadToRootError,
    [ErrorCodes.UPLOAD_TO_FOUND_ERROR]: T.uploadToFoundError,
    [ErrorCodes.UPLOAD_TO_FILE_ERROR]: T.uploadToFileError,
    [ErrorCodes.FILE_UPLOAD_TO_WORKSPACE_ERROR]: T.fileUploadToWorkspaceError,
    [ErrorCodes.NO_FILES_ATTACHED_ERROR]: T.noFilesAttachedError,
    [ErrorCodes.FILE_STORAGE_WRITE_ERROR]: T.fileStorageWriteError,
    [ErrorCodes.FILE_STORAGE_READ_ERROR]: T.fileStorageReadError,
    [ErrorCodes.REQUESTED_RESOURCE_NOT_FILE]: T.requestedResourceNotFile,
    [ErrorCodes.FILE_STORAGE_DELETE_ERROR]: T.fileStorageDeleteError,
    [ErrorCodes.PUBLIC_FOLDER_DELETE_ERROR]: T.publicFolderDeleteError,
    [ErrorCodes.FOLDER_NOT_ALLOWED_HERE]: T.folderNotAllowedHere,
    [ErrorCodes.SELF_ASSIGNMENT_ON_RESOURCE_RESTRICTION]:
      T.selfAssignmentOnResourceRestriction,
    [ErrorCodes.RU_ASSIGNMENT_AS_CONTROLLER_RESTRICTION]:
      T.ruAssignmentAsControllerRestriction,
    [ErrorCodes.OA_REMOVAL_FROM_RESOURCE_DENIED]: T.oaRemovalFromResourceDenied,
    [ErrorCodes.INSUFFICIENT_ASSIGNMENT_PRIVILEGES]:
      T.insufficientAssignmentPrivileges,
    [ErrorCodes.PEER_ACCOUNT_ASSIGNMENT_RESTRICTION]:
      T.peerAccountAssignmentRestriction,
    [ErrorCodes.ITEM_DETAILS_NOT_FOUND]: T.itemDetailsNotFoundError,
    [ErrorCodes.UNIQUE_VIOLATION]: T.uniqueFolderNameError,
    [ErrorCodes.FILE_CREDIT_LIMIT_EXCEEDED]: T.fileCreditLimitExceeded,
    [ErrorCodes.FILE_WORD_LIMIT_EXCEEDED]: T.fileWordLimitExceeded,
    [ErrorCodes.REFERENCE_DOCUMENT_DELETED]: T.referenceDocumentDeleted,
    [ErrorContext.OA]: {
      [ErrorCodes.FILE_CREDIT_LIMIT_EXCEEDED]: T.fileCreditLimitExceededOA,
      [ErrorCodes.FILE_WORD_LIMIT_EXCEEDED]: T.fileWordLimitExceededOA
    },
    [ErrorCodes.FILE_UPLOAD_LIMIT_ERROR]: T.fileUploadLimitError
  },
  [ErrorContext.ROLES]: {
    [ErrorCodes.INSUFFICIENT_VIEW_PRIVILEGES]:
      T.rolesInsufficientViewPrivileges,
    [ErrorCodes.UNIQUE_VIOLATION]: T.rolesUniqueVoilation,
    [ErrorCodes.PERMISSIONS_UPDATING_ERROR_FOR_ROLE]:
      T.permissionsUpdatingErrorForRole
  },
  [ErrorContext.USER_GROUPS]: {
    [ErrorCodes.INSUFFICIENT_VIEW_PRIVILEGES]:
      T.userGroupsInsufficientPrivileges,
    [ErrorCodes.UNIQUE_VIOLATION]: T.userGroupUniqueVoilation
  },
  [ErrorContext.USERS]: {
    [ErrorCodes.WA_CREATE_USER_RESTRICTION]: T.waCreateUserRestriction,
    [ErrorCodes.UNIQUE_VIOLATION]: T.usersUniqueVoilation,
    [ErrorCodes.AD_USER_NOT_FOUND]: T.adUserNotFoundError
  },
  [ErrorContext.CHAT]: {
    [ErrorCodes.MESSAGE_ON_EMPTY_FOLDER_OR_UNAVAILABLE_FILE]:
      T.messageOnEmptyFolderOrUnavailableFile,
    [ErrorCodes.NO_SHARED_FILES_EXIST]: T.noSharedFilesExist,
    [ErrorCodes.CHAT_CREDIT_LIMIT_EXCEEDED]: T.chatCreditLimitExceeded,
    [ErrorContext.OA]: {
      [ErrorCodes.CHAT_CREDIT_LIMIT_EXCEEDED]: T.chatCreditLimitExceededOA
    },
    [ErrorCodes.NO_RESOURCES_SHARED]: T.noResourcesShared,
    [ErrorCodes.COULD_NOT_INITIATE_REQUEST]: T.chatInitiationError
  },
  [ErrorContext.GENERAL]: {
    [ErrorCodes.INTERNAL_SERVER_ERROR]: T.internalServerError,
    [ErrorCodes.RESOURCE_NOT_FOUND_ERROR]: T.resourceNotFoundError,
    [ErrorCodes.INVALID_ARGS]: T.invalidArgsError,
    [ErrorCodes.READ_ONLY_RESOURCE_DELETE]: T.readOnlyResourceDelete,
    [ErrorCodes.ADD_PERMISSIONS_AGAINST_RESOURCE_ERROR]:
      T.addPermissionsAgainstResourceError,
    [ErrorCodes.INSUFFICIENT_CREATE_PRIVILEGES]: T.insufficientCreatePrivileges,
    [ErrorCodes.INSUFFICIENT_UPDATE_PRIVILEGES]: T.insufficientUpdatePrivileges,
    [ErrorCodes.INSUFFICIENT_DELETE_PRIVILEGES]: T.insufficientDeletePrivileges,
    [ErrorCodes.INSUFFICIENT_DELETE_ONE_OR_MORE_PRIVILEGES]:
      T.insufficientDeleteOneOrMorePrivileges,
    [ErrorCodes.READ_ONLY_RESOURCE_UPDATE]: T.readOnlyResourceUpdate
  },
  [ErrorContext.RESOURCE_PROVIDER]: {
    [ErrorCodes.OPERATION_NOT_PERMITTED]: T.operationNotPermitted,
    [ErrorCodes.INSUFFICIENT_PRIVILEGES]: T.insufficientPrivileges
  },
  [ErrorContext.FAVOURITES]: {
    [ErrorCodes.FAVOURITE_ADD_ERROR]: T.markFavouriteError,
    [ErrorCodes.FAVOURITE_REMOVE_ERROR]: T.unmarkFavouriteError,
    [ErrorCodes.USER_FAVOURITE_LIMIT_REACHED]: T.favouriteLimitReached
  },
  [ErrorContext.AI]: {
    [ErrorCodes.CONTENT_FILTER]: T.contentFilteringErrorMsg,
    [ErrorCodes.GENERAL_AI_ERROR]: T.generalErrorMsg,
    [ErrorCodes.UNSUPPORTED_LANGUAGE]: T.unsupportedLanguage,
    [ErrorCodes.RATE_LIMIT]: T.rateLimit,
    [ErrorCodes.NO_AUTHORISED_FILES]: T.noAuthorizedFilesForChat
  },
  [ErrorContext.DOC_ANALYST]: {
    [ErrorCodes.MAXIMUM_DATASET_FILES_LIMIT]: T.maximumDatasetSize,
    [ErrorCodes.QUESTIONNAIRE_BLOCKED_DUE_TO_ASSOCIATION]:
      T.questionnaireBlocked,
    [ErrorCodes.DOCUMENT_ANALYSIS_JOB_FAILED]: T.analysisFailed,
    [ErrorCodes.DOCUMENT_ANALYSIS_RESOURCE_NOT_FOUND]:
      T.analysisResourceFailure,
    [ErrorCodes.MAX_RUNNING_ANALYSIS_LIMIT]: T.analysisLimit,
    [ErrorCodes.ANALYSIS_EDITING_BLOCKED]: T.analysisEditingBlocked,
    [ErrorCodes.MINIMUM_DATASET_FILES_LIMIT]: T.minimumDatasetSize,
    [ErrorCodes.ANALYSIS_TO_QUESTIONNAIRE_UPDATE_LIMIT]:
      T.analysisQuestionnaireUpdateLimit,
    [ErrorCodes.UNIQUE_VIOLATION]: T.docAnalystDuplicationError
  },
  [ErrorContext.ASSISTANT]: {
    [ErrorCodes.UNIQUE_VIOLATION]: T.assistantUniqueViolationError,
    [ErrorCodes.OPERATION_NOT_PERMITTED]: T.operationNotPermitted,
    [ErrorCodes.PEER_ACCOUNT_ASSIGNMENT_RESTRICTION]:
      T.peerAccountAssignmentRestriction,
    [ErrorCodes.SELF_ASSIGNMENT_ON_RESOURCE_RESTRICTION]:
      T.assistantSelfAssignmentError
  }
};

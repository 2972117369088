import { useCallback, useState } from 'react';
import { IconButton } from '@mui/material';
import { useDeleteChatMutation } from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { DeleteOutline } from '@mui/icons-material';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import useMutation from 'src/hooks/useMutation';
import { ErrorContext } from 'src/utils/errorMappings';

type DeleteChatProps = {
  onDelete: (id: number) => void;
  chat_id: number;
};

const DeleteChat = ({ onDelete, chat_id }: DeleteChatProps) => {
  const [isOpen, setOpen] = useState(false);
  const [deleteChat, { isLoading: isDeleting }] = useMutation({
    api: useDeleteChatMutation,
    errorContext: ErrorContext.CHAT
  });

  const onConfirmDelete = async () => {
    const apiParams: DataApiInputParams = {
      params: {
        id: chat_id
      }
    };
    await deleteChat({
      params: apiParams,
      fallbackMsg: T.chatDeleteFailed,
      successMsg: T.chatDeleteSuccess,
      successCallback: () => {
        onDelete(chat_id);
      }
    });
  };

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <IconButton onClick={onClick} size="small" disabled={isDeleting}>
        <DeleteOutline htmlColor="white" fontSize="small" />
      </IconButton>
      {isOpen && (
        <ConfirmationDialog
          message={T.chatDeleteConfirmation}
          onClose={handleClose}
          onConfirm={onConfirmDelete}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

export default DeleteChat;

import Authenticated from 'src/components/Authenticated';
import mainRoutes from './dashboards';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import Status404 from 'src/content/Status404';
import BaseLayout from 'src/layouts/BaseLayout';
import Signup from 'src/content/UserSignup';
import { element } from 'prop-types';
import { Children } from 'react';
import PublicRoute from 'src/components/PublicRoute';
import { isPublicRoute } from 'src/utils/utils';
import { IS_SIGNUP_ENABLED } from 'src/config';
import PrivacyPolicy from 'src/content/pages/PrivacyPolicy';

const router = (userAppPermissions) => {
  if (IS_SIGNUP_ENABLED && isPublicRoute()) {
    return [
      {
        path: '/signup',
        element: <PublicRoute element={<Signup />} />
      },
      {
        path: '/privacypolicy',
        element: <PrivacyPolicy />
      },
      {
        path: '*',
        element: <BaseLayout />,
        children: [
          {
            path: '*',
            element: <Status404 />
          }
        ]
      }
    ];
  }

  return [
    {
      path: '/',
      element: (
        <Authenticated>
          <AccentHeaderLayout />
        </Authenticated>
      ),
      children: [
        {
          path: '/',
          children: mainRoutes(userAppPermissions)
        }
      ]
    },
    {
      path: '*',
      element: <BaseLayout />,
      children: [
        {
          path: '*',
          element: <Status404 />
        }
      ]
    }
  ];
};

export default router;

import { Box, Typography } from '@mui/material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';

const SignupConfirmation = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography fontSize={'24px'} fontWeight={400} color={'primary'}>
        {t(T.signupConfirmation1)}
      </Typography>
      <Typography>{t(T.signupConfirmation2)}</Typography>
    </Box>
  );
};

export default SignupConfirmation;

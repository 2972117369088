import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { SnackbarProvider, useSnackbar, closeSnackbar } from 'notistack';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Button } from '@mui/material';

const NotiStackAlert = () => {
  const {
    msg,
    type,
    isOpen,
    persist = false,
    resetAlert,
    hideDuration
  } = useSelector((state) => state.snackbar);
  const { enqueueSnackbar } = useSnackbar();

  const action = (snackbarId) => (
    <>
      <Button
        sx={{
          color: 'white',
          position: 'fixed',
          display: 'inline-block',
          top: 10,
          right: 0,
          padding: 0,
          width: 0,
          height: 0
        }}
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <HighlightOffOutlinedIcon />
      </Button>
    </>
  );

  useEffect(() => {
    if (isOpen) {
      enqueueSnackbar(msg, {
        action: action,
        variant: type,
        autoHideDuration: persist ? null : hideDuration,
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
      });
    }
  }, [isOpen, msg, type, persist, enqueueSnackbar, resetAlert]);

  return null;
};

const SnackbarAlert = () => (
  <SnackbarProvider
    style={{ maxWidth: '60em', paddingRight: '40px' }}
    maxSnack={2}
  >
    <NotiStackAlert />
  </SnackbarProvider>
);

export default SnackbarAlert;

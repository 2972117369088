import deFlag from 'country-flag-icons/3x2/DE.svg';
import usFlag from 'country-flag-icons/3x2/US.svg';
import itFlag from 'country-flag-icons/3x2/IT.svg';

type Language = {
  code: string;
  name: string;
  flag: string;
  otherCodes?: string[];
};

export const languages: Language[] = [
  {
    code: 'en',
    name: 'English',
    flag: usFlag,
    otherCodes: ['en-US', 'en-GB']
  },
  {
    code: 'de',
    name: 'German',
    flag: deFlag
  },
  {
    code: 'it',
    name: 'Italian',
    flag: itFlag
  }
];

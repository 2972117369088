import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { AlertColor } from '@mui/material';
import { AlertState } from 'src/types/base';

type SnackbarStateType = {
  msg: string;
  type?: AlertColor;
  isOpen?: boolean;
  persist?: boolean;
  resetAlert?: boolean;
  hideDuration?: number;
  isConnectedToInternet?: boolean;
};

export const initialState: SnackbarStateType = {
  isOpen: false,
  msg: '',
  persist: false,
  resetAlert: false,
  hideDuration: 4000,
  isConnectedToInternet: true
};

const slice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setAlert(state: SnackbarStateType, action: PayloadAction<AlertState>) {
      const {
        msg,
        type,
        isOpen,
        persist = false,
        hideDuration = 4000,
        isConnectedToInternet = true
      } = action.payload;
      state.isOpen = isOpen === false ? false : true;
      state.msg = msg;
      state.type = type;
      state.persist = persist || false || !isConnectedToInternet;
      state.resetAlert = isConnectedToInternet && !state.resetAlert;
      state.hideDuration = hideDuration;
    }
  }
});

export const reducer = slice.reducer;

export const setAlert =
  (alert: AlertState): AppThunk =>
  async (dispatch: any) => {
    dispatch(slice.actions.setAlert(alert));
  };

export default slice;

export const ONBOARDING_STEPS_TARGET = {
  CHAT_WORLD_KNOWLEDGE_BUTTON: 'world-knowledge-button',
  CHAT_TYPE_BUTTONS: 'chat-type-buttons',
  CHAT_QUERYBOX: 'chat_querybox',
  CHAT_MESSAGES: 'chat_messages',
  CHAT_INPUT_FIELD: 'chat-input-field',
  CHAT_REFERENCE_DOCUMENT: 'chat-reference-document',
  CHAT_SEND_MSG_BUTTON: 'chat-send-msg-button',
  DOCUMENT_DATA_LIBRARY_LINK: 'document-data-library_link',
  DOCUMENT_PRIVATE_WORKSPACE: 'document-private-workspace',
  DOCUMENT_ADD_MENU_NEW_BUTTON: 'document-add-menu-new-button',
  DOCUMENT_ADD_MENU_POPOVER: 'document-add-menu-popover',
  DOCUMENT_NEW_COLLECTION_BUTTON: 'document-new-collection-button',
  DOCUMENT_UPLOAD_LINK: 'document-upload-link',
  DOCUMENT_STATUS_TAG: 'document-status-tag',
  SIDEBAR_MORE_ACTION_BUTTON: 'sidebar-more-action-button',
  SIDEBAR_MORE_ACTION_ICON: 'sidebar-more-action-icon',
  USERS_NAVIGATION_LINK: 'users-navigation-link',
  USERS_INVITE_BUTTON: 'users-invite-button',
  REACT_JOYRIDE_SPOTLIGHT: 'react-joyride__spotlight'
};

import { ONBOARDING_STEPS_TARGET } from '../../constants';

export const handleDataLibrary = () => {};

export const handleMoreActionButtonClick = () => {
  const moreActions = document.getElementById(
    ONBOARDING_STEPS_TARGET.SIDEBAR_MORE_ACTION_BUTTON
  ) as HTMLButtonElement;

  if (moreActions) {
    moreActions.click();
  }
};

export const handleUsersButtonClick = () => {
  const usersButton = document.getElementById(
    ONBOARDING_STEPS_TARGET.USERS_NAVIGATION_LINK
  ) as HTMLButtonElement;

  if (usersButton) {
    usersButton.click();
  }
};

export const updateSpotLightHeight = () => {
  const spotLights = document.getElementsByClassName(
    ONBOARDING_STEPS_TARGET.REACT_JOYRIDE_SPOTLIGHT
  ) as HTMLCollectionOf<HTMLDivElement>;

  if (spotLights.length) {
    spotLights[0].style.height = '122px';
  }
};

export const handleNewButtonClick = () => {
  const newButton = document.getElementById(
    ONBOARDING_STEPS_TARGET.DOCUMENT_ADD_MENU_NEW_BUTTON
  ) as HTMLButtonElement;
  if (newButton) {
    newButton.click();
  }
};

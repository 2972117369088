import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';

interface ThemeInitialStateType {
  currentTheme: string;
  isOpen: boolean;
  sidebarOpenRoutes: { [z: string]: any };
}

const initialState: ThemeInitialStateType = {
  currentTheme: 'DEFAULT',
  isOpen: true,
  sidebarOpenRoutes: {}
};

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme(
      state: ThemeInitialStateType,
      action: PayloadAction<{ currentTheme: string }>
    ) {
      const { currentTheme } = action.payload;

      state.currentTheme = currentTheme;
    },
    setOpenRoutes(
      state: ThemeInitialStateType,
      action: PayloadAction<{ sidebarRoutes: { [z: string]: any } }>
    ) {
      const { sidebarRoutes } = action.payload;

      state.sidebarOpenRoutes = sidebarRoutes;
    }
  }
});

export const reducer = slice.reducer;

export const setTheme =
  (currentTheme: string): AppThunk =>
  async (dispatch: any) => {
    dispatch(
      slice.actions.setTheme({
        currentTheme: currentTheme
      })
    );
  };

export const setSidebarOpenRoutes =
  (sidebarRoutes: { [z: string]: any }): AppThunk =>
  async (dispatch: any) => {
    dispatch(
      slice.actions.setOpenRoutes({
        sidebarRoutes: sidebarRoutes
      })
    );
  };

export default slice;

import {
  BaseQueryFn,
  QueryDefinition,
  FetchBaseQueryError
} from '@reduxjs/toolkit/dist/query';
import { SubscriptionOptions } from '@reduxjs/toolkit/dist/query/core/apiState';
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAlert } from 'src/redux/slices/snackbar';
import { APITags } from 'src/services/utils';
import { ALERT } from 'src/types/enum';
import { ErrorContext, ErrorMessages } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const useLazyQuery = <T, R>({
  api,
  errorContext = ErrorContext.GENERAL,
  options = {}
}: {
  api: UseLazyQuery<QueryDefinition<T, BaseQueryFn, APITags, R>>;
  errorContext?: ErrorContext;
  options?: SubscriptionOptions;
}) => {
  const { t } = useTranslation();
  const [_fetchData, result, lastPromiseInfo] = api({ ...options });
  const dispatch = useDispatch();
  const { isError, data, error } = result;
  const isInternetConnected = useMemo(
    () => !!window?.navigator?.onLine,
    [window?.navigator?.onLine]
  );

  let _errorCallback: MutableRefObject<
    ((error: FetchBaseQueryError) => void) | undefined
  > = useRef<((error: FetchBaseQueryError) => void) | undefined>();

  let _successCallback: MutableRefObject<((data: R) => void) | undefined> =
    useRef<((data: R) => void) | undefined>();

  let _fallbackMsg: MutableRefObject<string | undefined> = useRef<
    string | undefined
  >();

  useEffect(() => {
    if (isError) {
      const _error = error as FetchBaseQueryError;
      dispatch(
        setAlert({
          type: ALERT.ERROR,
          msg: t(
            isInternetConnected
              ? ErrorMessages[errorContext][_error?.status] ||
                  ErrorMessages[ErrorContext.GENERAL][_error?.status] ||
                  _fallbackMsg?.current ||
                  T.internalServerError
              : T.noInternet
          ),
          isConnectedToInternet: isInternetConnected
        })
      );
      if (_errorCallback.current) _errorCallback.current(_error);
    }
  }, [
    isError,
    errorContext,
    dispatch,
    error,
    _errorCallback,
    isInternetConnected
  ]);

  useEffect(() => {
    if (data) {
      if (_successCallback.current) _successCallback.current(data);
    }
  }, [data, _successCallback]);

  const fetchData = useCallback(
    ({
      params,
      fallbackMsg,
      errorCallback,
      successCallback
    }: {
      params: T;
      fallbackMsg?: string;
      errorCallback?: (error: any) => void;
      successCallback?: (data: R) => void;
    }) => {
      _fetchData({ ...params });
      _errorCallback.current = errorCallback;
      _successCallback.current = successCallback;
      _fallbackMsg.current = fallbackMsg;
    },
    [_fetchData]
  );

  return [fetchData, result, lastPromiseInfo] as const;
};

export default useLazyQuery;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTheme } from '@mui/material';

type DialogProps = {
  title?: string;
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
};

const ConfirmationDialog = ({
  title,
  message,
  isOpen,
  onConfirm,
  onClose
}: DialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={onClose}
      aria-describedby="confirmation dialog"
    >
      <DialogTitle>{title || t(T.confirmationDialog)}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(message || T.genericConfirmation)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" size="small">
          {t(T.cancel)}
        </Button>
        <Button
          onClick={onConfirm}
          variant="contained"
          size="small"
          autoFocus
          color="secondary"
        >
          {t(T.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

const BaseLayout: FC = ({ children }) => {
  return (
    <Box display="flex" flex="1" height="100vh">
      {children || <Outlet />}
    </Box>
  );
};

export default BaseLayout;

import { Promise as BPromise } from 'bluebird';
import jwtDecode from 'jwt-decode';
import Keycloak from 'keycloak-js';
import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM_NAME,
  KEYCLOAK_SERVER_URL
} from './config';
import { authSignIn, authSignOut as signOut } from './redux/slices/auth';
import store from './redux/store';
import {
  decrypt_local,
  encrypt_local,
  setAllLocalStorageKeys
} from './utils/encryption';
import axios from './utils/axios';
import logger from './utils/logger';

const kc = new Keycloak({
  realm: KEYCLOAK_REALM_NAME,
  url: KEYCLOAK_SERVER_URL,
  clientId: KEYCLOAK_CLIENT_ID
});
export const KC_TOKEN = 'kc_token';
export const USER_LOGGED_IN = 'isLogin';
export const KC_REFRESH_TOKEN = 'kc_refreshToken';
export const IDLE_SESSION_TIME = 'idle_session_time';
export const KCC_TOKEN = 'kcc_token';
export const KCC_PARAMS = 'kcc_params';
export const KC_ID_TOKEN = 'kc_id_token';
export const IS_NEW_USER = 'new_user';
export const PERSIST_ROOT = 'persist:root';
export const AD_TOKEN = 'ad_token';

export const getKcToken = () => {
  return window.localStorage.getItem(KC_TOKEN)
    ? decrypt_local(window.localStorage.getItem(KC_TOKEN))
    : null;
};
export const setKcToken = (token: string) =>
  window.localStorage.setItem(KC_TOKEN, encrypt_local(token));

export const getKcIdToken = () => {
  return window.localStorage.getItem(KC_ID_TOKEN)
    ? decrypt_local(window.localStorage.getItem(KC_ID_TOKEN))
    : null;
};
export const setKcIdToken = (token: string) =>
  window.localStorage.setItem(KC_ID_TOKEN, encrypt_local(token));

export const getUserLogInStatus = () =>
  window.localStorage.getItem(USER_LOGGED_IN)
    ? decrypt_local(window.localStorage.getItem(USER_LOGGED_IN))
    : null;
export const setUserLogInStatus = (login: string) =>
  window.localStorage.setItem(USER_LOGGED_IN, encrypt_local(login));

export const getRefreshToken = () => {
  return window.localStorage.getItem(KC_REFRESH_TOKEN)
    ? decrypt_local(window.localStorage.getItem(KC_REFRESH_TOKEN))
    : null;
};
export const setRefreshToken = (token: string) =>
  window.localStorage.setItem(KC_REFRESH_TOKEN, encrypt_local(token));

export const getIdleSessionTime = () =>
  window.localStorage.getItem(IDLE_SESSION_TIME)
    ? decrypt_local(window.localStorage.getItem(IDLE_SESSION_TIME))
    : '';
export const setIdleSessionTime = (time: string) =>
  window.localStorage.setItem(IDLE_SESSION_TIME, encrypt_local(time));

export const getKccToken = () =>
  window.localStorage.getItem(KCC_TOKEN)
    ? decrypt_local(window.localStorage.getItem(KCC_TOKEN))
    : '';
export const setKccToken = (token: string) =>
  window.localStorage.setItem(KCC_TOKEN, encrypt_local(token));

export const setEncryptParams = (params: any) => {
  window.localStorage.setItem(
    KCC_PARAMS,
    encrypt_local(JSON.stringify(params))
  );
};

export const setAdToken = (token: string) => {
  localStorage.setItem(AD_TOKEN, encrypt_local(token));
};

export const getAdToken = () => {
  return localStorage.getItem(AD_TOKEN)
    ? decrypt_local(localStorage.getItem(AD_TOKEN))
    : '';
};
export const getEncryptParams = () => {
  let params = window.localStorage.getItem(KCC_PARAMS)
    ? decrypt_local(window.localStorage.getItem(KCC_PARAMS))
    : null;
  return params ? JSON.parse(params) : params;
};

export const getNewUser = () =>
  window.localStorage.getItem(IS_NEW_USER)
    ? decrypt_local(window.localStorage.getItem(IS_NEW_USER))
    : '';
export const setNewUser = (new_user: string) =>
  window.localStorage.setItem(IS_NEW_USER, encrypt_local(new_user));

export const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }
  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

let kcPromise: any;
let updateRetries: number = 0;
let kcInitRetries: number = 0;

export const fetchADAccessToken = async () => {
  const kcToken = getKcToken();
  const decoded: any = jwtDecode(kcToken);
  if (decoded.idp_alias) {
    const response = await axios.get(
      `${KEYCLOAK_SERVER_URL}/realms/${KEYCLOAK_REALM_NAME}/broker/${decoded.idp_alias}/token`,
      {
        headers: {
          Authorization: `Bearer ${kcToken}`
        }
      }
    );
    return response?.data ? response?.data : null;
  }
  return null;
};

const saveTokens = (kc: Keycloak) => {
  setKcToken(kc.token);
  setRefreshToken(kc.refreshToken);
  setKcIdToken(kc.idToken);
  if (getUserLogInStatus() === null) {
    // in case of login redirect back to home page.
    //   window.location.href = "/";
    //   setUserLogInStatus("null");
    // } else if (getUserLogInStatus() === "null") {
    setUserLogInStatus('false');
  } else if (getUserLogInStatus() === 'false') {
    setUserLogInStatus('true');
  }
  setKccToken('true');
};

const clearToken = () => {
  localStorage.clear();
};

// const isRefreshTimeExpired = (kcToken: string) => {
//   if (!kcToken) {
//     return false;
//   }
//   let token: any = jwtDecode(kcToken);
//   let tokenLifespanMins: any = (token.exp - token.iat) / 60;
//   let tokenRefreshTime: any = tokenLifespanMins * 0.5; // 70% of token life span
//   let tokenRemainingTime: any = (token.exp - new Date().getTime() / 1000) / 60;
//   let tokenElapsedTime = tokenLifespanMins - tokenRemainingTime;
//   return tokenElapsedTime > tokenRefreshTime ? true : false;
// };

// export const initializeTimer = (kcToken: string) => {
//   // let kcTokenMins: any = jwtDecode(kcToken);
//   // kcTokenMins = ((kcTokenMins.exp - kcTokenMins.iat) / 60) * intervalTime;
//   // kcTokenMins = kcTokenMins * 0.7; // 70% of token life span
//   // tokenRefreshTimer = setTimeout(() => updateToken(kc), kcTokenMins);

//   /////////////////////////////////////////////////////////
//   let token: any = jwtDecode(kcToken);
//   let tokenLifespanMins: any = (token.exp - token.iat) / 60;
//   let tokenRefreshTime: any = tokenLifespanMins * 0.1; // 70% of token life span
//   let tokenRemainingTime: any = (token.exp - new Date().getTime() / 1000) / 60;
//   let tokenElapsedTime = tokenLifespanMins - tokenRemainingTime;

//   if (tokenRemainingTime > 0) {
//     if (tokenElapsedTime > tokenRefreshTime) {
//       // update Token
//       updateToken(kc);
//     } else {
//       tokenRefreshTime = tokenRefreshTime - tokenElapsedTime;
//       // set timer with tokenrefresh time and update the call
//       tokenRefreshTimer = setTimeout(
//         () => updateToken(kc),
//         tokenRefreshTime * intervalTime
//       );
//     }
//   } else {
//     // immediate update the token
//     updateToken(kc);
//   }

//   ///////////////////////////////////////////////////////////
// };

const LOCK_KEY = 'token_refresh_lock';
const LOCK_EXPIRATION = 10000; // 10 seconds

function isLockActive() {
  const lock = JSON.parse(localStorage.getItem(LOCK_KEY) || '{}');
  const currentTime = Date.now();
  return lock.timestamp && currentTime - lock.timestamp < LOCK_EXPIRATION;
}

function acquireLock() {
  localStorage.setItem(LOCK_KEY, JSON.stringify({ timestamp: Date.now() }));
}

export const updateToken = (kc: any) => {
  const anyNav: any = navigator;
  anyNav.locks.request('update_token', async (lock: any) => {
    logger.log('Lock requested');

    if (isLockActive()) {
      logger.log('Another tab has already refreshed the token.');
      return; // Prevent this tab from proceeding with token refresh
    }

    logger.log('Lock Acquired');
    updateRetries = 0;
    acquireLock();
    await new Promise((res: any) => {
      kc.updateToken().then(
        () => {
          saveTokens(kc);
          updateRetries = 0;
          logger.log(`Token refreshed ${new Date()}`);
          store.dispatch(authSignIn());
          res(kc);
        },
        (err: any) => {
          if (updateRetries >= 3) {
            kc.clearToken();
            store.dispatch(signOut());
            logger.log('Token refresh failed');
            res(kc);
          } else {
            updateRetries++;
            updateToken(kc); // Retry token update if it fails
          }
        }
      );
    });
    // // Case: when token is valid and the token exp refresh time is expired then update the token
    // if (!isValidToken(getKcToken())) {
    //   // The lock has been acquired.
    // } else {
    //   // case: when token is updated by another tab
    //   // Update token for existing tab
    //   logger.log("Token already Updated!");
    //   initializeKcWithLocalstorage(kc);
    // }
  });
};
// export const initializeKcWithLocalstorage = (kc: any) => {
//   const token: any = getKcToken();
//   const refreshToken: any = getRefreshToken();
//   const idToken: any = getKcIdToken();

//   kc.onTokenExpired = () => {
//     logger.log(`token expired ${new Date()}`);
//     updateToken(kc);
//   };

//   kc.init({
//     token: token,
//     refreshToken: refreshToken,
//     idToken: idToken,
//     timeSkew: 600,
//   });
//   debugger;
//   logger.log(kc);
// };

export const kcinit = () => {
  if (kcPromise && kcPromise.then) {
    // if already authunticating and got a request from some other app
    return kcPromise;
  }
  let token: any = null;
  let refreshToken: any = null;

  kcPromise = new Promise((res: any) => {
    const kcc_params = window.localStorage.getItem(KCC_PARAMS);
    if (kcc_params && kcc_params.length && kcc_params[0] === '{') {
      setAllLocalStorageKeys();
    }

    if (kcc_params !== null) {
      token = getKcToken();
      refreshToken = getRefreshToken();
    }
    // token expired
    kc.onTokenExpired = () => {
      logger.log(`token expired ${new Date()}`);
      updateToken(kc);
    };

    // Pass IDP hint to login page
    const kcLogin = kc.login;
    const login: typeof kcLogin = (options) => {
      const urlParams = new URLSearchParams(window.location.search);
      const idpHint = urlParams.get('kc_idp_hint');
      return kcLogin({ ...options, idpHint });
    };
    kc.login = login;

    const initPromise = new BPromise((resInit: any) => {
      kcInitRetries = 0;
      kc.init({
        onLoad: 'login-required',
        token,
        refreshToken,
        timeSkew: 600,
        checkLoginIframe: false
      })
        .then(
          (authenticated) => {
            if (kc.isTokenExpired()) {
              res(updateToken(kc));
              return;
            }
            if (authenticated) {
              saveTokens(kc);
              res(kc);
              kcInitRetries = 0;
            } else {
              clearToken();
              store.dispatch(signOut());
            }
            resInit();
          },
          (error) => {
            logger.log('Error: ', error);
            if (kcInitRetries < 3) {
              kcInitRetries++;
              kcinit();
            } else if (kcInitRetries >= 3) {
              alert('Login failed!, please retry!');
              res(kc);
              clearToken();
              store.dispatch(signOut());
              resInit();
            }
          }
        )
        .catch((err: any) => {
          logger.log('Code is Expired!', err);
          if (kcInitRetries < 3) {
            kcInitRetries++;
            kcinit();
          } else if (kcInitRetries >= 3) {
            alert('Login failed!, please retry!');
            res(kc);
            clearToken();
            store.dispatch(signOut());
            resInit();
          }
        });
      // if (isValidToken(token)) {
      //   kc.init({
      //     token: getKcToken(),
      //     refreshToken: getRefreshToken(),
      //     idToken: getKcIdToken(),
      //   }).then(
      //     (authenticated) => {
      //       if (kc.isTokenExpired()) {
      //         res(updateToken(kc));
      //         return;
      //       }
      //       if (authenticated) {
      //         res(kc);
      //       } else {
      //         clearToken();
      //         store.dispatch(signOut());
      //       }
      //       resInit();
      //     },
      //     (error) => {
      //       logger.log("Error: ", error);
      //       if (kcInitRetries < 3) {
      //         kcInitRetries++;
      //         kcinit();
      //       } else if (kcInitRetries >= 3) {
      //         alert("Login failed!, please retry!");
      //         res(kc);
      //         clearToken();
      //         store.dispatch(signOut());
      //         resInit();
      //       }
      //     }
      //   );
      // } else {
      // }
    });
    setTimeout(() => {
      // if auth server not found, keycloak may silenty fail
      if (initPromise.isPending()) {
        // force logout
        // force re login
        clearToken();
        store.dispatch(signOut());
      }
    }, 5000);
  });
  return kcPromise;
};

export default kc;

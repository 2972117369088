import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const SignupHeader = () => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" gap={{ xs: '16px', sm: '6px' }}>
      <Typography fontSize="24px" fontWeight={500} color="primary">
        {t(T.welcomeText)} {t(T.empowerGPT)}!
      </Typography>
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography color="#000C57A2">{t(T.signupInfo)}</Typography>
        <Typography color="#000C57A2">{t(T.signupInfo2)}</Typography>
        <ul style={{ color: '#000C57A2', marginTop: '0px', paddingLeft: '28px' }}>
          <li>Upload up to 100 documents.</li>
          <li>Invite up to 10 team members.</li>
        </ul>
      </Box>
    </Box>
  );
};

export default SignupHeader;

import { getKcToken, isValidToken } from 'src/keycloak';

const PublicRoute = ({ element }) => {
  const isAuthenticated = isValidToken(getKcToken());

  if (isAuthenticated) {
    window.location.replace('/');
    return null;
  }

  return element;
};

export default PublicRoute;

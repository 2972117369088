import React, { useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import WelcomePopup from './WelcomePopup';
import Joyride, { ACTIONS, EVENTS } from 'react-joyride';
import { useTranslation } from 'react-i18next';
import CustomPopup from './CustomPopup';
import {
  handleChatQueryInput,
  handleSendMessageButton,
  handleWorldKnowledgeTabClick,
  updateSpotLightPosition
} from './OnboardingSteps/ChatSteps/actions';
import DocumentSteps from './OnboardingSteps/DocumentsSteps';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import {
  toggleOnboarding,
  updateOnboarding
} from 'src/redux/slices/onboarding';
import {
  handlePrivateWorkspaceClick,
  handleNewButtonClick,
  handleUploadButtonClick
} from './OnboardingSteps/DocumentsSteps/actions';
import UsersSteps from './OnboardingSteps/UsersSteps';
import {
  handleMoreActionButtonClick,
  handleUsersButtonClick
} from './OnboardingSteps/UsersSteps/actions';
import { updateUserOnboarding } from 'src/redux/slices/data';
import useMutation from 'src/hooks/useMutation';
import { useUpdateUserPreferencesMutation } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { ACCOUNT_TYPE, OnboardingStepTypes } from 'src/types/enum';
import ChatSteps from './OnboardingSteps/ChatSteps';
import { ONBOARDING_STEPS_TARGET } from './constants';

const UserOnboard = () => {
  const chatSteps = ChatSteps();
  const documentSteps = DocumentSteps();
  const userSteps = UsersSteps();
  const STEP_TYPES = {
    chat: chatSteps,
    document: documentSteps,
    user: userSteps
  };
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.auth);
  const [showPopup, setShowPopup] = useState(true);
  const [stepType, setStepType] = useState('chat');
  const { isOnboarding, stepCount, updateOnboardingStep, stepsContext } =
    useSelector((state: RootState) => state.onboarding);
  const [steps, setSteps] = useState(chatSteps);
  const [stepIndex, setStepIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateUserOnboardingSetting] = useMutation({
    api: useUpdateUserPreferencesMutation,
    errorContext: ErrorContext.GENERAL
  });

  const markUserOnboardingComplete = async () => {
    await updateUserOnboardingSetting({
      params: { params: { name: 'onboarded', v: 'true' } }
    });
    dispatch(updateUserOnboarding(true));
  };

  const handleClose = () => {
    handleStartTour();
  };

  const handleStartTour = () => {
    setShowPopup(false);
    dispatch(toggleOnboarding());
  };

  const handleNext = () => {
    if (stepIndex === steps.length - 1) {
      dispatch(toggleOnboarding());
      nextStepType();
    }
  };

  const handleTourClose = () => {
    dispatch(toggleOnboarding());
    nextStepType();
  };

  useEffect(() => {
    if (updateOnboardingStep) setStepIndex(stepCount);
  }, [stepCount, updateOnboardingStep]);

  useEffect(() => {
    if (stepsContext !== OnboardingStepTypes.CHAT && stepType !== stepsContext)
      nextStepType();
  }, [stepsContext]);

  const nextStepType = () => {
    switch (stepType) {
      case OnboardingStepTypes.CHAT:
        setStepType(OnboardingStepTypes.DOCUMENT);
        setTimeout(() => {
          setSteps(STEP_TYPES[OnboardingStepTypes.DOCUMENT]);
          setStepIndex(0);
          dispatch(
            updateOnboarding({
              isOnboarding: true,
              stepsContext: OnboardingStepTypes.DOCUMENT
            })
          );
        }, 200);
        break;
      case OnboardingStepTypes.DOCUMENT:
        setStepIndex(0);
        if (
          user.accountType !== ACCOUNT_TYPE.REGULAR_USER &&
          user.accountType !== ACCOUNT_TYPE.TRIAL_USER
        ) {
          setStepType(OnboardingStepTypes.USER);
          setSteps(STEP_TYPES[OnboardingStepTypes.USER]);
          setTimeout(() => {
            dispatch(toggleOnboarding());
          }, 200);
        } else {
          dispatch(updateOnboarding({ stepCount: 0, isOnboarding: false }));
          markUserOnboardingComplete();
        }
        break;
      default:
        setStepIndex(0);
        dispatch(updateOnboarding({ stepCount: 0, isOnboarding: false }));
        markUserOnboardingComplete();
        break;
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, type, step } = data;

    if (steps.length < index || !step) return;

    if (action === ACTIONS.CLOSE && type === EVENTS.STEP_AFTER) {
      if (isOnboarding) dispatch(toggleOnboarding());
      nextStepType();
      return;
    } else if (
      action === ACTIONS.UPDATE &&
      step.target === `.${ONBOARDING_STEPS_TARGET.CHAT_MESSAGES}`
    ) {
      dispatch(
        updateOnboarding({
          stepCount: stepIndex,
          updateOnboardingStep: false
        })
      );
      updateSpotLightPosition();
    } else if (type === EVENTS.STEP_AFTER) {
      switch (step.target) {
        case `#${ONBOARDING_STEPS_TARGET.CHAT_TYPE_BUTTONS}`:
          handleWorldKnowledgeTabClick();
          handleChatQueryInput();
          break;
        case `#${ONBOARDING_STEPS_TARGET.CHAT_QUERYBOX}`:
          handleSendMessageButton();
          break;
        case `#${ONBOARDING_STEPS_TARGET.DOCUMENT_DATA_LIBRARY_LINK}`:
          if (action === ACTIONS.NEXT) {
            dispatch(toggleOnboarding());
            navigate('/data-library');
          }
          break;
        case `#${ONBOARDING_STEPS_TARGET.DOCUMENT_PRIVATE_WORKSPACE}`:
          dispatch(toggleOnboarding());
          handlePrivateWorkspaceClick();
          break;
        case `#${ONBOARDING_STEPS_TARGET.DOCUMENT_ADD_MENU_POPOVER}`:
          handleNewButtonClick();
          dispatch(
            updateOnboarding({
              stepCount: stepIndex,
              updateOnboardingStep: false
            })
          );
          break;
        case `#${ONBOARDING_STEPS_TARGET.SIDEBAR_MORE_ACTION_ICON}`:
          handleMoreActionButtonClick();
          dispatch(toggleOnboarding());
          setTimeout(() => {
            dispatch(toggleOnboarding());
          }, 500);
          break;
        case `#${ONBOARDING_STEPS_TARGET.USERS_NAVIGATION_LINK}`:
          if (action === ACTIONS.NEXT) {
            dispatch(toggleOnboarding());
            handleUsersButtonClick();
          }
          break;
        case `#${ONBOARDING_STEPS_TARGET.DOCUMENT_UPLOAD_LINK}`:
          if (stepsContext !== OnboardingStepTypes.USER) {
            dispatch(toggleOnboarding());
            handleUploadButtonClick();
          }
          break;
        default:
          break;
      }

      if (action === ACTIONS.NEXT) {
        if (
          steps.length - 1 !== stepIndex &&
          step.target !==
            `#${ONBOARDING_STEPS_TARGET.DOCUMENT_NEW_COLLECTION_BUTTON}`
        ) {
          setStepIndex((prev) => prev + 1);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: { xs: '100%', lg: '100%' },
          backgroundColor: theme.colors.alpha.white[100]
        }}
      >
        <WelcomePopup
          isOpen={showPopup}
          onClose={handleClose}
          handleStartTour={handleStartTour}
        />
        <Joyride
          tooltipComponent={(props) => (
            <CustomPopup
              {...props}
              totalSteps={steps.length}
              onClose={handleTourClose}
              handleNext={handleNext}
              isLastStep={steps.length - 1 === stepIndex}
            />
          )}
          callback={handleJoyrideCallback}
          continuous
          run={isOnboarding}
          stepIndex={stepIndex}
          disableOverlayClose={true}
          hideBackButton={true}
          hideCloseButton={true}
          showProgress
          floaterProps={{
            styles: {
              floater: {
                zIndex: 2000
              }
            }
          }}
          styles={{
            buttonNext: {
              background: '#FFFFFF',
              color: '#000C57',
              border: '1px solid #000C57'
            },
            options: {
              primaryColor: '#000C57',
              zIndex: 1500
            }
          }}
          steps={steps}
        />
      </Box>
    </React.Fragment>
  );
};

export default UserOnboard;

import { Box, Typography, styled } from '@mui/material';

export const StyledBox = styled(Box)({
  padding: '12px 0px 10px 0px',
  backgroundColor: '#CAD9F233',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '2px',
  borderRadius: '10px'
});

import { Step } from 'react-joyride';
import CustomArrow from '../../CustomArrow';
import { Typography } from '@mui/material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { ONBOARDING_STEPS_TARGET } from '../../constants';

const ChatSteps = (): Step[] => {
  const { t } = useTranslation();

  return [
    {
      target: `#${ONBOARDING_STEPS_TARGET.CHAT_TYPE_BUTTONS}`,
      placement: 'bottom-start',
      floaterProps: { hideArrow: true },
      offset: 20,
      content: (
        <>
          <CustomArrow
            position={{ top: '-38px', left: '200px' }}
            orientation="top"
          />
          <Typography width={570} mb={1}>
            {t(T.chatStep1Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.chatStep1Title)
    },
    {
      target: `#${ONBOARDING_STEPS_TARGET.CHAT_QUERYBOX}`,
      placement: 'top-end',
      offset: 20,
      floaterProps: {
        hideArrow: true,
        styles: {
          floaterOpening: {
            left: '40px'
          }
        }
      },
      content: (
        <>
          <CustomArrow
            position={{ bottom: '-40px', left: '550px' }}
            orientation="bottom"
          />
          <Typography width={570} mb={1}>
            {t(T.chatStep2Description)}
          </Typography>
          <Typography>{t(T.onNext)}</Typography>
        </>
      ),
      disableBeacon: true,
      title: t(T.chatStep2Title)
    },
    {
      target: `.${ONBOARDING_STEPS_TARGET.CHAT_MESSAGES}`,
      floaterProps: {
        hideArrow: true,
        styles: {
          floater: {
            display: 'none'
          }
        }
      },
      disableBeacon: true,
      content: ''
    }
  ];
};

export default ChatSteps;

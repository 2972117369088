import { getEncryptParams } from 'src/keycloak';
import { DataApiInputParams, IBaseQueryResult } from 'src/types/api';
import {
  Builder,
  appendFormData,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { getPayload } from 'src/utils/encryption';
import { User } from 'src/types/user';

export default (builder: Builder) => {
  return {
    getUsers: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let queryString = getQueryString(arg.params);
        let url = `keycloak/Users${
          queryString.length ? `?${queryString}` : ''
        }`;
        if (IS_ENCRYPTION_ENABLED) {
          url = `${url}${queryString.length ? '&' : '?'}payload=${btoa(
            getPayload('', getEncryptParams())
          )}`;
        }
        let result = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response: IBaseQueryResult<User[]> = decryptResponse(
          result,
          decryptKey
        );
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['User']
    }),
    createUser: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let url = `keycloak/Users`;
        let formData = appendFormData(arg.params);
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['User']
    }),
    updateUser: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let url = `keycloak/Users`;
        let formData = appendFormData(arg.params);
        let result: any = await fetchWithBQ({
          url: url,
          method: 'PUT',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    deleteUser: builder.mutation({
      async queryFn(
        args: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let formData = appendFormData(args.params);
        let url = `keycloak/Users`;
        const result: any = await fetchWithBQ({
          url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['User']
    }),
    updateStatus: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let url = `keycloak/Users/update_status`;
        let formData = appendFormData(arg.params);
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        let decryptKey = getEncryptParams()?.k;
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['User']
    })
  };
};
